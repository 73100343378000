import { useTheme } from '@mui/material/styles';

export const returnTheme = () => {
    const theme = useTheme();
    return {
        "themeName": "default",
        "colorPalette": "light",
        "isPanelless": false,
        "backgroundImage": "",
        "backgroundOpacity": 1,
        "backgroundImageAttachment": "scroll",
        "backgroundImageFit": "cover",
        "cssVariables": {
            "--sjs-questionpanel-backcolor": theme.palette.container.background,
            "--sjs-corner-radius": "4px",
            "--sjs-base-unit": "8px",
            "--sjs-shadow-small": "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
            "--sjs-font-questiontitle-color": theme.palette.text.primary,
            "--sjs-shadow-inner": "inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
            "--sjs-font-editorfont-size": "18px",
            "--sjs-font-editorfont-color": "rgba(244, 6, 6, 0.91)",
            "--sjs-font-editorfont-placeholdercolor": "rgba(11, 128, 128, 0.45)",
            "--sjs-border-default": "rgba(0, 0, 0, 0.16)",
            "--sjs-border-light": "rgba(0, 0, 0, 0.09)",
            "--sjs-general-backcolor": "rgba(255, 255, 255, 1)",
            "--sjs-general-backcolor-dark": "rgba(248, 248, 248, 1)",
            "--sjs-general-backcolor-dim-light": "rgba(249, 249, 249, 1)",
            "--sjs-general-backcolor-dim-dark": "rgba(243, 243, 243, 1)",
            "--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
            "--sjs-general-forecolor-light": "rgba(0, 0, 0, 0.45)",
            "--sjs-general-dim-forecolor": "rgba(0, 0, 0, 0.91)",
            "--sjs-general-dim-forecolor-light": "rgba(0, 0, 0, 0.45)",
            "--sjs-shadow-small-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
            "--sjs-shadow-medium": "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
            "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
            "--sjs-shadow-inner-reset": "inset 0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
            "--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
            "--sjs-article-font-xx-large-textDecoration": "none",
            "--sjs-article-font-xx-large-fontWeight": "700",
            "--sjs-article-font-xx-large-fontStyle": "normal",
            "--sjs-article-font-xx-large-fontStretch": "normal",
            "--sjs-article-font-xx-large-letterSpacing": "0",
            "--sjs-article-font-xx-large-lineHeight": "64px",
            "--sjs-article-font-xx-large-paragraphIndent": "0px",
            "--sjs-article-font-xx-large-textCase": "none",
            "--sjs-article-font-x-large-textDecoration": "none",
            "--sjs-article-font-x-large-fontWeight": "700",
            "--sjs-article-font-x-large-fontStyle": "normal",
            "--sjs-article-font-x-large-fontStretch": "normal",
            "--sjs-article-font-x-large-letterSpacing": "0",
            "--sjs-article-font-x-large-lineHeight": "56px",
            "--sjs-article-font-x-large-paragraphIndent": "0px",
            "--sjs-article-font-x-large-textCase": "none",
            "--sjs-article-font-large-textDecoration": "none",
            "--sjs-article-font-large-fontWeight": "700",
            "--sjs-article-font-large-fontStyle": "normal",
            "--sjs-article-font-large-fontStretch": "normal",
            "--sjs-article-font-large-letterSpacing": "0",
            "--sjs-article-font-large-lineHeight": "40px",
            "--sjs-article-font-large-paragraphIndent": "0px",
            "--sjs-article-font-large-textCase": "none",
            "--sjs-article-font-medium-textDecoration": "none",
            "--sjs-article-font-medium-fontWeight": "700",
            "--sjs-article-font-medium-fontStyle": "normal",
            "--sjs-article-font-medium-fontStretch": "normal",
            "--sjs-article-font-medium-letterSpacing": "0",
            "--sjs-article-font-medium-lineHeight": "32px",
            "--sjs-article-font-medium-paragraphIndent": "0px",
            "--sjs-article-font-medium-textCase": "none",
            "--sjs-article-font-default-textDecoration": "none",
            "--sjs-article-font-default-fontWeight": "400",
            "--sjs-article-font-default-fontStyle": "normal",
            "--sjs-article-font-default-fontStretch": "normal",
            "--sjs-article-font-default-letterSpacing": "0",
            "--sjs-article-font-default-lineHeight": "28px",
            "--sjs-article-font-default-paragraphIndent": "0px",
            "--sjs-article-font-default-textCase": "none",
            "--sjs-general-backcolor-dim": "transparent",
            "--sjs-primary-backcolor": theme.palette.primary.main,
            "--sjs-primary-backcolor-dark": theme.palette.secondary.main,
            "--sjs-primary-forecolor": theme.palette.mode === "dark" ? theme.palette.text.primary : "#FFFFFF",
            "--sjs-primary-forecolor-light": theme.palette.text.primary,
            "--sjs-primary-forecolor-dim": theme.palette.text.primary,
            "--sjs-primary-forecolor-dark": theme.palette.text.primary,
            "--sjs-special-red": theme.palette.error.main,
            "--sjs-special-red-light": "rgba(229, 10, 62, 0.1)",
            "--sjs-font-headerdescription-family": "Verdana, sans-serif",
            "--sjs-header-backcolor": "transparent"
        },
        "header": {
          "height": 0
        },
        "headerView": "advanced"
    }
}