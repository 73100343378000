import React, {useEffect, useState, FormEvent} from 'react';
import {useSearchParams, useNavigate} from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import Copyright from '../Copyright';
import {userApi} from '../../api/openapi-axios-client';
import { callApi } from '../../api/helpers';
import { MessageResponse, PasswordResetRequest, ResetPasswordFinishFormData } from '../../types';

const ResetPasswordFinish: React.FC = () => {
    const [error, setError] = useState<string>('');
    const [userId, setUserId] = useState<string>('');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const resetToken = searchParams.get('resetToken');

    useEffect(() => {
      const getData = async (): Promise<void> => {
        if(userId) {
          return;
        }

        // Check if Token is valid
        if(!resetToken) {
          setError('No Reset Token Supplied');
          return;
        }

        try {
          const result = await callApi<PasswordResetRequest>(() => 
            userApi.password.validateResetRequest(resetToken)
          );

          if(!result || !result.userId) {
            setError('Invalid Reset Token');
            return;
          }

          setUserId(result.userId);
        }
        catch (err) {
          setError('Invalid Reset Token');
        }
      };
      
      getData();
    }, [userId, resetToken]);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        setError('');
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const password = formData.get('password') as string;
        const confirmPassword = formData.get('confirmPassword') as string;

        if(password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (!resetToken || !userId) {
            setError('Missing required data for password reset');
            return;
        }

        const submissionsData: ResetPasswordFinishFormData = {
            resetToken,
            userId,
            password
        };
    
        try {
            await callApi<MessageResponse>(() => 
                userApi.password.resetPassword({...submissionsData})
            );
            navigate('/login');
        } catch (err) {
            setError((err as Error).message);
        }
    };
    
    return (
        <>
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {error && <Alert severity='error'>{error}</Alert>}
  
          <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
            <PasswordOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Update Password
          </Typography>
          <Box component='form' onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='confirmPassword'
              label='Confirm Password'
              type='password'
              id='confirmPassword'
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{mt: 3, mb: 2}}>
              Update Password
            </Button>
          </Box>
        </Box>
        <Copyright sx={{mt: 8, mb: 4}} />
      </>
    );
};

export default ResetPasswordFinish; 