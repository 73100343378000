function getStringFromUnicode(unicode: number): string {
    return String.fromCodePoint(unicode);
}

export function breakLines(str: string, limit: number): string {
    let brokenString = '';
    for(let i = 0, count = 0; i < str.length; i++) {
       if(count >= limit && str[i] === ' '){
          count = 0;
          brokenString += '\n';
       } 
       else {
          count++;
          brokenString += str[i];
       }
    }
    return brokenString;
}

export const fastGrowingCareer = getStringFromUnicode(0x1F680);
export const registeredTrademark = getStringFromUnicode(0x000AE);

export function stringToColor(string: string): string {
    let hash = 0;

    for (let i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
} 

export function truncateString(string: string | null | undefined, length: number = 100, showDots: boolean = true): string | null | undefined {
    if(!string) { 
        return string;
    }
    
    return string.length > length ? `${string.substring(0, length)}${showDots ? '...' : ''}` : string;
}