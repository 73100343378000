import React, { ReactElement } from 'react';
import {Circle, Font, Image, Line, Page, StyleSheet, Svg, Text, View, Document} from '@react-pdf/renderer';
import ReactPDFChart from 'react-pdf-charts';
import {Pie, PieChart} from 'recharts';

import {convertValue} from '../../helpers/salary';
import {insightReportLogo} from '../../assets';
import {breakLines, fastGrowingCareer, registeredTrademark, stringToColor} from '../../helpers/strings';
import {GraphData} from '../../types/insight-report';
import {InsightReportProps, UserShareReportCluster} from '../../types';

  
Font.registerEmojiSource({
    format: 'png',
    url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageStyles = StyleSheet.create({
    view: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    sectionHeader: {
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        fontSize: 14,
        marginBottom: 10,
    },
    lineDivider: {
        x1: 20,
        y1: 1,
        x2: 575,
        y2: 1,
        strokeWidth: 2,
        stroke: '#DADADA',
        style: {
            paddingLeft: 20,
            paddingRight: 20,
        }
    } as any,
    lineDividerSvg: {
        height: 2,
        width: '100%',
        style: {
            marginTop: 10,
            marginBottom: 10,
        }
    } as any,
    lineTable: {
        x1: 0,
        y1: 1,
        x2: 575,
        y2: 1,
        strokeWidth: 2,
        stroke: '#DADADA',
    } as any,
    lineTableSvg: {
        height: 2,
        width: '100%',
    },
    title: {
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        fontSize: 12,
    },
    detail: {
        fontFamily: 'Helvetica',
        fontWeight: 'normal',
        fontSize: 12,
    },
    snapShotDetail: {
        fontFamily: 'Helvetica',
        fontWeight: 'normal',
        fontSize: 10,
    },
    borders: {
        borderRadius: 8,
    }
});

const InsightReport: React.FC<InsightReportProps> = ({reportData}) => {
    const {user: {firstName, lastName, email}, clusters, occupations} = reportData;

    const buildHeader = (): ReactElement => {
        return (
            <View style={{...pageStyles.view, marginTop: 20, marginBottom: 20, width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'flex-start'}}>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <Image src={insightReportLogo} style={{height: 20, width:100, marginBottom: 5}}/>
                    <Text style={{fontSize: 6, fontFamily: 'Helvetica', fontWeight: 'normal'}}>{String.fromCodePoint(0x000AE)}</Text>
                </View>
                <Svg {...pageStyles.lineTableSvg}>
                    <Line {...pageStyles.lineTable as any} />
                </Svg>
            </View>
        );
    };

    const buildFooter = (pageNumber: number): ReactElement => {
        return (
            <View style={{position:'absolute', bottom: 10, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', paddingLeft: 20, paddingRight: 20, width:'100%'}}>
                <Text style={{fontSize: 10, color:'#979797'}}>
                    {`Copyright ${new Date().getFullYear()}`}
                </Text>
                <Text style={{fontSize: 10, color:'#979797', width:450}}>
                    From &quot;O*NET OnLine Help: Find Occupations&quot; by the U.S. Department 
                    of Labor, Employment and Training Administration (USDOL/ETA).
                    Used under the CC BY 4.0 license.
                </Text>
                <Text style={{fontSize: 10, textAlign:'right', color:'#979797'}}>
                    {pageNumber}
                </Text>
            </View>
        );
    };

    const graphData : GraphData[] = clusters.map((cluster : UserShareReportCluster) => {
        return {
            name: cluster.name,
            nameSplitArray: breakLines(cluster.name, 15).split('\n'),
            count: cluster.count,
            percent: cluster.percentage,
            fill: stringToColor(cluster.name)
        } as GraphData;
    });

    return (
        <Document title={`Insight Report - ${firstName} ${lastName} ${new Date().toLocaleDateString()}`} author='FutureGen XYZ'>
            {/*PAGE 1*/}
            <Page style={{fontFamily: 'Helvetica', display:'flex', flexDirection:'column', width:'100%', height:'100%'}}>
                {/* Header */}
                <View style={{...pageStyles.view, width:'100%', height:110, backgroundColor:'#F4F6FC', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'flex-start'}}>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <Image src={insightReportLogo} style={{height: 20, width:100, marginBottom: 5}}/>
                        <Text style={{fontSize: 6, fontFamily: 'Helvetica', fontWeight: 'normal'}}>{String.fromCodePoint(0x000AE)}</Text>
                    </View>
                    <Text style={{fontFamily:'Helvetica-Bold', fontSize: 28}}>{`${firstName} ${lastName}'s Career Insight Report`}</Text>
                </View>
                {/* User Information */}
                <View style={{...pageStyles.view, display:'flex', flexDirection:'column', marginTop: 20}}>
                    <Text style={pageStyles.sectionHeader}>General Information:</Text>
                    <View style={{display:'flex', flexDirection:'row', gap: 100}}>
                        <View style={{display:'flex', flexDirection:'column', gap: 10}}>
                            <View style={{display:'flex', flexDirection:'row', gap: 5}}>
                                <Text style={pageStyles.title}>Name:</Text>
                                <Text style={pageStyles.detail}>{`${firstName} ${lastName}`}</Text>
                            </View>
                            <View style={{display:'flex', flexDirection:'row', gap: 5}}>
                                <Text style={pageStyles.title}>E-mail:</Text>
                                <Text style={pageStyles.detail}>{email}</Text>
                            </View>
                            <View style={{display:'flex', flexDirection:'row', gap: 5}}>
                                <Text style={pageStyles.title}>Last updated:</Text>
                                <Text style={pageStyles.detail}>{new Date().toLocaleDateString()}</Text>
                            </View>
                        </View>
                        <View style={{display:'flex', flexDirection:'row', gap: 10}}>
                            <Text style={pageStyles.title}>Number of videos watched:</Text>
                            <Text style={pageStyles.detail}>{clusters.reduce((prev, cur) => prev + cur.count, 0)}</Text>
                        </View>
                    </View>
                </View>
                <Svg {...pageStyles.lineDividerSvg}>
                    <Line {...pageStyles.lineDivider as any} />
                </Svg>
                {/* Summary */}
                <View style={{...pageStyles.view, display:'flex', flexDirection:'column'}}>
                    <Text style={pageStyles.sectionHeader}>Summary:</Text>
                    <Text style={pageStyles.detail}>
                        {`${firstName} ${lastName} has been recommended that they continue exploring the following careers based on their demonstrated interests and lifestyle wants on Future Gen's CareerAtlas. Please help ${firstName} ${lastName} narrow down their career decision so they may begin learning the skill sets necessary to take these interests to the next level and prepare to be ready for the modern workplace.`}
                    </Text>
                </View>
                {/* Chart */}
                <View style={{...pageStyles.view, display:'flex', flexDirection:'row', marginTop: 20}}>
                    <View style={{display:'flex', flexDirection:'column'}}>
                        <Text style={{...pageStyles.title, fontSize: 12}}>Distribution of watched videos</Text>
                        <ReactPDFChart style={{marginTop: 20, marginRight: 15}}>
                            <PieChart width={340} height={300}>
                                <Pie
                                    data={graphData}
                                    isAnimationActive={false}
                                    labelLine={false}
                                    dataKey={'count'}
                                    outerRadius={110}
                                    innerRadius={60}
                                    label={({count, percent}: {count: number; percent: number}) =>
                                        `${count} (${percent}%)`
                                    }>
                                </Pie>
                            </PieChart>
                        </ReactPDFChart>
                    </View>
                    <View style={{display:'flex', flexDirection:'column', gap: 10, justifyContent:'center', flexGrow: 1}}>
                        <Text style={{...pageStyles.title, fontSize: 12}}>Career Clusters:</Text>
                        {graphData.sort((a, b) => b.percent - a.percent).map((data, index) => (
                            <View key={index} style={{display:'flex', flexDirection:'row', gap: 5, alignContent: 'center', alignItems: 'center'}}>
                                <Svg height={10} width={10}>
                                    <Circle cx={5} cy={5} r={5} fill={data.fill} />
                                </Svg>
                                <View style={{display:'flex', flexDirection:'column', flexGrow: 1}}>
                                    {data.nameSplitArray.map((line, index) => (
                                        <Text key={index} style={{...pageStyles.detail, fontSize: 10}}>{line}</Text>
                                    ))}
                                </View>
                                <Text key={index} style={{...pageStyles.detail, fontSize: 10}}>{`${data.percent}%`}</Text>
                            </View>
                        ))}
                    </View>
                </View>
                {/* Footer */}
                {buildFooter(1)}
            </Page>
            {/*PAGE 2*/}
            <Page style={{fontFamily: 'Helvetica', display:'flex', flexDirection:'column', width:'100%', height:'100%'}}>
                {/* Header */}
                {buildHeader()}
                {/* Career Snapshot */}
                <View style={{...pageStyles.view, display:'flex', flexDirection:'column'}}>
                    <Text style={pageStyles.sectionHeader}>Career Snapshot:</Text>
                    <View style={{backgroundColor:'#DEE6FF', padding: 10, marginBottom: 10, ...pageStyles.borders}}>
                        <Text>
                            <Text style={{...pageStyles.detail, color: '#595FF3', fontFamily: 'Helvetica-BoldOblique'}}>
                                {'Note: '}
                            </Text>
                            <Text style={{...pageStyles.detail, fontFamily: 'Helvetica-Oblique'}}>
                                Outcomes are subject to change over time. User watch behavior may impact results. Therefore, it&apos;s important to recognize that the information provided is not static and may require periodic reassessment to ensure relevance and accuracy.
                            </Text>
                        </Text>
                    </View>
                    <View style={{backgroundColor: '#D5D5D6', padding: 10, marginBottom: 10, width: 218, ...pageStyles.borders}}>
                        <Text style={{...pageStyles.detail, fontFamily: 'Helvetica-Bold'}}>
                            {fastGrowingCareer}   Indicates Fast-Growing Careers
                        </Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'column', gap: 10, ...pageStyles.borders, borderWidth: 2, borderColor: '#DADADA', padding:4}}>
                        <View style={{display:'flex', flexDirection:'column', alignContent: 'center', gap: 10}}>
                            <View style={{display:'flex', flexDirection:'row', gap: 10, marginTop: 5, marginLeft: 5, marginRight: 5}}>
                                <Text style={{...pageStyles.snapShotDetail, color: '#979797', width: 200}}>Career Industry</Text>                                
                                <Text style={{...pageStyles.snapShotDetail, color: '#979797', flexGrow: 1}}>Occupations</Text>
                                <Text style={{...pageStyles.snapShotDetail, color: '#979797', width: 75}}>Match score</Text>
                            </View>
                            <Svg {...pageStyles.lineTableSvg}>
                                <Line {...pageStyles.lineTable as any} />
                            </Svg>
                        </View>
                        <View style={{display:'flex', flexDirection:'column', gap: 10, marginLeft: 8}}>
                            {occupations.map((occupation, index) => (
                                <View key={index} style={{display:'flex', flexDirection:'column', alignContent: 'center'}}>
                                    <View key={index} style={{display:'flex', flexDirection:'row', alignItems: 'center', marginLeft: 5, marginRight: 5, marginBottom: 10}}>
                                        <Text style={{...pageStyles.snapShotDetail, width: 200}}>
                                                {breakLines(occupation.category, 20)}
                                        </Text>
                                        <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center',  gap: 10,  ...pageStyles.snapShotDetail, flexGrow: 1}}>
                                            <Text style={pageStyles.snapShotDetail}>
                                                {breakLines(occupation.name, 40)}
                                            </Text>
                                            {occupation.fastGrowing && 
                                            <Text style={{...pageStyles.snapShotDetail}}>
                                                {fastGrowingCareer}
                                            </Text>
                                            }
                                        </View>
                                        <Text style={{...pageStyles.snapShotDetail, width: 75, textAlign: 'center'}}>{`${convertValue(occupation.rating)}%`}</Text>
                                    </View>
                                    {index < occupations.length - 1 && (
                                        <Svg {...pageStyles.lineTableSvg}>
                                            <Line {...pageStyles.lineTable as any} />
                                        </Svg>
                                    )}
                                </View>
                            ))}
                        </View>
                    </View>
                </View>
                {/* Footer */}
                {buildFooter(2)}
            </Page>
        </Document>
    );
};

export default InsightReport; 