import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { ROUND_HOVER_CHANGE_BUTTON, ROUND_HOVER_CHANGE_BUTTON_INVERSE, ROUND_HOVER_CHANGE_ICON_BUTTON, ROUND_HOVER_CHANGE_ICON_BUTTON_INVERSE_DARK } from '../../styles/buttons';
import { SxProps, Theme } from '@mui/material/styles';

type ButtonFormat = 'primary' | 'secondary' | 'tertiary' | 'text' | 'roundIconHover' | 'roundIconHover-focus' | 'roundHover' | 'roundHover-focus' | 'custom';

interface ButtonFeatures {
    variant: ButtonProps['variant'];
    style: SxProps<Theme>;
}

interface GlobalButtonProps extends Omit<ButtonProps, 'sx'> {
    format: ButtonFormat;
    text: string;
    sx?: SxProps<Theme>;
}

const buttonBrandFeaturesByType = (format: ButtonFormat): ButtonFeatures => {
    switch (format) {
        case 'primary':
            return {
                variant: 'contained',
                style: {}
            };
        case 'secondary':
            return {
                variant: 'outlined',
                style: { backgroundColor: '#ffffff' }
            };
        case 'tertiary':
            return {
                variant: 'text',
                style: { textDecoration: 'underline' }
            };
        case 'text':
            return {
                variant: 'text',
                style: { fontWeight: 900, fontSize: 18 }
            };
        case 'roundIconHover':
            return {
                variant: 'text',
                style: ROUND_HOVER_CHANGE_ICON_BUTTON
            };
        case 'roundIconHover-focus':
            return {
                variant: 'text',
                style: ROUND_HOVER_CHANGE_ICON_BUTTON_INVERSE_DARK
            };
        case 'roundHover':
            return {
                variant: 'text',
                style: ROUND_HOVER_CHANGE_BUTTON
            };
        case 'roundHover-focus':
            return {
                variant: 'text',
                style: ROUND_HOVER_CHANGE_BUTTON_INVERSE
            };
        case 'custom':
            return {
                variant: 'text',
                style: {}
            };
    }
};

export const GlobalButton: React.FC<GlobalButtonProps> = ({ format, text, style, ...passThroughProps }) => {
    const brandedButtonFeatures = buttonBrandFeaturesByType(format);
    const passThroughFeatures = {
        variant: brandedButtonFeatures.variant,
        ...passThroughProps
    };

    const buttonStyle = {
        borderRadius: 1,
        ...brandedButtonFeatures.style,
        ...style
    };

    return <Button {...passThroughFeatures} sx={buttonStyle as SxProps<Theme>}>{text}</Button>;
}; 