import React from 'react';
import { Controller, Control } from 'react-hook-form';
import startCase from 'lodash/startCase';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControlLabelProps } from '@mui/material/FormControlLabel';

interface GlobalCheckboxProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  id: string;
  control: Control<any>;
  defaultValue?: string;
  label?: string | React.ReactElement;
  
}

export const GlobalCheckbox: React.FC<GlobalCheckboxProps> = ({
  name,
  control,
  id,
  label,
  ...passthroughProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value }
      }) => (
        <FormControlLabel
          control={<Checkbox color="primary" checked={value} onChange={onChange} />}
          id={id}
          key={id}
          label={label || startCase(id)}
          {...passthroughProps}
        />
      )}
    />
  );
}; 