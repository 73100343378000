import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Policies: React.FC = () => {
    return (
        <>
            <Typography variant='h5' fontWeight={900} gutterBottom sx={{color: 'text.primary'}}>
                Policies
            </Typography>
            <Typography variant='body1' sx={{color: 'text.primary'}}>
                <Link href='https://www.futuregenxyz.com/terms-of-use' target="_blank" rel="noopener">Terms of Use</Link>
            </Typography>
            <Typography variant='body1' sx={{color: 'text.primary'}}>
                <Link href='https://www.futuregenxyz.com/privacy-policy' target="_blank" rel="noopener">Privacy Policy</Link>
            </Typography>
            <Typography variant='body1' sx={{color: 'text.primary'}}>
                <Link href='https://www.futuregenxyz.com/privacy-policy-for-california-residents' target="_blank" rel="noopener">California Residents Privacy Policy</Link>
            </Typography>
        </>
    );
};

export default Policies; 