import React from 'react';
import { Loading } from '../../../elements';

const AuthRedirect: React.FC = () => {
    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh', 
            width: '100%' 
        }}>
            <Loading message="Redirecting..." />
        </div>
    );
};

export default AuthRedirect;