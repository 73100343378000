import React, {useContext, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import CloseIcon from '@mui/icons-material/Close';

import {videoApi} from '../../api/openapi-axios-client';
import {Loading, Modal} from '../../elements';
import VideoPlayer from './VideoPlayer';
import RootContext from '../../services/context-states/root-context';
import {truncateString} from '../../helpers/strings';
import { GetHistory200Response, RewatchVideoData, VideoHistoryPagination, VideoWatchLogWithONet } from '../../types';
import { callApi } from '../../api/helpers';

const DEFAULT_PAGINATION: VideoHistoryPagination = {
    pageCount: 1,
    pageNumber: 1,
    totalCount: 0
};

const VideoHistoryPage: React.FC = () => {
    const {userContext} = useContext(RootContext);
    const {currentUser} = userContext;
    
    const [videoHistory, setVideoHistory] = useState<VideoWatchLogWithONet[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [displayRewatchModal, toggleRewatchModal] = useState<boolean>(false);
    const [rewatchVideoData, setRewatchVideoData] = useState<RewatchVideoData | null>(null);
    const [pagination, updatePagination] = useState<VideoHistoryPagination>(DEFAULT_PAGINATION);

    useEffect(() => {
        fetchVideoHistory(pagination.pageNumber);
    }, [currentUser]);

    const fetchVideoHistory = async (page: number): Promise<void> => {
        if (currentUser?.id) {
            setIsLoading(true);
            try {
                const data = await callApi<GetHistory200Response>(() => videoApi.watchLog.getHistory('paged', currentUser.id, page, 10, 'yes'));
                const {results, ...paginationData} = data;
                setVideoHistory(videoHistory.concat(results));
                updatePagination(paginationData);
            } catch (error) {
                // Handle error silently
            } finally {
                setIsLoading(false);
            }
        }
    };

    const rewatchVideo = (video: VideoWatchLogWithONet): void => {
        setRewatchVideoData({
            id: video.sourceId,
            watchId: video.watchId
        });
        toggleRewatchModal(true);
    };

    return (
        <>
        <Modal
            open={displayRewatchModal}
            onClose={() => toggleRewatchModal(false)}
            modalTitle='Watch Video'
            modalDescription='Watch Video'
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 5,
                p: 4,
            }}>
                <Stack spacing={2}>
                    <IconButton 
                        aria-label="close" 
                        onClick={() => toggleRewatchModal(false)} 
                        sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                        <CloseIcon />
                    </IconButton>
                    {rewatchVideoData?.id && <VideoPlayer 
                        video={rewatchVideoData} 
                        autoplay={1} 
                        height={'500'} 
                        width={'500'}
                        addtionalOptions={{}}
                        additionalVars={{}}
                        onEndFunc={() => {}}
                    />}
                </Stack>
        </Modal>
        {(isLoading && <Loading />) ||
            <Box sx={{display: 'flex', flexDirection:'column', gap: 2}}>
                <Typography sx={{fontWeight: 900, fontSize:'24px'}}>
                    Watch History
                </Typography>
                <Typography sx={{fontSize:'20px'}}>
                    Look at all the videos you&apos;ve already watched
                </Typography>
                <Box sx={{
                    display:'flex', 
                    flexDirection:'row', 
                    flexWrap:'wrap', 
                    gap: 5}}>
                        {videoHistory.length > 0 && videoHistory.map(video => (
                            <div key={video.id} style={{cursor: 'pointer'}} onClick={() => rewatchVideo(video)}>
                                <img src={video.thumbnail} alt={video.occupationTitle} style={{width: '275px', height: '175px'}} />
                                <Typography sx={{fontSize: 16, fontWeight: 'bold', color: 'text.primary'}}>
                                    {truncateString(video.videoTitle, 30)}
                                </Typography>
                            </div>
                        ))}
                </Box>
                {pagination && pagination.pageCount > pagination.pageNumber && (
                    <Typography 
                        sx={{cursor: 'pointer', alignSelf: 'center', color: 'primary.main', fontWeight: 900, fontSize: 18}} 
                        onClick={() => fetchVideoHistory(pagination.pageNumber + 1)}>
                        See More
                    </Typography>
                )}
            </Box>
        }
        </>
    );
};

export default VideoHistoryPage; 