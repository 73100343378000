import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';

import {Loading, FastGrowingCareer} from '../../elements';
import RootContext from '../../services/context-states/root-context';
import {assessmentApi} from '../../api/openapi-axios-client';
import {callApi} from '../../api/helpers';
import {fastGrowingCareer} from '../../helpers/strings';
import { CareerNavigationData, ONetCareerDetails, VideoHistoryRatingOccupationData } from '../../types';
import { VideoHistoryRating } from '../../types';
import { getClusterImage } from '../../helpers/images';
import shadows from '@mui/material/styles/shadows';

const ResultsPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {tokenContext, userContext} = useContext(RootContext);
    const {token} = tokenContext;    
    const [resultData, setResultData] = useState<VideoHistoryRating[]>([]);
    const [visibleLimit, setVisibleLimit] = useState<number>(3);
    const {currentUser} = userContext;
    
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        const getData = async (): Promise<void> => {
            try {
                const data = await callApi<VideoHistoryRating[]>(() => assessmentApi.ratings.getUserHistoryRating(currentUser?.id ?? '', true));

                if(data)  {
                    setResultData(data);
                }
    
                setIsLoading(false);
            }
            catch {
                setIsLoading(false);
            }
        };

        if(token && !resultData.length) {
            getData();
        }
    }, [token, resultData.length, currentUser?.id]);

    const BuildClusterObject = (data: VideoHistoryRating): React.ReactElement => {

        return (
            <Box key={`result-${data.category}`} sx={{display:'flex', flexDirection:'column', boxShadow: 8, cursor: 'pointer', background: 'container.background', maxWidth: 275, minWidth: 275, padding: 0}} onClick={() => navigate(`/careers/details/${currentUser?.id}/${data.categoryId}/${Number(data.categoryCode).toFixed(0)}`, {state: {clusterData: data}})}>
                <Box sx={{display:'flex'}}>
                    <img src={getClusterImage(data.categoryCode)} alt={data.categoryCode} style={{overflow: 'hidden', objectFit: 'cover', width: '100%', height: '155px'}}/>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, gap: 1, padding: 2}}>
                    <Typography sx={{fontWeight: 600, fontSize: '10pt', marginRight: 2, flexGrow: 1, color: 'text.primary'}}>{data.category}</Typography>
                    <Button variant="contained" color="primary" size='small' sx={{width: 'fit-content'}}>Learn more</Button>
                </Box>
            </Box>
        );
    }

    const buildOccupationItem = (cluster: VideoHistoryRating, occupation: VideoHistoryRatingOccupationData): React.ReactElement | null => {
        if(occupation.averageRating < 4) {
            return null;
        }

        const careerData: CareerNavigationData = {
            backLabel: 'Results',
            categoryId: cluster.categoryId,
            category: cluster.category,
            occupation: occupation.onet ? {
                ...occupation.onet,
                details: occupation.onet
            } as ONetCareerDetails & { details: ONetCareerDetails } : {} as ONetCareerDetails
        };

        return (
            <Box key={occupation.code} sx={{cursor:'pointer', background: 'container.background', border: 'container.border'}} onClick={() => navigate(`/careers/details/${currentUser?.id}/${cluster.categoryId}/career/${cluster.categoryId}-${occupation.occupationId}-${occupation.occupationId}`, {state: {careerData}})}>
                <Grid container columnSpacing={2} sx={{alignItems: 'center', marginTop: '5px', marginBottom: '5px'}}>
                    <Grid size={6}>
                        <Typography sx={{fontSize: 14, color: 'text.primary'}}>
                            {occupation.title}
                        </Typography>
                    </Grid>
                    <Grid size={4}>
                        <Typography sx={{fontSize: 14, color: 'text.primary'}}>
                            {cluster.category}
                        </Typography>
                    </Grid>
                    <Grid size={2}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'end', alignItems: 'center'}}>
                            {occupation?.onet?.tags?.bright_outlook && (
                                <Typography sx={{fontSize: 14, color: 'text.fastCareer', marginRight: 5}}>
                                    {fastGrowingCareer}
                                </Typography>) }
                            <KeyboardArrowRight sx={{color: 'grey.500', fontSize: '2.0rem', alignSelf: 'end'}}/>
                        </div>
                    </Grid>
                </Grid>
                <Divider sx={{color: 'divider', background: 'divider'}}/>
            </Box>
        );
    }

    const EmptyData = (): React.ReactElement => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', gap: 10, 
                alignItems: 'center', justifyContent: 'center', padding: '16px', 
                backgroundColor: theme.palette.container.background,
                marginTop: '5%',
                width: '80%',
                borderRadius: 2,
                border: 1,
                borderColor: theme.palette.container.border}}>
                <PrivacyTipIcon sx={{fontSize: 36, color: 'text.primary'}}/>
                <Typography sx={{fontSize: 16, color: 'text.primary'}}>
                    We're still learning more about your career preferences. Until then, please watch more videos to help us learn more about your career interests.
                </Typography>
            </div>
        )
    }

    const ResultData = (): React.ReactElement => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', padding: '16px'}}>
                <Typography sx={{fontSize: 22, fontWeight: 900, color: 'text.primary'}}>
                    Top 3 Industry Matches
                </Typography>
                <div style={{display: 'flex', flexDirection: 'row', gap: 30, marginTop: 10}}>
                    {resultData.slice(0, visibleLimit).map((data) => BuildClusterObject(data))}
                </div>
                <Divider sx={{marginTop: 3, marginBottom: 3}}/>
                <Typography sx={{fontSize: 22, fontWeight: 900, color: 'text.primary'}}>
                    Recommended Career Paths Based on Videos Watched
                </Typography>
                <Typography sx={{fontSize: 14, marginTop: 1, color: 'text.primary'}}>
                    These are the career paths we'd recommend based on the videos you've watched. Watch more videos to uncover more career paths.
                </Typography>
                <FastGrowingCareer sx={{marginTop: 1}}/>
                <Box padding={2} sx={{marginTop:1, borderRadius: 2, border: 1, borderColor: 'container.border', bgcolor: 'container.background'}}>
                    <Grid container spacing={2}>
                        <Grid size={6}>
                            <Typography sx={{fontSize: 16, color: 'text.secondary'}}>
                                Occupations
                            </Typography>
                        </Grid>
                        <Grid size={4}>
                            <Typography sx={{fontSize: 16, color: 'text.secondary'}}>
                                Industry
                            </Typography>
                        </Grid>
                        <Grid size={2}>
                            <Typography sx={{fontSize: 16, color: 'text.secondary'}}>
                                Occupaction Outlook
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{marginTop: 1, color: 'divider', background: 'divider', borderColor: 'divider'}}/>
                    {resultData.map((cluster) => cluster.occupationData.map((occupation) => buildOccupationItem(cluster, occupation)))}
                </Box>
            </div>
        )
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center', justifyContent: 'center'}}>
            {
                (isLoading && <Loading />) || ((resultData.length === 0 || resultData.length && !resultData.some((cluster) => cluster.occupationData.some((occupation) => occupation.averageRating > 2.5))) && <EmptyData />) || <ResultData />
            }
        </div>
    )
}

export default ResultsPage; 