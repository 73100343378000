import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

interface TabPanelProps extends Omit<BoxProps, 'children'> {
  children?: ReactNode;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, ...rest } = props;

  return (
    <Box
      component="div"
      role="tabpanel"
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      {...rest}
    >
      {children}
    </Box>
  );
} 