import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import RootContext from '../../services/context-states/root-context';
import checkLicense from '../../helpers/license';

import * as permissionHelper from '../../helpers/permissions';
import {types} from '../../api/openapi-axios-client';
import AccessFlags from 'types/access-flags';
import { GlobalAlertsProps, User } from '../../types';

const GlobalAlerts: React.FC<GlobalAlertsProps> = ({isLoggedIn = false}) => {
  const {billingContext, messageContext, accessFlagsContext, userContext} = useContext(RootContext);
  const {billingInformation} = billingContext;
  const {error, setError, successMessage, setSuccessMessage} = messageContext;
  const {currentUser} = userContext;
  const {accessFlags, setAccessFlags} = accessFlagsContext;

  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const enableBilling = (userData: User | null): boolean => {
    if(!userData?.additionalData) {
      return false;
    }
    
    if(!userData.additionalData.parentAccount && userData.additionalData.userType === types.UserType.Individual) {
      return false;
    } 

    if(!permissionHelper.checkPermission(userData.additionalData.role.permissions || [], permissionHelper.permissions.ManageBilling)) {
      return false;
    }

    return true;
  };

  const enableManageUsers = (userData: User | null): boolean => {
    if(!userData?.additionalData) {
      return false;
    }

    if(!permissionHelper.checkPermission(userData.additionalData.role.permissions || [], permissionHelper.permissions.ManageUsers)) {
      return false;
    }

    return true;
  };

  // Global Error/Success Reset / Clearing
  useEffect(() => {
    setError('');
    setSuccessMessage('');
  }, [location]);

  useEffect(() => { 
    // Reset Has Loaded anytime Billing Information Changes
    setHasLoaded(false);
  }, [billingInformation]);

  // Subscription Alert and Licensing alert
  useEffect(() => {
    if(hasLoaded) {
      return;
    }

    // Subscription Check
    // ******************************
    if (!currentUser) {
      // Dont show if Null as its first load and unknown
      return;
    }

    if (!billingInformation || !billingInformation.data) {
      setAccessFlags((prevState: AccessFlags) => ({
        ...prevState,
        showIncompleteProfile: true,
        enableManageBilling: enableBilling(currentUser),
        enableManageUsers: enableManageUsers(currentUser)
      }));

      setHasLoaded(true);
      return;
    }

    if(!billingInformation.isActive) {
      setAccessFlags((prevState: AccessFlags) => ({
        ...prevState,
        showIncompleteProfile: !billingInformation.isActive,
        enableManageBilling: enableBilling(currentUser),
        enableManageUsers: enableManageUsers(currentUser)
      }));

      setHasLoaded(true);
      return;
    }

    // License Check
    // **********************************

    if (billingInformation.data.usedLicenses.length === 0 && Number(billingInformation.data.licenses) === 0) {
      // No used licenses, so no need to show
      setAccessFlags((prevState: AccessFlags) => ({
        ...prevState,
        showIncompleteProfile: false,
        showMissingLicense: false,
        enableManageBilling: enableBilling(currentUser),
        enableManageUsers: enableManageUsers(currentUser)
      }));

      setHasLoaded(true);
      return;
    }

    setAccessFlags((prevState: AccessFlags) => ({
      ...prevState, 
      showIncompleteProfile: false,
      enableManageBilling: enableBilling(currentUser),
      enableManageUsers: enableManageUsers(currentUser),
      showMissingLicense: !checkLicense(currentUser.id, billingInformation?.data?.usedLicenses || []), 
      showUnusedLicenses: Number(billingInformation?.data?.licenses || 0) > (billingInformation?.data?.usedLicenses || []).length
    }));

    setHasLoaded(true);
  }, [currentUser, billingInformation, hasLoaded]);
  
  return (
    <div id='private-global-alert' style={{display:'flex', flexDirection:'column', justifyContent:'center', width: '100%'}}>
      {(error.length > 0) && <Alert severity="error">{error}</Alert>}
      {(successMessage.length > 0) && <Alert severity="success">{successMessage}</Alert>}
      {isLoggedIn && (
        <>
          {accessFlags.showIncompleteProfile &&
            <Alert severity="warning" sx={{width: '50%'}}
              action={
                <Button color="inherit" size="small" onClick={() => navigate('/settings?show=Billing')}>
                  Update
                </Button>
              }>
              Incomplete Profile: Go to Settings to complete your profile
            </Alert>
          }
          {accessFlags.showMissingLicense &&
            <Alert severity="warning" sx={{width: '50%'}}>
              License: You do not have a license to access the video content
            </Alert>
          }
        </>
      )}
    </div>
  );
};

export default GlobalAlerts; 