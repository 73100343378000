import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { CopyrightProps } from '../types';

const Copyright: React.FC<CopyrightProps> = (props) => {

  return (
    <Typography
      id='footer'
      color='text.secondary'
      align='center'
      sx={{ 
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '0',
        paddingRight: '0',
        zIndex: 1,
      }}
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://www.futuregenxyz.com/">
        Future Gen
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default Copyright; 