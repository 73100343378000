import React from 'react';
import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { PublicRouteProps } from '../types/route';

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => (
    <Container component="main">
        <CssBaseline />
        {children ? children : <Outlet />}
    </Container>
);

export default PublicRoute; 