import React, { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import AuthService from '../../services/auth';
import RootContext from '../../services/context-states/root-context';

import { callApi } from '../../api/helpers';
import { userApi } from '../../api/openapi-axios-client';

import { Model } from 'survey-core';
import 'survey-core/defaultV2.min.css';
import { Survey } from 'survey-react-ui';
import surveyJson from './survey-configs/registration-survey.json';
import { returnTheme } from './survey-configs/Theme';
import { MessageResponse } from '../../types';

const RegistrationSurvey: React.FC = () => {
    const { userContext } = useContext(RootContext);
    const { currentUser } = userContext;

    const [showSurvey, setShowSurvey] = useState<boolean>(false);
    
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const isInvite = searchParams.get('isInvite');

    const theme = useTheme();

    const survey = new Model(surveyJson);
    // @ts-expect-error - Ignoring type mismatch with backgroundImageFit property
    survey.applyTheme(returnTheme());
    survey.onComplete.add(async (sender) => {

        await callApi<MessageResponse>(() => userApi.userSurveys.createSurvey({
            userId: currentUser?.id || '',
            key: 'registration-survey',
            response: JSON.stringify(sender.data)
        }));

        navigate('/home?welcome=true');
    });

    const welcomeBanner = (): React.ReactElement => {
        return (
            <div style={{ 
                display: 'flex',
                flexDirection: 'row', 
                borderRadius: '5px',
                borderColor: theme.palette.container.border,
                backgroundColor: theme.palette.container.background,
                borderWidth: '1px',
                borderStyle: 'solid',
                padding: 30,
                gap: 20}}>
                <Typography sx={{fontSize: 48}}>{String.fromCodePoint(0x1F44B)}</Typography>
                <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                    <Typography sx={{fontSize: 18}}>Welcome to Future Gen's CareerAtlas!</Typography>
                    <Typography sx={{fontSize: 18}}>Before we get started, we'd love to ask you a few questions to get to know you.</Typography>
                    <Button variant='contained' color='primary' type='submit' style={{width: 150, marginTop: 25}} onClick={() => {

                        if(!currentUser || !currentUser.id || !AuthService.getCurrentUser()?.token) {
                            navigate('/');
                            return;
                        }

                        setShowSurvey(true)
                    }}>Get started</Button>
                </div>
            </div>
        )
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 100}}>
            {!showSurvey ? welcomeBanner() : <Survey model={survey} />}
        </div>
        
    );
};

export default RegistrationSurvey;