import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import RootContext from '../../services/context-states/root-context';
import {assessmentApi} from '../../api/openapi-axios-client';

import {convertValue} from '../../helpers/results';
import {truncateString} from '../../helpers/strings';

const ReportPage = () => {

    const {tokenContext, userContext} = useContext(RootContext);
    const {token} = tokenContext;    
    const [resultData, setResultData] = useState([]);
    const [visibleLimit, setVisibleLimit] = useState(3);
    const {currentUser} = userContext;
    
    const navigate = useNavigate();

    const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;

    const BuildResultObject = (data) => {

        const gaugeValue = convertValue(data.averageRating)
        let colorToUse = '#FF1414';

        if(gaugeValue <=45) {
            colorToUse = '#FF1414';
        }
        else if(gaugeValue > 45 && gaugeValue <= 79) {
            colorToUse = '#FFC000';
        }
        else if(gaugeValue > 79) {
            colorToUse = '#33F600';
        }

        return (
            <Box key={`result-${data.category}`} sx={{display:'flex', marginBottom: '10px', cursor: 'pointer'}} onClick={() => navigate(`/careers/details/${currentUser.id}/${data.categoryId}`, {state: {clusterData: data}})}>
                <Box sx={{display:'flex', color:colorToUse, justifyContent:'center', alignItems:'center', marginRight:4}}>
                    <CircularProgress variant="determinate" value={gaugeValue} color='inherit' size={120} thickness={6} sx={{borderRadius: '50%', boxShadow: `inset 0 0 0 ${6/44*120}px ${setOpacity(colorToUse, .2)}`}}/>
                    <Typography position='absolute' color="black" textAlign="center">{`${gaugeValue}%`}<br />Match</Typography>
                </Box>
                <Box sx={{display:'flex', flexDirection:'column', marginTop:2}}>
                    <Typography sx={{fontWeight: 900, marginBottom:1}}>{data.category}</Typography>
                    <Box sx={{display:'flex', flexDirection:'row'}}>
                        <Typography sx={{flex:1, marginRight:2}}>
                                {truncateString(data.categoryDescription, 250)} <span style={{color: '#0709FF', fontWeight: 'bold'}}>Read More</span>
                        </Typography>
                        <ArrowRightAltIcon sx={{color: 'grey.500', fontSize: '2.5rem'}}/>
                    </Box>
                </Box>
            </Box>
        );
    }

    useEffect(() => {

        const getData = async () => {
            const data = await assessmentApi.ratings.getUserHistoryRating(currentUser.id);

            if(data)  {
                setResultData(data);
            }
        };

        if(token && !resultData.length) {
            getData();
        }
    }, [token]);

    return (
        <Paper elevation={3}>
            {resultData.length === 0
                ?
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', height: '100vh'}}>
                    <Typography variant="h6">No data available</Typography>
                </Box>
                :
                <Grid container item spacing={0} xs={ 12 } sx={{padding:2}}>
                    {resultData.slice(0, visibleLimit).map((result) => (
                        BuildResultObject(result)
                    ))}
                    <Grid item xs={ 12 } sx={{textAlign: 'center', marginTop: '10px'}}>
                        {resultData.length > visibleLimit && (
                            <Typography variant="body2" sx={{color: 'grey.500', cursor: 'pointer'}} onClick={() => setVisibleLimit(visibleLimit === resultData.length ? 3 : resultData.length)}>
                                { visibleLimit === resultData.length ? 'Show Less' : 'Show More'}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            }
        </Paper>
    )
}


export default ReportPage