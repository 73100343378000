import { createTheme, Theme, PaletteMode } from '@mui/material/styles';

// Extend the MUI theme to include custom properties
declare module '@mui/material/styles' {
  interface TypeBackground {
    chartBar: string;
    navigation?: string;
  }

  interface TypeText {
    fastCareer?: string;
  }

  interface Palette {
    container: {
      background: string;
      border: string;
    },
    highlight: {
      navigation: string;
      tab: string;
    }
  }
}

// Shared theme settings
const getDesignTokens = (mode: PaletteMode) => ({
  typography: {
    fontFamily: 'Inter'
  },
  ...(mode === 'light' ? {
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#2B3EAA',
            '&:hover': {
              color: '#A8B2FF',
            },
            '&:visited': {
              color: '#A8B2FF',
            },
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
        root: {
          input: {
              '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 100px #FFFFFF inset',
              WebkitTextFillColor: 'default',
            },
          },
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#FFFFFF',
          },
          '& .MuiFilledInput-root': {
            backgroundColor: '#FFFFFF',
          },
          '& .MuiInputBase-root': {
            backgroundColor: '#FFFFFF',
          },
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100pxrgb(250, 250, 250) inset',
            WebkitTextFillColor: 'ffffff',
        },
        }
      }
    }
  }
} : {
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#4B5ACE',
            '&:hover': {
              color: '#9497FF',
            },
            '&:visited': {
              color: '#9497FF',
            },
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
                '&:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 100px #14151C inset',
                WebkitTextFillColor: 'default',
              },
            },
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#14151C',
            },
            '& .MuiFilledInput-root': {
              backgroundColor: '#14151C',
            },
            '& .MuiInputBase-root': {
              backgroundColor: '#14151C',
            }
          }
        }
      }
    }
  }),
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // Light mode
          primary: {
            main: '#2B3EAA',
            contrastText: '#ffffff'
          },
          secondary: {
            main: '#A8B2FF',
          },
          background: {
            default: '#F7F7F8',
            paper: '#ffffff',
            chartBar: '#2B3EAA',
            navigation: '#EFEFF1',
          },
          container: {
            background: '#FFFFFF',
            border: '#D9D9D9',
          },
          highlight: {
            navigation: '#DADADA',
            tab: '#C6CDF7',
          },
          text: {
            primary: '#000000',
            secondary: '#979797',
            fastCareer: '#C33333',
          },
          error: {
            main: '#C33333',
          },
          info: {
            main: '#2196F3',
          },
          warning: {
            main: '#FF9800',
          },
          divider: '#979797',
        }
      : {
          // Dark mode
          primary: {
            main: '#4B5ACE', // Slightly lighter blue for dark mode
            contrastText: '#ffffff'
          },
          secondary: {
            main: '#9497FF',
          },
          background: {
            default: '#212330',
            paper: '#1E1E1E',
            navigation: '#181A25',
            chartBar: '#4B5ACE',
          },
          container: {
            background: '#14151C',
            border: '#333333',
          },
          highlight: {
            navigation: '#272834',
            tab: '#272834',
          },
          text: {
            primary: '#FFFFFF',
            secondary: '#979797',
            fastCareer: '#FB7979',
          },
          error: {
            main: '#FB7979',
          },
          info: {
            main: '#64B5F6', // Lighter blue for dark mode
          },
          warning: {
            main: '#FF9800',
          },
          divider: '#979797',
        }),
  },
});

// Create the light theme
export const lightTheme: Theme = createTheme(getDesignTokens('light'));

// Create the dark theme
export const darkTheme: Theme = createTheme(getDesignTokens('dark'));

export default lightTheme; 