import React, {useContext, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Avatar from '@mui/material/Avatar';
import Check from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Mode from '@mui/icons-material/Mode';
import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import {PDFViewer} from '@react-pdf/renderer';
import InsightReport from './InsightReport';

import RootContext from '../../services/context-states/root-context';

import {assessmentApi} from '../../api/openapi-axios-client';

import {stringToColor} from '../../helpers/strings';

const ShareReportPage = () => {
    const {messageContext, tokenContext} = useContext(RootContext);
    const {token} = tokenContext;    
    const {setError, setSuccessMessage} = messageContext;
    
    const [loaded, setLoaded] = useState(false);
    const [dialogToOpen, setDialogToOpen] = useState('ADD');
    const [dialogOpen, setDialogOpen] = useState(false);

    const [shareData, setShareData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [usersForEdit, setUsersForEdit] = useState([]);
    const [selectedRelationship, setSelectedRelationship] = useState();

    const [previewReport, setPreviewReport] = useState(null);

    const emailRef = React.createRef();
    const relationshipRef = React.createRef();
    const otherRef = React.createRef();
    const firstNameRef = React.createRef();
    const lastNameRef = React.createRef();

    useEffect(() => {

        const getData = async () => {
            const shareDataResponse = await assessmentApi.resultSharing.getUsersSharedWith();
            setShareData(shareDataResponse);
            setLoaded(true);
        };

        if(token) {
            if(!loaded) {
                getData();
            }
        }
    }, [token, loaded]);

    const handleSelectedUserChanged = (user, status) => {
        if(status) {
            setSelectedUsers([...selectedUsers, user]);
        } 
        else {
            setSelectedUsers(selectedUsers.filter((selected) => selected.id !== user.id));
        }
    }

    const handleAddDialogOpen = () => {
        setSuccessMessage('');
        const sharedToEdit = selectedUsers.length === 1 ? selectedUsers[0].sharedWith?.map(shared => {
            return {
                ...shared,
                editMode: false
            }
        }) : [];

        setUsersForEdit(sharedToEdit);
        setDialogToOpen('ADD');
        setDialogOpen(true);
    }

    const handleViewPreviewDialogOpen = async (userId) => {
        // Get Report Data
        setDialogToOpen('LOADING');
        setDialogOpen(true);

        try {
            const reportData = await assessmentApi.resultSharing.getPreviewShareReportData(userId);

            // Set Report
            setPreviewReport(<InsightReport reportData={reportData}/>);
        }
        catch(error) {
            setPreviewReport(null);
        }

        setDialogOpen(false);
        
        setDialogToOpen('PREVIEW');
        setDialogOpen(true);
    }

    const handleDialogClose = (resultMessage) => {
        if(dialogToOpen === 'ADD') {
            setLoaded(false);
            setUsersForEdit([]);
            setSelectedUsers([]);
            setShareData([]);

            if(resultMessage) {
                setSuccessMessage(resultMessage);
            }

            setDialogOpen(false);
        }
        else {
            // Handle Preview Dialog
            setDialogOpen(false);
            setPreviewReport(null);
        }
    }

    const handleAdd = async (event) => {
        setError('');
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get('email');
        const firstName = data.get('firstName');
        const lastName = data.get('lastName');
        const relationship = data.get('relationship');
        const other = data.get('other');
        
        if(!email || !firstName || !lastName || !relationship) {
            setError('All fields are required');
            return;
        }

        if(relationship === 'other' && !other) {
            setError('All fields are required');
            return;
        }

        const user = {
            email,
            firstName,
            lastName,
            relationship: relationship === 'other' ? other : relationship
        }

        await handlePeopleWithAccessChanged(user, 'add');

        if(relationship === 'other') {
            otherRef.current.value = '';
        }

        emailRef.current.value = '';
        firstNameRef.current.value = '';
        lastNameRef.current.value = '';
        relationshipRef.current.value = '';
    };

    const handleEdit = (user, editMode) => {
        setUsersForEdit(usersForEdit.map((selected) => {
            if(selected.id === user.id) {
                return {
                    ...selected,
                    editMode
                }
            }
            return selected;
        }));
    }

    const handlePeopleWithAccessChanged = async (user, action) => {
        if(action === 'delete') {
            // Handle API Call to delete user
            const response = await assessmentApi.resultSharing.deleteShare(user.id);

            if(response.error) {
                setError(response.error);
                return;
            }

            setUsersForEdit(usersForEdit.filter((selected) => selected.id !== user.id));
        }

        if(action === 'add') {

            const addedUsers = [];

            for(const selected of selectedUsers) {

                // Handle API Call to add user
                const response = await assessmentApi.resultSharing.createShare({
                    ...user,
                    userId: selected.user.id
                });

                if(response.error) {
                    setError(response.error);
                    return
                }

                addedUsers.push({...response, editMode: false});
            }

            if(selectedUsers.length > 1) {
                handleDialogClose('Results Shared');
            }
            else {
                setUsersForEdit([...usersForEdit, ...addedUsers]);
            }
        }

        if(action === 'update') {

            // Handle API Call to update user
            const response = await assessmentApi.resultSharing.updateShare(user);

            if(response.error) {
                setError(response.error);
                return;
            }

            setUsersForEdit(usersForEdit.map((selected) => {
                if(selected.id === user.id) {
                    return user;
                }
                return selected;
            }));
        }
    }

    const buildPeopleWithAccess = user => {

        if(!user) {
            return null;
        }

        const colorForBorder = user.views > 0 ? '#04854F' : '#E08600';
        const colorForBackground = user.views > 0 ? '#D6F7E9' : '#F7EAD6';

        const ref = React.createRef();

        return (
            user.editMode ? (
                <div style={{display: 'flex', flexDirection: 'row', gap: 5, width: '100%', height:60, alignItems: 'center', marginBottom:15}}>
                    <TextField autoComplete='email' inputRef={ref} name={`edit-email-${user.id}`} required id='email' label='E-mail' size='small' autoFocus sx={{flexFlow: 1, width: '100%'}} defaultValue={user.email} />
                    <IconButton aria-label='save' onClick={async () => {user.email = ref.current.value; await handlePeopleWithAccessChanged(user, 'update'); handleEdit(user, false);}}><Check style={{fontSize:18}}/></IconButton>
                    <IconButton aria-label='cancel' onClick={() => {handleEdit(user, false)}}><CloseIcon style={{fontSize:18}}/></IconButton>
                </div>
            ) : (
                <div key={user.id} style={{display: 'flex', flexDirection: 'row', gap: 5, width: '100%', height:60, alignItems: 'center', marginBottom:15}}>
                    <IconButton aria-label='delete' onClick={async () => await handlePeopleWithAccessChanged(user, 'delete')}><CloseIcon style={{fontSize:18}}/></IconButton>
                    <IconButton aria-label='edit' onClick={()=> handleEdit(user, true)}><Mode style={{fontSize:18}}/></IconButton>
                    <Avatar sx={{bgcolor: stringToColor(`${user.firstName} ${user.lastName}`), height: 40, width: 40}}>{user.firstName[0]}</Avatar>
                    <div style={{display: 'flex', flexDirection: 'column', gap:0, marginLeft:10, flexGrow:1}}>
                        <Typography sx={{fontWeight: 'bold', fontSize:16}}>{user.firstName} {user.lastName}</Typography>
                        <Typography sx={{color: 'grey.500', fontSize:14}}>{user.email}</Typography>
                        <Typography sx={{color: 'grey.500', fontSize:14, textTransform:'capitalize'}}>{user.relationship}</Typography>
                    </div>
                    <Box sx={{border: '1px', borderStyle:'solid', borderColor: colorForBorder, background: colorForBackground, borderRadius: 2, height:40, width:120, display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Typography sx={{color: colorForBorder, fontSize:14}}>{user.views > 0 ? 'Active' : 'Pending'}</Typography>
                    </Box>
                </div>
            )
        )
    }

    const buildLoadingModal = () => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', height:'100%', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress sx={{width: 75, height: 75}}/>
                <Typography sx={{fontWeight: 900, fontSize:'24px', marginBottom: '20px', flexGrow:1}}>
                    Building Report ...
                </Typography>
            </div>
        );
    }

    const buildInviteControl = () => {
        return (
            <Box component='form' onSubmit={handleAdd} style={{padding: 20, display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'stretch', height:'100%'}}>
                <div style={{display: 'flex', flexDirection: 'row', gap: 10, width: '100%'}}>
                    <TextField inputRef={emailRef} autoComplete='email' name='email' required id='email' label='E-mail' autoFocus sx={{flexFlow: 1, width: '100%'}} />
                    <FormControl id={'relationship-form'} sx={{minWidth: 200}}>
                        <InputLabel id='relationship-label'>Relationship</InputLabel>
                        <Select inputRef={relationshipRef} fullWidth label='Relationship' id='relationship' name='relationship' labelId='relationship-label' value={selectedRelationship} onChange={e => setSelectedRelationship(e.target.value)}>
                            <MenuItem value='parent'>Parent</MenuItem>
                            <MenuItem value='teacher'>Teacher</MenuItem>
                            <MenuItem value='mentor'>Mentor</MenuItem>
                            <MenuItem value='counselor'>Counselor</MenuItem>
                            <MenuItem value='other'>Other</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {selectedRelationship === 'other' && (
                <div style={{display: 'flex', flexDirection: 'row', gap: 10, width: '100%'}}>
                    <TextField inputRef={otherRef} autoComplete='other' name='other' required id='other' label='Other' autoFocus sx={{flexFlow: 1, width: '100%'}} />
                </div>
                )}
                <div style={{display: 'flex', flexDirection: 'row', gap: 10, width: '100%'}}>
                    <TextField inputRef={firstNameRef} autoComplete='given-name' name='firstName' required id='firstName' label='First Name' sx={{flexFlow: 1, width: '100%'}} />
                    <TextField inputRef={lastNameRef} autoComplete='family-name' name='lastName' required id='lastName' label='Last Name' sx={{flexFlow: 1, width: '100%'}} />
                </div>
                <div style={{display: 'flex', flexDirection: 'row', gap: 10, width: '100%', justifyContent: 'end'}}>
                    <Button variant='contained' color='primary' type='submit'>Add User</Button>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 10, width: '100%', height:'100%'}}>
                    <Typography sx={{fontWeight:'bold'}}>People with Access</Typography>
                    <Box sx={{maxHeight: 260, flexGrow:1, flexShrink:1, overflow:'auto'}}>
                        {usersForEdit.map((user) => buildPeopleWithAccess(user))}
                    </Box>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', gap: 10, width: '100%', alignSelf:'end', justifyContent: 'end'}}>
                    <Button variant='contained' color='primary' sx={{width:100}} onClick={() => handleDialogClose('Results Shared')}>Done</Button>
                </div>
            </Box>
        )
    }

    const buildPreviewControl = () => {
        if(!previewReport) {
            return (
                <Typography sx={{fontWeight: 900, fontSize:'24px', margin: 20, flexGrow:1}}>
                    Error Previewing Report
                </Typography>
            );
        }
        else {
            return (
                <PDFViewer width='100%' height='100%'>
                    {previewReport}
                </PDFViewer>
            )
        }
    }

    const getDialogToOpen = () => {
        switch(dialogToOpen) {
            case 'LOADING':
                return buildLoadingModal();
            case 'ADD':
                return buildInviteControl();
            case 'PREVIEW':
                return buildPreviewControl();
        }
    }

    const getDialogTitle = () => {
        if(dialogToOpen === 'LOADING') {
            return 'Loading...';
        }
        else if(dialogToOpen === 'ADD') {

            if(selectedUsers.length > 0) {
                return selectedUsers.length > 1 
                    ? 'Multiple Users' 
                    : `Share ${selectedUsers[0].user.firstName} ${selectedUsers[0].user.lastName}'s Results`;
            }
            else {
                return 'Share Results';
            }
        }
        else {
            return 'View Insights Report';
        }
    }

    const getDialogWidth = () => {
        switch(dialogToOpen) {
            case 'LOADING':
                return '25%';
            case 'ADD':
                return '70%';
            case 'PREVIEW':
                return '100%';
        }
    }

    const getDialogHeight = () => {
        switch(dialogToOpen) {
            case 'LOADING':
                return '25%';
            case 'ADD':
                return '50%';
            case 'PREVIEW':
                return '100%';
        }
    }

    return (
        <>
        <Dialog open={dialogOpen} PaperProps={{sx: {width: getDialogWidth(), height: getDialogHeight()}}}>
          <DialogTitle>
            {getDialogTitle()}
            <IconButton aria-label="close" onClick={() => handleDialogClose(null)} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
            {getDialogToOpen()}
        </Dialog>    
            <Box>
                <Typography sx={{fontWeight: 900, fontSize:'24px', marginBottom: '20px', flexGrow:1}}>
                    Share Results
                </Typography>
                <Box sx={{
                    display:'flex', 
                    flexDirection:'row', 
                    flexWrap:'wrap', 
                    justifyContent: 'right', 
                    gap:'10px', 
                    minWidth:400, 
                    maxWidth:1140}}>
                        <Button variant='contained' color='primary' disabled={!(selectedUsers.length > 0)} sx={{height: 40, borderRadius: 2}} startIcon={<ShortcutOutlinedIcon/>} onClick={() => handleAddDialogOpen()}>
                            Share Results
                        </Button>
                        <TableContainer component={Paper} sx={{borderRadius:2}}>
                            <Table sx={{minWidth: 400}} size='small' aria-label='Users & Invites'>
                                <TableHead sx={{'& th': {
                                    color: 'grey.500'}}}>
                                    <TableRow>
                                        <TableCell sx={{width: 25}}></TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Shared to</TableCell>
                                        <TableCell align='center'>Preview</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {shareData?.map((row) => (
                                    <TableRow key={row.user.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell align='center' sx={{width:25}}><Checkbox onChange={(event) => handleSelectedUserChanged(row, event.target.checked)}/></TableCell>
                                        <TableCell component='th' scope='row'>{row.user.firstName} {row.user.lastName}</TableCell>
                                        <TableCell>{row.user.email}</TableCell>
                                        <TableCell>{row.sharedWith.length} people</TableCell>
                                        <TableCell align='center'><VisibilityOutlinedIcon sx={{color:'grey.600', width:30, height:30, cursor:'pointer'}} onClick={async () => await handleViewPreviewDialogOpen(row.user.id)}/></TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                </Box>
            </Box>
        </>
      )
}

export default ShareReportPage;