import React from 'react';
import Divider from '@mui/material/Divider';

interface GlobalHostedDividerProps {
  orientation?: 'horizontal' | 'vertical';
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const GlobalHostedDivider: React.FC<GlobalHostedDividerProps> = ({ children, style, orientation = 'horizontal' }) => {
    return (
        <div style={{
            display: 'flex', 
            flexDirection: orientation === 'horizontal' ? 'row' : 'column', 
            alignItems: 'center', 
            alignContent: 'stretch', 
            gap: 15, 
            height: orientation === 'vertical' ? '100%' : 'auto',
            alignSelf: 'stretch',
            ...style
        }}>
            <Divider orientation={orientation} sx={{color: 'divider', background: 'divider', flexGrow: 1, height: orientation === 'vertical' ? '35vh' : 'auto'}}/>
            {children}
            <Divider orientation={orientation} sx={{color: 'divider', background: 'divider', flexGrow: 1, height: orientation === 'vertical' ? '35vh' : 'auto'}}/>
        </div>
    );
  }; 