import React from 'react';
import Typography from '@mui/material/Typography';
import { ONetActivity, ONetCategory, ONetElementDetail, Task } from '../../../types';
import { pageStyles } from './styles';

interface CareerTabComponentsProps {
    index: number;
    title: string;
    data?: (ONetActivity | ONetElementDetail | ONetCategory | Task)[];
}

const CareerTab: React.FC<CareerTabComponentsProps> = ({ index, title, data }) => {
    return (
        <div style={{display:'flex', flexDirection:'column', gap: 0}}>
            <Typography sx={{...pageStyles.detailStyle}}>
                {title}
            </Typography>

            <ul>
                {data && data.map((element, i) => {
                    if ('name' in element && 'description' in element) {
                        return (
                            <li key={i} style={{marginTop: 15}}>
                                <Typography sx={{...pageStyles.detailStyle}}>
                                    <b>{element.name} — </b> {element.description}
                                </Typography>
                            </li>
                        );
                    }

                    if ('title' in element && 'example' in element) {
                        return (
                            <li key={i} style={{marginTop: 15}}>
                                <Typography sx={{...pageStyles.detailStyle}}>
                                    <b>{element.title.name} — </b> {element.example.map((example: any) => example.name).join(', ')}
                                </Typography>
                            </li>
                        );
                    }

                    if ('name' in element) {
                        return (
                            <li key={i} style={{marginTop: 15}}>
                                <Typography sx={{...pageStyles.detailStyle}}>
                                    {element.name}
                                </Typography>
                            </li>
                        );
                    }

                    if ('statement' in element) {
                        return (
                            <li key={i} style={{marginTop: 15}}>
                                <Typography sx={{...pageStyles.detailStyle}}>
                                    {element.statement}
                                </Typography>
                            </li>
                        );
                    }

                    return null;
                })}
            </ul>
        </div>
    );
};

export default CareerTab;