import { BlsWage, ConvertedSalary,ONetSalary, SalaryData } from '../types';

function handleSalaryData(baseHourly: number, baseAnnual: number) : SalaryData {
    let hourly = baseHourly;
    let annual = baseAnnual;

    if(!hourly && !annual) { 
        return {
            hourly: 0,
            annual: 0
        }
    }

    if(annual && !hourly) {
        hourly = annual / 2080.00;
    }

    if(hourly && !annual) {
        annual = hourly * 2080.00;
    }

    return {
        hourly: hourly,
        annual: annual
    }
}

export function shortenSalary(value: string): string {
    if(!value) {
        return 'N/A';
    }

    if(value.length === 3) {
        return value;
    }
    else if(value.length === 4) {
        return `${value[0]}.${value[1]}K`;
    }
    else if(value.length === 5) {
        return `${value.substring(0, 2)}.${value[2]}K`;
    }
    else if(value.length === 6) {
        return `${value.substring(0, 3)}.${value[3]}K`;
    }
    else {
        return value;
    }
}

export function convertValue(value: number) : number {
    return Math.round(value * 20);
}

export function returnSalaryData(salary : ONetSalary | BlsWage) : ConvertedSalary[] {

    // Salary Object
    /*
        ONET
        {
            "soc_code": "45-0000",
            "annual_10th_percentile": 30010,
            "annual_median": 35520,
            "annual_90th_percentile": 56640,
            "hourly_10th_percentile": 14.43,
            "hourly_median": 17.08,
            "hourly_90th_percentile": 27.23
        }

        BLS
        {
            hourlyAverage: item.hMean,
            annualAverage: item.aMean,

            hourlyTenPercent: item.hPct10,
            annualTenPercent: item.aPct10,
            
            hourlyTwentyFivePercent: item.hPct25,
            annualTwentyFivePercent: item.aPct25,
            
            hourlySeventyFivePercent: item.hPct75,
            annualSeventyFivePercent: item.aPct75,
            
            hourlyNinetyPercent: item.hPct90,
            annualNinetyPercent: item.aPct90
        }
    */

    if(!salary) {
        return [];
    }

    if ('annual_median' in salary || 'hourly_median' in salary) {
        const onetSalary = salary as ONetSalary;
        
        return [
            {
                key: 'ninetiethPercentile',
                ordering: 0,
                title: 'Top Earners',
                ...handleSalaryData(onetSalary.hourly_90th_percentile, onetSalary.annual_90th_percentile)
            },
            {
                key: 'average',
                ordering: 1,
                title: 'Average',
                ...handleSalaryData(onetSalary.hourly_median, onetSalary.annual_median)
            },
            {
                key: 'tenthPercentile',
                ordering: 2,
                title: 'Bottom Earners',
                ...handleSalaryData(onetSalary.hourly_10th_percentile, onetSalary.annual_10th_percentile)
            }
        ];
    }
    else {
        return [
            {
                key: 'ninetiethPercentile',
                ordering: 0,
                title: 'Top Earners',
                ...handleSalaryData(salary.hPct90, salary.aPct90)
            },
            {
                key: 'seventyFifthPercentile',
                ordering: 1,
                title: 'Top 25% Earners',
                ...handleSalaryData(salary.hPct75, salary.aPct75)
            },
            {
                key: 'average',
                ordering: 2,
                title: 'Average',
                ...handleSalaryData(salary.hMean, salary.aMean)
            },
            {
                key: 'twentyFifthPercentile',
                ordering: 3,
                title: 'Low 25% Earners',
                ...handleSalaryData(salary.hPct25, salary.aPct25)
            },
            {
                key: 'tenthPercentile',
                ordering: 4,
                title: 'Bottom Earners',
                ...handleSalaryData(salary.hPct10, salary.aPct10)
            }
        ];
    }
}