import React, { ReactNode } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';
import styles from './borderedSection.module.scss';

interface GlobalBorderedSectionProps {
  icon?: SvgIconComponent;
  title?: string;
  children?: ReactNode;
}

export const GlobalBorderedSection: React.FC<GlobalBorderedSectionProps> = ({ icon, title, children }) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <div className={styles.headerBorderBefore} />
        {(icon || title) && (
          <div className={styles.headerTitle}>
            {icon && <SvgIcon component={icon} />}
            {title && <span className={styles.title}>{title}</span>}
          </div>
        )}
        <div className={styles.headerBorderAfter} />
      </div>
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  );
}; 