import React from 'react';
import {useNavigate} from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {logoSquare} from '../../assets';
import { ThemeToggle } from '../ThemeToggle';
import { registeredTrademark } from '../../helpers/strings';

const TopAppBar: React.FC = () => {
    const navigate = useNavigate();

    return (
        <AppBar position='sticky' sx={{backgroundColor: 'background.navigation', width: '100vw', maxHeight: 65}}>
            <Toolbar sx={{justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', minWidth: 250}}>
                    <Box
                        component="img"
                        sx={{
                            maxWidth: 50,
                            cursor: 'pointer',
                        }}
                        alt="Future Gen logo"
                        src={logoSquare}
                        onClick={() => navigate('/')}
                    />
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2}}>
                        <Typography component='h4' variant='h4' sx={{color: 'text.primary', fontWeight: 900}}>
                            Future Gen
                        </Typography>
                        <Typography sx={{fontSize: 16, fontWeight: 500, color: 'text.primary'}}>{registeredTrademark}</Typography>
                    </div>

                </Box>
                <ThemeToggle />
            </Toolbar>
        </AppBar>
    );
};

export default TopAppBar; 