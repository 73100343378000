import React, {useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types'

import AuthService from '../../../services/auth';
import RootContext from '../../../services/context-states/root-context';
import {logoCircle} from '../../../assets';

// Drawer Contents
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';

// Icons
import LogoutIcon from '@mui/icons-material/Logout';
import {mainDrawer} from './DrawerContentsObject';

import {setResetApiAuth, userApi} from '@apis';
import { set } from 'react-hook-form';

const DrawerContents = ({toggleDrawer}) => {
    const [open, setOpen] = useState({});
    const {billingContext, accessFlagsContext, tokenContext, userContext} = useContext(RootContext)
    const {billingInformation, setBillingInformation} = billingContext;
    const {accessFlags} = accessFlagsContext;
    const {setCurrentUser, currentUser} = userContext;
    const {setToken} = tokenContext
    const [selectedItem, setSelectedItem] = useState('home');

    const navigate = useNavigate();

    const handleClick = (parentCat) => {
        setOpen((prevState => ({
            ...prevState,
            [parentCat]: !prevState[parentCat]
        })))
    };

    const logOut = async () => {

        await userApi.account.logout();

        AuthService.logout();
        setToken(null);
        setCurrentUser({});
        setBillingInformation(null);
        toggleDrawer(false);
        setResetApiAuth();
    };

    const BadgeComponent = (button) => (
        <Badge badgeContent={button.badgeValue}  
                invisible={!button.showBadge} 
                variant={button.badgeValue === -1 ? 'dot' : 'standard'}
                color="error">
            {button.icon}
        </Badge>
    );

    //

    return (
        <Box
            sx={{width: 250}}
            role="presentation">
            <nav aria-label="main navigation options">
                <List>

                    <ListSubheader onClick={() => navigate('/home')}
                        sx={{
                            cursor: 'pointer',
                            fontWeight: 900,
                            fontSize: 25,
                            color: 'black'
                        }}>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: 35,
                                mr: 1
                            }}
                            alt="Future Gen logo"
                            src={logoCircle}
                        />
                        {' '}
                        {'Future Gen'}
                    </ListSubheader>

                    {mainDrawer(currentUser, billingInformation, accessFlags).map(button => {
                        if (button.hidden) {
                            return;
                        } else if (button.subroutes?.length > 0) {
                            return (
                                <React.Fragment key={button.key} >
                                    <ListItemButton disabled={button.disabled} sx={{background: selectedItem === button.key ? '#DADADA' : 'transparent'}} onClick={() => {
                                        if (button.route && !button.disabled) {
                                            setSelectedItem(button.key);
                                            navigate(button.route)
                                        }

                                        if (!button.disabled) {
                                            handleClick(button.key)
                                        }
                                    }}>
                                            <ListItemIcon>
                                                {BadgeComponent(button)}
                                            </ListItemIcon>
                                        <ListItemText primary={button.text} />
                                        {open[button.key] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={open[button.key]} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {button.subroutes.map(nestedButton => {
                                                if (!nestedButton.hidden) {
                                                    return (
                                                        <ListItemButton disabled={nestedButton.disabled} sx={{pl: 4, background: selectedItem === nestedButton.key ? '#DADADA' : 'transparent'}} key={nestedButton.key} onClick={() => {
                                                            if (nestedButton.route && !nestedButton.disabled) {
                                                                setSelectedItem(nestedButton.key);
                                                                navigate(nestedButton.route)
                                                            }
                                                        }}>
                                                            <ListItemText primary={nestedButton.text} />
                                                        </ListItemButton>
                                                    )
                                                }
                                            })}
                                        </List>
                                    </Collapse>
                                    {button.displayDivider && <Divider />}
                                </React.Fragment>
                            )
                        } else {
                            return (
                                <React.Fragment key={button.key} >
                                    <ListItem disabled={button.disabled} disablePadding >
                                        <ListItemButton sx={{background: selectedItem === button.key ? '#DADADA' : 'transparent'}} onClick={() => {
                                            if (button.route && !button.disabled) {
                                                setSelectedItem(button.key);
                                                navigate(button.route);
                                            }
                                        }}>
                                            <ListItemIcon>
                                                {BadgeComponent(button)}
                                            </ListItemIcon>
                                            <ListItemText primary={button.text} />
                                        </ListItemButton>
                                    </ListItem>
                                    {button.displayDivider && <Divider />}
                                </React.Fragment>
                            )
                        }
                    })}
                </List>

                <List sx={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%'
                }}>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton onClick={logOut} >
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary='Logout' />
                        </ListItemButton>
                    </ListItem>
                </List>

            </nav>
        </Box>
    )
}

DrawerContents.propTypes = {
    toggleDrawer: PropTypes.func.isRequired
}

export default DrawerContents