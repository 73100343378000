import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Image} from 'mui-image';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {userApi} from '@apis';
import Copyright from '../Copyright';
import {landingImage} from '../../assets'

const ResetPassword = () => {

  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setError('');
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email');

    if (!email)
    {
      setError('No Email entered');
      return;
    }

    const submissionsData = {
      email
    };

    userApi.password.requestReset({...submissionsData})
      .then(data => {
        if (data?.message)
        {
          navigate('/');
        }
        else
        {
          setError('error requesting password reset')
        }
      })
      .catch(() => setError('error requesting reset'))
  };

  return (
    <Grid container direction='row' justifyContent="space-evenly" spacing={ 5 } sx={ {mt: 10} }>

      <Grid size={ 6 } display={ {xs: 'none', md: 'block'} }>
        <Image src={ landingImage } />
      </Grid>

      <Grid center size={ 6 }>

        { error && <Alert severity='error'>{ error }</Alert> }

        <Typography component='h5' variant='h5' sx={ {fontWeight: 900} }>
          Reset Your Password
        </Typography>
        <Typography component='p' variant='body1' >
          Submit your email and if your email is in our system we will send you a reset link.
        </Typography>
        <Box component='form' onSubmit={ handleSubmit } noValidate sx={ {mt: 1} }>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            style={ {mt: 3, mb: 2} }
          >
            Request Password Reset
          </Button>
          <Grid container sx={ {justifyContent: 'space-evenly', margin: 1} } >
            <Grid>
              <Link
                component='button'
                variant='body1'
                onClick={ (event) => { event.preventDefault(); navigate('/login');}}>
                Sign In
              </Link>
            </Grid>
            <Grid>
              <Link
                component='button'
                variant='body1'
                onClick={ (event) => { event.preventDefault(); navigate('/register');}}>
                Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Copyright/>
    </Grid>
  )
}

ResetPassword.propTypes = {
  actions: PropTypes.shape({
    closeModal: PropTypes.func
  }),
}

export default ResetPassword