import React from 'react';
import { SocialLoginButtonProps } from 'types/component-props';
import './third-party-button.css';

interface ThirdPartyLoginButtonProps extends SocialLoginButtonProps {
  logo: React.ReactNode;
  text: string;
}

const ThirdPartyLoginButton: React.FC<ThirdPartyLoginButtonProps> = ({
  type = 'signup',
  mode = 'light',
  disabled = false,
  logo,
  text
}) => {
  return (
    <button 
      className={`third-party-button-${mode === 'light' ? 'light' : 'dark'}`} 
      disabled={disabled}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 16px',
        borderRadius: '4px',
        border: `1px solid ${mode === 'light' ? '#dadce0' : '#3c4043'}`,
        backgroundColor: mode === 'light' ? '#fff' : '#202124',
        color: mode === 'light' ? '#3c4043' : '#e8eaed',
        fontFamily: 'Roboto, arial, sans-serif',
        fontSize: '14px',
        fontWeight: 500,
        height: '40px',
        cursor: 'pointer',
        boxShadow: '0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15)',
        position: 'relative',
        width: '240px',
        transition: 'background-color 0.218s, border-color 0.218s, box-shadow 0.218s'
      }}
    >
      <div 
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          backgroundColor: mode === 'light' ? '#3c4043' : '#e8eaed',
          transition: 'opacity 0.218s'
        }}
        className={`third-party-button-state-${mode === 'light' ? 'light' : 'dark'}`}
      />
      <div 
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          position: 'relative'
        }}
        className={`third-party-button-content-wrapper-${mode === 'light' ? 'light' : 'dark'}`}
      >
        <div 
          style={{
            marginRight: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          className={`third-party-button-icon-${mode === 'light' ? 'light' : 'dark'}`}
        >
          {logo}
        </div>
        <span 
          style={{
            flexGrow: 1,
            textAlign: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
          className={`third-party-button-contents-${mode === 'light' ? 'light' : 'dark'}`}
        >
          {text}
        </span>
      </div>
    </button>
  );
};

export default ThirdPartyLoginButton; 