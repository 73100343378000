import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface GlobalLoadingProps {
  message?: string;
}

export const GlobalLoading: React.FC<GlobalLoadingProps> = ({ message }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
      <CircularProgress sx={{ width: 75, height: 75 }} />
      <Typography sx={{ fontSize: 20, marginLeft: 2 }}>
        {!message ? 'Loading...' : message}
      </Typography>
    </div>
  );
}; 