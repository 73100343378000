import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TooltipRenderProps } from 'react-joyride';

const toolTipStyle = {
  backgroundColor: '#4C52D3',
  primaryColor: '#00000057',
  textColor: '#FFFFFF',
} as const;

const AppTourToolTip: React.FC<TooltipRenderProps> = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
  isLastStep,
}) => {
  return (
    <Box
      {...tooltipProps}
      sx={{
        maxWidth: 400,
        minWidth: 400,
        padding: 2,
        backgroundColor: toolTipStyle.backgroundColor,
        borderRadius: 1,
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
      }}
    >
      {step.title && (
        <Typography variant="h6" sx={{ mb: 1, color: toolTipStyle.textColor }}>
          {step.title}
        </Typography>
      )}
      
      <Typography variant="body1" sx={{ mb: 2, color: toolTipStyle.textColor }}>
        {step.content}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        {step.showSkipButton && (
          <Button 
            variant='text' 
            {...skipProps} 
            sx={{
              color: toolTipStyle.textColor, 
              fontSize: 10, 
              marginRight: 'auto'
            }}
          >
            Done?&nbsp;<span style={{textDecoration: 'underline'}}>Click here to skip</span>
          </Button>
        )}

        {(index > 0 && !step.hideCloseButton) && (
          <Button
            {...backProps}
            variant="contained"
            size="small"
            sx={{
              color: toolTipStyle.textColor, 
              backgroundColor: toolTipStyle.primaryColor
            }}
          >
            Back
          </Button>
        )}
        
        {continuous ? (
          <Button
            {...primaryProps}
            variant="contained"
            size="small"
          >
            {isLastStep ? 'Done' : 'Next'}
          </Button>
        ) : (
          <Button
            {...closeProps}
            variant="contained"
            size="small"
            sx={{backgroundColor: toolTipStyle.primaryColor}}
          >
            Got It!!
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AppTourToolTip; 