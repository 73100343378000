import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid2';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {types, setResetApiAuth, userApi} from '@apis';

import {Button, FormCheckbox, FormInput, Radio, UserInvites} from '@elements';
import RootContext from '../../services/context-states/root-context';
import AuthService from '../../services/auth';
import Copyright from '../Copyright';

const Register = () => {
    const [searchParams] = useSearchParams();
    const {tokenContext, userContext} = useContext(RootContext)
    const {setToken} = tokenContext
    const {setCurrentUser} = userContext
    const [error, setError] = useState('');
    const [registrationType, setRegistrationType] = useState('');
    const [agreementChecked, setAgreementChecked] = useState(false);
    const [userInvites, setUserInvites] = useState([]);
    const {control, handleSubmit, register, watch} = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
            termsAndPolicies: false,
            registrationOptions: {
                registerFor: '',
                registerOthers: false,
                othersToInvite: []
            }
        }
    });

    const navigate = useNavigate();

    const isInvite = searchParams.get('invite');

    const handleRadioChange = (event) => {
        setRegistrationType(event.target.value);
    };

    useEffect(() => {
        if (isInvite) {
            setRegistrationType('myself');
        }
    }, []);

    // necessary for password valdation 
    const password = watch('password');

    // necessary for password valdation 
    useEffect(() => {
        register('password');
    }, [register]);

    const toggleAgreement = (e) => setAgreementChecked(e.target.checked);

    const buildLabel = (registrationType, baseLabel) => registrationType === 'others' ? `Your ${baseLabel}` : baseLabel;

    const handleFormSubmit = (data) => {
        setError('');
        
        // eslint-disable-next-line no-unused-vars, prefer-const
        let submissionData = data;

        if (isInvite) {
            submissionData = {
                ...submissionData,
                isInvite: true,
                parentId: searchParams.get('parentId') || null,
                inviteId: searchParams.get('invite') || null,
            }
        }

        if(registrationType !== 'myself') {

            submissionData.registrationOptions = {
                registerOthers: true,
                othersToInvite: userInvites
            }
        }

        userApi.account.register({
            audience: 'user-web-app',
            ...submissionData
        }, types.AuthorizationType.User)
            .then(data => {
                if (data?.token) {
                    localStorage.setItem('user', JSON.stringify({
                        ...data,
                        expiry: new Date().getTime() + 5000
                    }))
                    setToken(data.token)
                    setCurrentUser(AuthService.decodeUserToken(data.token));
                    setResetApiAuth();
                    navigate(isInvite ? '/home' : '/settings?show=Billing');
                } else {
                    setError('Registration unsuccessful, please try again later')
                }
            })
            .catch((err) => {
                setError(err?.message || 'Invalid. This information may already exist')
            });
    };

    return (
        <Grid container direction='column' sx={{mt: 5}}>

            {error && <Alert severity='error'>{error}</Alert>}

            <Typography component='h1' variant='h5' sx={{fontWeight: 900}}>
                Create an Account
            </Typography>

            {!isInvite &&
                <Grid>
                    <Typography component='p' variant='body1' >
                        Who are you registering this account for?
                    </Typography>

                    <RadioGroup
                        aria-labelledby="registration-radio-buttons-group"
                        name="registration-radio-buttons-group"
                        onChange={handleRadioChange}
                        sx={{display: 'flex', flexDirection: 'row'}}>
                        <Radio label="Myself" value="myself" />
                        <Radio label="Others" value="others"/>
                    </RadioGroup>

                </Grid>
            }

            <Box component='form' onSubmit={handleSubmit(handleFormSubmit)} sx={{mt: 3}}>
                {!!registrationType &&
                    <>
                        <Grid container spacing={2}>
                            {registrationType === 'others' &&
                                <Grid size={12}>
                                    <FormInput
                                        autoFocus
                                        control={control}
                                        fullWidth
                                        id='registration-option'
                                        label='Who are you registering for?'
                                        name='registrationOptions.registerFor'
                                        required={true}
                                    />
                                </Grid>
                            }

                            <Grid size={6}>
                                <FormInput
                                    autoComplete='given-name'
                                    autoFocus
                                    control={control}
                                    fullWidth
                                    id='firstName'
                                    label= {buildLabel(registrationType, 'First Name')}
                                    name='firstName'
                                    required={true}
                                />
                            </Grid>

                            <Grid size={6}>
                                <FormInput
                                    autoComplete='family-name'
                                    control={control}
                                    fullWidth
                                    id='lastName'
                                    label= {buildLabel(registrationType, 'Last Name')}
                                    name='lastName'
                                    required
                                />
                            </Grid>

                            <Grid size={12}>
                                <FormInput
                                    autoComplete='email'
                                    control={control}
                                    fullWidth
                                    id='email'
                                    label= {buildLabel(registrationType, 'Email')}
                                    name='email'
                                    required
                                    rules={{
                                        required: 'A valid email is required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email address',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid size={6}>
                                <FormInput
                                    autoComplete='new-password'
                                    control={control}
                                    fullWidth
                                    required
                                    name='password'
                                    label='Password'
                                    type='password'
                                    id='password'
                                    rules={{
                                        required: 'Password is required',
                                        minLength: {
                                            value: 8,
                                            message: 'Password must be at least 8 characters',
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: 'Password can not exceed 100 characters',
                                        },
                                        validate: {
                                            uppercase: (value) =>
                                                /[A-Z]/.test(value) || 'Password must contain at least one uppercase letter',
                                            lowercase: (value) =>
                                                /[a-z]/.test(value) || 'Password must contain at least one lowercase letter',
                                            hasTwoDigits: (value) =>
                                                (value.match(/\d/g) || []).length >= 2 ||
                                                'Password must contain at least two digits',
                                            hasTwoSpecialCharacters: (value) =>
                                                (value.match(/[@$!%*?&]/g) || []).length >= 1 ||
                                                'Password must contain at least two special characters (@ $ ! % * ? &)',
                                            noSpaces: (value) =>
                                                !/\s/.test(value) || 'Password cannot contain spaces',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid size={6}>
                                <FormInput
                                    autoComplete='new-password'
                                    control={control}
                                    fullWidth
                                    required
                                    name='passwordConfirm'
                                    label='Password'
                                    type='password'
                                    id='password-confirm'
                                    rules={{
                                        required: 'Please confirm your password',
                                        validate: (value) =>
                                            value === password || 'Passwords do not match',
                                    }}
                                />
                            </Grid>

                            <Grid size={2} />

                            {registrationType === 'others' &&
                                <Grid size={12} sx={{display:'flex', flexDirection:'column'}}>
                                    <Typography variant='caption' sx={{fontSize:18}}>Invite Additional Users</Typography>
                                    <Box sx={{flexGrow: 1, padding: 2, minHeight:400, border: 1, borderRadius:2, borderColor: 'grey.500'}}>
                                        <UserInvites userInvites={userInvites} setUserInvites={setUserInvites} />
                                    </Box>
                                </Grid>
                            }

                            <Grid size={8}>
                                <FormCheckbox
                                    aria-label='Terms and Policies'
                                    control={control}
                                    id='termsAndPolicies'
                                    key='termsAndPolicies'
                                    name='termsAndPolicies'
                                    label={
                                        <span>
                                            By checking this box, I certify that I am at least 13 years old and that I agree to the Terms and Policies, <a href='https://www.futuregenxyz.com/terms-of-use'>Terms of Use</a>, and <a href='https://www.futuregenxyz.com/privacy-policy'>Private Policy</a>. This service is for the U.S. only.
                                        </span>
                                    }
                                    onClick={e => toggleAgreement(e)}
                                />
                            </Grid>
                            <Grid size={2} />

                            <Grid align='center' size={12}>
                                <Button disabled={!agreementChecked} format='primary' type='submit' text='Sign Up' style={{'minWidth': '400px'}} />
                            </Grid>
                        </Grid>

                        <Grid container justifyContent='center'
                            sx={{mt: {xs: 5, sm: 10}}}>
                            <Grid>
                                {'Already have an account? '}
                                <Link
                                    component='button'
                                    variant='body1'
                                    onClick={() => navigate('/login')}
                                >
                                    Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </>
                }
            </Box>
            <Copyright />
        </Grid >
    )
}

Register.propTypes = {
    isInvite: PropTypes.bool,
    parentId: PropTypes.string,
    inviteId: PropTypes.string,
    actions: PropTypes.shape({
        changeComponent: PropTypes.func,
        closeModal: PropTypes.func,
        toggleLoggedIn: PropTypes.func,
    }),
}

export default Register