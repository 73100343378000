import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';

import BackArrow from '@mui/icons-material/ArrowBack';
import {BarChart, BarItem, BarLabel, BarLabelContext} from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { SelectChangeEvent } from '@mui/material';

import {useTheme} from '@mui/material/styles';

import CareerTabPanel from './CareerTabPanel';
import Education from './Education';
import CareerTab from './CareerTab';

import {assessmentApi, videoApi} from '../../../api/openapi-axios-client';
import {callApi} from '../../../api/helpers';
import {HorizontalScrollBox, Loading, Modal} from '../../../elements';
import VideoPlayer from '../../videos/VideoPlayer';
import {returnSalaryData, shortenSalary} from '../../../helpers/salary';
import {fastGrowingCareer, truncateString} from '../../../helpers/strings';
import RootContext from '../../../services/context-states/root-context';
import {baseSalaryTableData, baseSalaryChartData} from './base-data';
import { 
    BlsWage,
    CareerCluster,
    CareerNavigationData, 
    CareerDetailVisibility,
    ConvertedSalary, 
    ONetCareerDetails, 
    ONetElementDetail, 
    ONetOccupationAlternateTitles,
    SalaryChartData,
    SalaryCityFilter,
    SalaryStateFilter,
    SalaryTableData, 
    Video
} from '../../../types';
import { pageStyles, tabsStyle, tabStyle } from './styles';
import WorkContext from './WorkContext';

const CareerDetailsPage: React.FC = () => {
    const {messageContext} = useContext(RootContext);
    const {setError} = messageContext;

    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [displayVideoModal, setDisplayVideoModal] = useState<boolean>(false);
    const [relatedVideos, setRelatedVideos] = useState<Video[] | null>(null);
    const [videoWatch, setVideoWatch] = useState<any | null>(null);

    const [backLabel, setBackLabel] = useState<string>('');
    const [categoryId, setCategoryId] = useState<string>('');
    const [category, setCategory] = useState<string>('');
    const [occupation, setOccupation] = useState<ONetCareerDetails & { backUrl?: string, hideAlternateTitles?: boolean } | null>(null);

    const [salaryTypeToShow, setSalaryTypeToShow] = useState<string>('annual');
    const [salaryData, setSalaryData] = useState<any[]>([]);

    const [salaryStateFliters, setSalaryStateFilters] = useState<SalaryStateFilter[]>([]);
    const [selectedSalaryStateFilter, setSelectedSalaryStateFilter] = useState<string>('national');

    const [salaryCityFilters, setSalaryCityFilters] = useState<SalaryCityFilter[]>([]);
    const [selectedSalaryCityFilter, setSelectedSalaryCityFilter] = useState<string>('all');
    
    const [salaryTableData, setSalaryTableData] = useState<SalaryTableData>(baseSalaryTableData);
    const [salaryChartData, setSalaryChartData] = useState<SalaryChartData>(baseSalaryChartData);

    const [showHideSettings, setShowHideSettings] = useState<CareerDetailVisibility>({
        showAlternateTitles: true,
        showWages: true,
        showEducation: true,
        showKeyCharacteristics: true,
        showWorkContext: true
    });

    const navigate = useNavigate();
    const {state} = useLocation();
    const {userId, careerClusterId, detailId} = useParams();
    const {careerData} = state as { careerData?: CareerNavigationData } || {};

    const theme = useTheme();
    const loadSource = searchParams.get('source');
    const [tabValue, setTabValue] = useState<number>(0);

    const numberFormatter = (value: number | string, decimal: number): string => {
        return parseFloat(parseFloat(value.toString()).toFixed(decimal)).toLocaleString('en-US', {useGrouping: true});
    };

    const handleStateSalaryFilterChange = (e: SelectChangeEvent) => {
        const value = e.target.value as string;
        setSelectedSalaryStateFilter(value);
        setSelectedSalaryCityFilter('all');

        if(value === 'national') {
            setSalaryCityFilters([]);
            generateSalaryData('national', null);
        }
        else {
            const selectedState = [
                {
                    value: 'all',
                    display: 'All Cities',
                    salaryData: returnSalaryData(salaryData.filter(item => item.areaType === 2 && item.primState === value)[0] || salaryData[0])
                }
            ];
            
            salaryData.filter(item => item.areaType === 4 && item.primState === value).forEach((item) => {
                selectedState.push({
                    value: item.id,
                    display: item.areaTitle.replace(`, ${item.primState}`, ''),
                    salaryData: returnSalaryData(item)
                });
            });

            setSalaryCityFilters(selectedState as SalaryCityFilter[]);
            generateSalaryData(value, 'all');
        }
    }

    const handleCitySalaryFilterChange = (e: SelectChangeEvent) => {
        const value = e.target.value as string;
        setSelectedSalaryCityFilter(value);
        generateSalaryData(selectedSalaryStateFilter, value);
    }

    const generateSalaryData = (stateFilter: string, cityFilter: string | null) => {
        const title = salaryTypeToShow === 'annual' ? 'Annual Salary' : 'Hourly Salary';

        if(stateFilter === 'national') {
            const salaries = salaryStateFliters.find(item => item.value === 'national')?.salaryData;

            if (salaries) {
                setSalaryTableData({
                    title: title,
                    location: 'National',
                    salaries: salaries
                });

                generateSalaryChartData(title, 'National', salaries);
            }
        }
        else if (stateFilter !== 'national' && cityFilter === 'all') {
            const selectedState = salaryStateFliters.find(item => item.value === stateFilter);
            if (selectedState) {
                const salaries = selectedState.salaryData;

                setSalaryTableData({
                    title: title,
                    location: selectedState.display,
                    salaries: salaries
                });

                generateSalaryChartData(title, selectedState.display, salaries);
            }
        }
        else if (cityFilter && cityFilter !== 'all') {
            const selectedCity = salaryCityFilters.find(item => item.value === cityFilter);
            if (selectedCity) {
                setSalaryTableData({
                    title: title,
                    location: selectedCity.display,
                    salaries: selectedCity.salaryData
                });

                generateSalaryChartData(title, selectedCity.display, selectedCity.salaryData);
            }
        }
        else {
            setSalaryTableData(baseSalaryTableData);
            setSalaryChartData(baseSalaryChartData);
        }
    }

    const generateSalaryChartData = (title: string, location: string, data: any[]) => {
        try {
            let dataToUse = data;

            if(data.length !== 5) {
                dataToUse = [
                    {
                        key: 'tenthPercentile',
                        title: '10th Percentile',
                        annual: data.find(item => item.key === 'tenthPercentile')?.annual,
                        hourly: data.find(item => item.key === 'tenthPercentile')?.hourly
                    },
                    {
                        key: 'twentyFifthPercentile',
                        title: '',
                        annual: (data.find(item => item.key === 'tenthPercentile')?.annual || 0) + 100,
                        hourly: (data.find(item => item.key === 'tenthPercentile')?.hourly || 0) + 1
                    },
                    {
                        key: 'average',
                        title: 'Average',
                        annual: data.find(item => item.key === 'average')?.annual,
                        hourly: data.find(item => item.key === 'average')?.hourly
                    },
                    {
                        key: 'seventyFifthPercentile',
                        title: '',
                        annual: (data.find(item => item.key === 'ninetiethPercentile')?.annual || 0) - 100,
                        hourly: (data.find(item => item.key === 'ninetiethPercentile')?.hourly || 0) - 1
                    },
                    {
                        key: 'ninetiethPercentile',
                        title: '90th Percentile',
                        annual: data.find(item => item.key === 'ninetiethPercentile')?.annual,
                        hourly: data.find(item => item.key === 'ninetiethPercentile')?.hourly
                    }
                ];
            }
    
            const chartData: SalaryChartData = {
                title: title,
                location: location,
                annual: {
                    values: [10, 25, 50, 25, 10],
                    labels: dataToUse.sort((a, b) => b.ordering - a.ordering).map((item, index) => {
                        if(item.key === 'average') {
                            if(!item.annual) {
                                return `${location.substring(0, 10)} Average: N/A`;
                            }
                            return `${location.substring(0, 10)} Average: $${shortenSalary(item.annual.toString())}`;
                        }
                        else if(item.key === 'tenthPercentile' || item.key === 'ninetiethPercentile') {
                            if(!item.annual) {
                                return 'N/A';
                            }
                            return `$${shortenSalary(item.annual.toString())}`;
                        }
                        else {
                            return ' '.repeat(index);
                        }
                    }),
                    colors: dataToUse.map((item) => item.key === 'average' ? theme.palette.background.chartBar || theme.palette.primary.main : theme.palette.secondary.main)
                },
                hourly: {
                    values: [10, 25, 50, 25, 10],
                    labels: dataToUse.sort((a, b) => b.ordering - a.ordering).map((item, index) => {
                        if(item.key === 'average') {
                            if(!item.hourly) {
                                return `${location.substring(0, 10)} Average: N/A`;
                            }
                            return `${location.substring(0, 10)} Average: $${shortenSalary(item.hourly.toString())}`;
                        }
                        else if(item.key === 'tenthPercentile' || item.key === 'ninetiethPercentile') {
                            if(!item.hourly) {
                                return 'N/A';
                            }
                            return `$${shortenSalary(item.hourly.toString())}`;
                        }
                        else {
                            return ' '.repeat(index);
                        }
                    }),
                    colors: dataToUse.map((item) => item.key === 'average' ? theme.palette.background.chartBar || theme.palette.primary.main : theme.palette.secondary.main)
                }
            };
    
            setSalaryChartData(chartData);
        }
        catch(e) {
            console.error(e);
        }
    }

    const generateRelatedVideoCard = (video: any, i: number) => {
        return (
            <Box key={`related-${i}`} sx={{
                display:'flex',
                flexDirection:'column',
                cursor:'pointer',
                background:'white',
                alignContent:'center',
                justifyContent:'center',
                justifyItems:'center'}} onClick={() => {setVideoWatch(video);setDisplayVideoModal(true)}}>
                <img src={video.thumbnail} style={{width: '250px', height: '150px'}} alt={video.title} />
                <Typography sx={{fontSize: 12, fontWeight: 'bold', color: 'text.primary'}}>
                    {truncateString(video.title, 35)}
                </Typography>
            </Box>
        )
    }

    const generateTabProps = (index: number) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const generateTabsAndContentPanels = () => {

        const tabs = [
            {
                label: 'Knowledge', 
                title: 'Knowledge is the understanding you have about a given topic, gained through learning, experience, or observation. Expertise in these critical domains is vital for success in this profession:',
                data: occupation?.knowledge?.element
            },
            {
                label: 'Skills',
                title: 'These basic skills are developed capacities that facilitate learning or the more rapid acquisition of knowledge:',
                data: occupation?.skills?.element
            },
            {
                label: 'Technology Skills',
                title: 'Technological skills are the application of machines or technological systems technologies to design, set-up, operate, and correct malfunctions:',
                data: occupation?.technologySkills?.category
            },
            {
                label: 'Abilities',
                title: 'Abilities are enduring attributes of the individual that influence performance:',
                data: occupation?.abilities?.element
            },
            {
                label: 'Detailed Work Activities',
                title: 'Work activities are general types of job behaviors occurring on multiple jobs:',
                data: occupation?.detailedWorkActivities?.activity
            },
            {
                label: 'Tasks',
                title: 'These are the day-to-day tasks that are expected in this role:',
                data: occupation?.tasks?.task
            },
            {
                label: 'Work Styles',
                title: 'Work styles are personal characteristics that can affect how well someone performs a job. These are the work styles that are critical to a person\'s success in this occupation:',
                data: occupation?.workStyles?.element
            },
            {
                label: 'Work Values',
                title: 'Work values are global aspects of work that are important to a person\'s satisfaction.  These are the work values important to this career:',
                data: occupation?.workValues?.element
            }
        ];

        const toReturn: {label: string, index: number, panel: React.ReactNode}[] = [];
        let tabIndex = 0;

        tabs.forEach((tab) => {

            switch(tab.label) {
                case 'Knowledge':
                case 'Skills':
                case 'Technology Skills':
                case 'Abilities':
                case 'Detailed Work Activities':
                case 'Work Styles':
                case 'Work Values': {
                    if(loadSource === 'library') {
                        toReturn.push({
                            label: tab.label,
                            index: tabIndex,
                            panel: <CareerTab index={tabIndex} title={tab.title} data={tab.data} />
                        });
                        tabIndex++;
                    }
                    break;
                }
                case 'Tasks':
                    toReturn.push({
                        label: tab.label,
                        index: tabIndex,
                        panel: <CareerTab index={tabIndex} title={tab.title} data={tab.data} />
                    });
                    tabIndex++;
                    break;
            }
        });

        return toReturn;
    };

    useEffect(() => {
        const getData = async () => {

            let sectionsToShowOrHide = {
                showAlternateTitles: true,
                showEducation: true,
                showWages: false,
                showKeyCharacteristics: true,
                showWorkContext: true
            };
            
            let displayData: CareerNavigationData;

            if(careerData) {
                displayData = {
                    backLabel: careerData.backLabel,
                    categoryId: careerData.categoryId,
                    category: careerData.category,
                    occupation: {
                        ...careerData.occupation,
                        ...careerData.occupation.details
                    }
                }
    
                setBackLabel(displayData.backLabel || '');
                setCategoryId(displayData.categoryId || '');
                setCategory(displayData.category || '');
                setOccupation(displayData.occupation);
                sectionsToShowOrHide = {
                    ...sectionsToShowOrHide,
                    showAlternateTitles: displayData.occupation?.alternateTitles?.length ? true : false,
                    showEducation: displayData.occupation?.education ? true : false,

                    showKeyCharacteristics: displayData.occupation?.knowledge?.element?.length 
                        || displayData.occupation?.skills?.element?.length 
                        || displayData.occupation?.technologySkills?.category?.length 
                        || displayData.occupation?.abilities?.element?.length 
                        || displayData.occupation?.detailedWorkActivities?.activity?.length 
                        || displayData.occupation?.tasks?.task?.length 
                        || displayData.occupation?.workStyles?.element?.length 
                        || displayData.occupation?.workValues?.element?.length ? true : false,

                    showWorkContext: (displayData.occupation && displayData.occupation?.workContext?.element?.some((element) => element.hasOwnProperty('response'))) || false
                };
            }
            else {
                // Get the Cluster / Occupation / Career data
                const cluster = await callApi<CareerCluster>(() => assessmentApi.careerData.getCareerCluster(careerClusterId || ''));

                if(!cluster) {
                    setError('Unable to load career details');
                    setIsLoading(false);
                    return;
                }

                const clusterData = await callApi<ONetOccupationAlternateTitles>(() => 
                    assessmentApi.careerData.getOccupationDetails(careerClusterId || '', detailId || '')
                );

                if(!clusterData) {
                    setError('Unable to load career details');
                    setIsLoading(false);
                    return;
                }
                
                const careerDetails : ONetCareerDetails & {
                    backUrl?: string;
                    hideAlternateTiltles?: boolean;
                } = await callApi<ONetCareerDetails>(() => assessmentApi.careerData.getONetCareerData(clusterData.occupationCode));

                if(!careerDetails) {
                    setError('Unable to load career details');
                    setIsLoading(false);
                    return;
                }

                if(clusterData.occupationAlternateTitle) {
                    careerDetails.hideAlternateTiltles = true;
                    careerDetails.title = clusterData.occupationAlternateTitle;
                    careerDetails.backUrl = `/library/cluster/${careerClusterId}`;
                }
                else {
                    careerDetails.backUrl = `/careers/details/${userId}/${careerClusterId}`
                }

                displayData = {
                    backLabel: cluster.title,
                    categoryId: cluster.id,
                    category: cluster.title,
                    occupation: careerDetails
                }

                setBackLabel(displayData.backLabel || '');
                setCategoryId(displayData.categoryId || '');
                setCategory(displayData.category || '');
                setOccupation(displayData.occupation);
                sectionsToShowOrHide = {
                    ...sectionsToShowOrHide,
                    showAlternateTitles: displayData.occupation?.alternateTitles?.length ? true : false,
                    showEducation: displayData.occupation?.education ? true : false,

                    showKeyCharacteristics: displayData.occupation?.knowledge?.element?.length 
                        || displayData.occupation?.skills?.element?.length 
                        || displayData.occupation?.technologySkills?.category?.length 
                        || displayData.occupation?.abilities?.element?.length 
                        || displayData.occupation?.detailedWorkActivities?.activity?.length 
                        || displayData.occupation?.tasks?.task?.length 
                        || displayData.occupation?.workStyles?.element?.length 
                        || displayData.occupation?.workValues?.element?.length ? true : false,

                    showWorkContext: (displayData.occupation && displayData.occupation?.workContext?.element?.some((element) => element.hasOwnProperty('response'))) || false
                };
            }

            if(!relatedVideos && displayData?.occupation?.code) {
                const related = await callApi<Video[]>(() => videoApi.videos.getRelatedVideos(displayData?.occupation?.code, 4));

                if(related) {
                    setRelatedVideos(related);
                }
                else {
                    setRelatedVideos([]);
                }
            }

            if((!salaryData || !salaryData.length) && displayData?.occupation?.code) {
                const blsData = await callApi<BlsWage[]>(() => assessmentApi.careerData.getBlsWageData(displayData?.occupation?.code));

                if(blsData && blsData.length) {
                    setSalaryData(blsData);
                    sectionsToShowOrHide = {
                        ...sectionsToShowOrHide,
                        showWages: true
                    };

                    const convertedNationalStateData = blsData.sort((a, b) => a.areaType - b.areaType)
                        .filter(item => item.area <= 99 && item.area !== 66 && item.area !== 72)
                        .map((item) => {
                            return {
                                value: item.areaType === 1 ? 'national' : item.primState,
                                display: item.areaType === 1 ? 'National' : item.areaTitle,
                                salaryData: returnSalaryData(item)
                            }
                        });

                    setSalaryStateFilters(convertedNationalStateData);

                    setSalaryTableData({
                        title: salaryTypeToShow === 'annual' ? 'Annual Salary' : 'Hourly Salary',
                        location: 'National',
                        salaries: convertedNationalStateData.find(item => item.value === 'national')?.salaryData || []
                    });

                    generateSalaryChartData(
                        salaryTypeToShow === 'annual' ? 'Annual Salary' : 'Hourly Salary',
                        'National',
                        convertedNationalStateData.find(item => item.value === 'national')?.salaryData || []
                    );
                }
                else {
                    if(displayData.occupation?.jobOutlook?.salary) {
                        const oNetSalaryData = returnSalaryData(displayData.occupation.jobOutlook.salary);

                        setSalaryData(oNetSalaryData);
                        sectionsToShowOrHide = {
                            ...sectionsToShowOrHide,
                            showWages: true
                        };

                        setSalaryTableData({
                            title: salaryTypeToShow === 'annual' ? 'Annual Salary' : 'Hourly Salary',
                            location: 'National',
                            salaries: oNetSalaryData
                        });

                        generateSalaryChartData(
                            salaryTypeToShow === 'annual' ? 'Annual Salary' : 'Hourly Salary',
                            'National',
                            oNetSalaryData
                        );
                    }
                }
            }

            setShowHideSettings(sectionsToShowOrHide);
        }
        
        try {
            getData();
        }
        finally {
            setIsLoading(false);
        }
    }, []);

    return (
        <>
            <Modal
                open={displayVideoModal}
                onClose={() => setDisplayVideoModal(false)}
                modalTitle='Watch Video'
                modalDescription='Watch Video'
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 700,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 5,
                    p: 4,
                }}>
                <Stack spacing={2}>
                    <IconButton 
                        aria-label="close" 
                        onClick={() => setDisplayVideoModal(false)} 
                        sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                        <CloseIcon />
                    </IconButton>
                    {videoWatch?.id && <VideoPlayer 
                        video={videoWatch} 
                        autoplay={1}
                        addtionalOptions={{}}
                        additionalVars={{}}
                        onEndFunc={() => {}}
                    />}
                </Stack>
            </Modal>
            {(isLoading && <Loading />) ||
                <div style={{width:'100%', height:'100%', gap: 2}}>
                    {/* NAV HEADER */}
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start', gap: 1.5}}>
                        <IconButton onClick={() => occupation?.backUrl ? navigate(occupation.backUrl) : navigate(-1)}>
                            <BackArrow sx={{color: 'text.secondary'}}/>
                        </IconButton>
                        <Typography sx={{fontSize: 20, color: 'text.secondary', verticalAlign:'center', cursor:'pointer'}} 
                            onClick={() => occupation?.backUrl ? navigate(occupation.backUrl) : navigate(-1)}>
                            {`Return to ${backLabel}`}
                        </Typography>
                    </div>
                    {/* NAV HEADER */}
                    <Box padding={4} sx={{display:'flex', flexDirection:'column', borderRadius: 2, border: 1, borderColor: 'container.border', bgcolor: 'container.background'}}>
                        
                        {/* RELATED VIDEOS */}
                        <div style={{...pageStyles.infoDiv, marginTop: 0, marginBottom: 15}}>
                            <Typography sx={pageStyles.headerStyle}>
                                Related Content
                            </Typography>
                            <HorizontalScrollBox toRender={relatedVideos && relatedVideos.length ? relatedVideos.map((related, i) => generateRelatedVideoCard(related, i)) : []} />
                        </div>
                        {/* RELATED VIDEOS */}

                        {/* OCCUPATION HEADER */}
                        <div style={{display: 'flex', flexDirection: 'column', gap: 0, alignItems: 'flex-start'}}>
                            <div style={{display:'flex', flexDirection:'row', gap: 25, alignItems: 'center'}}>
                                <Typography sx={{fontSize: 20, fontWeight: 900, color: 'text.primary'}}>
                                    {occupation?.title}
                                </Typography>
                                {occupation?.tags?.bright_outlook && (
                                    <Typography sx={{fontSize: 14, color: 'text.fastCareer'}}>
                                        {`${fastGrowingCareer} Fast-growing career`}
                                    </Typography>)
                                }
                            </div>
                            {
                                backLabel !== category && (
                                    <div style={{display:'flex', flexDirection:'row', gap: 5, alignItems: 'center', cursor: 'pointer'}} onClick={() => navigate(`/library/cluster/${categoryId}?backLocation=${occupation?.title}`)}>
                                        <Typography sx={{fontSize: 14, color: 'text.secondary'}}>
                                            {`Career Industry: ${category}`}
                                        </Typography>
                                        <OpenInNewIcon sx={{fontSize: 16, color: 'primary.main'}}/>
                                    </div>
                                )
                            }
 
                        </div>
                        {/* OCCUPATION HEADER */}

                        {/* OCCUPATION OVERVIEW */}
                        <div style={pageStyles.infoDiv}>
                            <Typography sx={pageStyles.headerStyle}>
                                Overview
                            </Typography>
                            <Typography sx={pageStyles.detailStyle}>
                                {occupation?.description}
                            </Typography>
                        </div>
                        {/* OCCUPATION OVERVIEW */}
                        
                        {/* GENERAL */}
                        {(showHideSettings.showAlternateTitles || showHideSettings.showEducation || showHideSettings.showWages) && (
                            <div style={{...pageStyles.infoDiv}}>
                                <Typography sx={pageStyles.headerStyle}>
                                    General
                                </Typography>

                                {/* JOB TITLES */}
                                {(!occupation?.hideAlternateTitles && showHideSettings.showAlternateTitles) && (
                                    <Typography sx={{...pageStyles.detailStyle}}>
                                        <b>Sample of reported job titles:</b> {occupation?.alternateTitles?.join(', ') || ''}
                                    </Typography>
                                )}
                                {/* JOB TITLES */}

                                {/* EDUCATION */}
                                {showHideSettings.showEducation && (
                                    <div style={{display:'flex', flexDirection:'column', gap: 5, marginTop: 10}}>
                                        <Typography sx={{...pageStyles.detailStyle, fontWeight: 900}}>
                                            Education
                                        </Typography>
                                        <Typography sx={pageStyles.detailStyle}>
                                            How much education does a new hire need to perform a job in this occupation? Respondents said:
                                        </Typography>
                                        <div style={{display:'flex', flexDirection:'column', gap: 5, marginTop: 10}}>
                                            {occupation?.education?.level_required?.category?.sort((a, b) => (b.score?.value || 0) - (a.score?.value || 0)).map((level) => <Education data={level} />)}
                                        </div>
                                    </div>
                                )}
                                {/* EDUCATION */}
                                
                                {/* WAGE */}
                                {showHideSettings.showWages && (
                                    <div style={{display:'flex', flexDirection:'column', gap: 15, marginTop: 15}}>
                                        <Typography sx={{...pageStyles.detailStyle, fontWeight: 900}}>
                                            Wage & Employment Trends
                                        </Typography>
                                            {/* FILTERS */}
                                            <div style={{display:'flex', flexDirection:'row', gap: 15, alignItems: 'center'}}>
                                                {salaryStateFliters.length > 0 && (
                                                <>
                                                <Select sx={{fontSize:14, width: 200, fontWeight:900}} variant='standard' value={selectedSalaryStateFilter} onChange={handleStateSalaryFilterChange} displayEmpty size={'small'}>
                                                    {salaryStateFliters.map((item, index) => <MenuItem key={index} value={item.value}>{item.display}</MenuItem>)}
                                                </Select>
                                                <Select disabled={selectedSalaryStateFilter === 'national'} sx={{fontSize:14, width: 500, fontWeight:900}} variant='standard' value={selectedSalaryCityFilter} onChange={handleCitySalaryFilterChange} displayEmpty size={'small'}>
                                                    {salaryCityFilters.map((item, index) => <MenuItem key={index} value={item.value}>{item.display}</MenuItem>)}
                                                </Select>
                                                </>
                                                )}
                                                <Select sx={{fontSize:14, width:125, fontWeight:900}} variant='standard' value={salaryTypeToShow} onChange={(e: SelectChangeEvent) => setSalaryTypeToShow(e.target.value)} displayEmpty size={'small'}>
                                                    <MenuItem value={'annual'}>Annual Salary</MenuItem>
                                                    <MenuItem value={'hourly'}>Hourly Salary</MenuItem>
                                                </Select>
                                            </div>
                                            {/* FILTERS */}
                                        <div style={{display:'flex', flexDirection:'row', gap: 5, width:'100%', flexGrow: 1}}>
                                            {/* CHART VIEW */}
                                            <div style={{display:'flex', flexDirection:'column', gap: 5, flexGrow: 1}}>
                                                <Typography sx={{fontSize: 14, color: 'text.secondary'}}>
                                                {salaryChartData.location} wage:
                                                </Typography>
                                                {salaryData && salaryData.length > 0 &&
                                                (
                                                    <div>
                                                        <BarChart
                                                        height={300}
                                                        borderRadius={5}
                                                        leftAxis={null}
                                                        bottomAxis={{
                                                            disableLine: true,
                                                            disableTicks: true
                                                        }}
                                                        tooltip={{trigger: 'none'}}
                                                        axisHighlight={{
                                                            x: 'none',
                                                            y: 'none'
                                                        }}
                                                        barLabel={((item : BarItem, context: BarLabelContext) => {
                                                            return (
                                                                <BarLabel {...item} {...context} isHighlighted={false} isFaded={false} color="text.primary"/>
                                                            );
                                                        }) as any}
                                                        series={[
                                                        {data: salaryChartData[salaryTypeToShow as 'annual' | 'hourly'].values, id: 'pvId', hideTooltip: true} as any,
                                                        ]}
                                                        xAxis={[{data: salaryChartData[salaryTypeToShow as 'annual' | 'hourly'].labels, scaleType: 'band', labelColor: 'text.secondary', hideTooltip: true,
                                                            colorMap: {
                                                                type: 'ordinal',
                                                                colors: salaryChartData[salaryTypeToShow as 'annual' | 'hourly'].colors
                                                            }
                                                        } as any]}/>
                                                    </div>
                                                )}
                                            </div>
                                            {/* CHART VIEW */}
                                            {/* TABLE VIEW */}
                                            <div>
                                                <Typography sx={{fontSize: 14, color: 'text.secondary'}}>
                                                    Table view:
                                                </Typography>
                                                <TableContainer sx={{borderRadius: '10px', border: 1, borderColor: 'container.border'}}>
                                                    <Table size='small' sx={{borderCollapse: 'collapse', borderStyle: 'hidden', '& td': {border: 1, borderColor:'container.border'}}}>
                                                        <TableHead sx={{background: theme.palette.background.default}}>
                                                            <TableRow>
                                                                <TableCell sx={{width: 175}}>
                                                                    <Typography sx={{fontSize: 14, color: 'text.secondary'}}>
                                                                        {salaryTableData.title}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell sx={{width: 125}}>
                                                                    <Typography sx={{fontSize: 14, color: 'text.secondary'}}>
                                                                        {salaryTableData.location.substring(0, 8)}{salaryTableData.location.length > 8 ? '...' : ''}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {salaryTableData.salaries.sort((a, b) => a.ordering - b.ordering).map((item : ConvertedSalary, index: number) => {
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell>
                                                                            <Typography sx={{fontSize: 14, color: 'text.primary'}}>
                                                                                {item.title}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography sx={{fontSize: 14, color: 'text.primary'}}>
                                                                                {item[salaryTypeToShow as keyof ConvertedSalary] && item[salaryTypeToShow as keyof ConvertedSalary] !== 'N/A' ? `$${numberFormatter(item[salaryTypeToShow as keyof ConvertedSalary], 2)}` : 'N/A'}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                            {/* TABLE VIEW */}
                                        </div>
                                    </div>
                                )}
                                {/* WAGE */}
                            </div>
                        )}
                        {/* GENERAL */}
                        
                        {/* KEY CHARACTERISTICS */}
                        {showHideSettings.showKeyCharacteristics && (
                            <div style={{...pageStyles.infoDiv, gap: 15, width:'100%'}}>
                                <Typography sx={pageStyles.headerStyle}>
                                    Key Characteristics
                                </Typography>
                                {/* TABS */}
                                <div style={{display:'flex', flexDirection:'row', gap:0, width:'100%', flexGrow: 1}}>
                                    <Tabs orientation="vertical" 
                                        variant="scrollable" 
                                        aria-label="Key Characteristics Tabs" 
                                        value={tabValue} 
                                        onChange={(event, index) => {setTabValue(index)}} 
                                        sx={{...tabsStyle, background: theme.palette.background.default, borderColor: 'container.border'}}>
                                        {generateTabsAndContentPanels().map((tab) => <Tab label={tab.label} {...generateTabProps(tab.index)} sx={tabStyle} />)} 
                                    </Tabs>
                                    {generateTabsAndContentPanels().map((tab) => <CareerTabPanel value={tabValue} index={tab.index}>{tab.panel}</CareerTabPanel>)}
                                </div>
                                {/* TABS */}

                                {/* WORK CONTEXT */}
                                {(loadSource === 'library' && showHideSettings.showWorkContext) && (
                                    <>
                                    <Typography sx={{...pageStyles.detailStyle, fontWeight: 900, marginTop: 2}}>
                                        Work Context
                                    </Typography>
                                    <Typography sx={{...pageStyles.detailStyle}}>
                                        Hear what others have to say about what their day-to-day look like:
                                    </Typography>
                                    <div style={{display:'flex', flexDirection:'column', gap: 10, marginTop: 10}}>
                                        {occupation?.workContext?.element?.slice(0, occupation?.workContext?.element.length > 5 ? 5 : occupation?.workContext?.element.length)
                                            .map((item, index) => <WorkContext key={index} item={item as ONetElementDetail & { response: { percentage: number; name: string; }[] }} index={index + 1} />)}
                                    </div>
                                    </>
                                )}
                                {/* WORK CONTEXT */}
                            </div>
                        )}
                        {/* KEY CHARACTERISTICS */}
                    </Box>
                </div>
            }
        </>
    );
}

export default CareerDetailsPage; 