const pageStyles = {
    infoDiv: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        marginTop: 15
    },
    headerStyle: {
        fontWeight: 900,
        fontSize: 18,
        color: 'text.secondary'
    },
    detailStyle: {
        fontSize: 16,
        color: 'text.primary'
    }
} as const;

const tabsStyle = {
    minWidth: 195,
    width: 195,
    border: 1,
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
} as const;

const tabStyle = {
    alignItems: 'start',
    fontSize: 14,
    color: 'primary.main',
    textTransform: 'none',
} as const;

export {pageStyles, tabsStyle, tabStyle};