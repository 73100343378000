import { TourStep } from '../../types/app-tour';

export const generateTourSteps = (): TourStep[] => [
    {
        target: '.tour__videosWatch',
        content: 'These videos feature real professionals sharing their insights and experiences. Click through the videos to discover which careers suit your interests.',
        title: 'Start Watching Career Videos',
        placement: 'right',
        disableBeacon: true,
        spotlightClicks: false,
        showSkipButton: true,
        hideCloseButton: true,
        data: {
            next: '/videos/watch',
        },
    },
    {
        target: '.tour__learnAboutEachCareer',
        content: 'These section of the panel offers additional information about the career from the video.',
        title: 'Learn About Each Career',
        placement: 'left',
        disableBeacon: true,
        spotlightClicks: false,
        showSkipButton: true,
        hideCloseButton: true,
    },
    {
        target: '.tour__shareYourFeedback',
        content: 'Providing us feedback about your interest in the career showcased will contribute to a more accurate career recommendation.',
        title: 'Share Your Feedback  On Each Video',
        placement: 'left',
        disableBeacon: true,
        spotlightClicks: false,
        showSkipButton: true,
        hideCloseButton: true
    },
    {
        target: '.tour__keepWatchingVideos',
        content: 'When you\'re done watching the video and providing feedback, feel free to proceed to the next video.',
        title: 'Keep Watching Videos',
        placement: 'left',
        disableBeacon: true,
        spotlightClicks: false,
        showSkipButton: true,
        hideCloseButton: true
    },
    {
        target: '.tour__seeYourResults',
        content: 'Once you\'ve watched 30 videos, you can view your career recommendations in the Results page.',
        title: 'See Your Results',
        placement: 'right',
        disableBeacon: true,
        spotlightClicks: false,
        showSkipButton: true,
        hideCloseButton: true,
        data: {
            next: '/home',
        },
    }
]; 