import React, {useContext, useState, FormEvent} from 'react';
import {useNavigate} from 'react-router-dom';
import { titleCase } from 'title-case';

import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Copyright from '../Copyright';
import {landingImage} from '../../assets';
import RootContext from '../../services/context-states/root-context';
import AuthService from '../../services/auth';
import {audience, setResetApiAuth, userApi} from '../../api/openapi-axios-client';
import { LoginFormData, UserSession } from '../../types/auth';
import { BooleanResponse, Session } from '../../types';
import { callApi } from '../../api/helpers';
import { LoginSocialGoogle, IResolveParams } from 'reactjs-social-login';
import GoogleSvg from './social-third-party-login/google/GoogleSvg';
import MicrosoftSvg from './social-third-party-login/microsoft/MicrosoftSvg';
import { LoginMicrosoft } from './social-third-party-login/microsoft/LoginMicrosoft';
import ThirdPartyLoginButton from './social-third-party-login/ThirdPartyLoginButton';
import { HostedDivider } from '../../elements';

const Login: React.FC = () => {
  const {tokenContext, userContext} = useContext(RootContext);
  const {setToken} = tokenContext;
  const {setCurrentUser} = userContext;
  const [error, setError] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const redirectUri = `${window.location.origin}`;
    const redirectMicrosoftUri = `${window.location.origin}/auth-redirect`

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    setError('');
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const submissionsData: LoginFormData = {
      email: formData.get('email') as string,
      password: formData.get('password') as string,
    };

    await handleLogin('futuregen', submissionsData);
  };

  const handleThirdPartyLoginSuccess = async (data: IResolveParams) => {
    const { access_token, email } = data.data as {
      access_token?: string;
      email?: string;
  };

  if (!access_token || !email) {
      setError(`Error logging in with ${titleCase(data.provider)}`);
      return;
  }

  await handleLogin(data.provider, {
    email,
    password: access_token
  });
  }

  const handleThirdPartyLoginError = (error: any) => {
    const {type, message} = error as {type: string, message: string};

    if(type !== 'popup_closed') {
        return;
    }

    setError(message);
  }

  const handleLogin = async (loginProvider: string, loginData: LoginFormData) => {
    try {
      const data = await callApi<Session>(() => userApi.account.authorize(
        {
          email: loginData.email,
          password: loginData.password,
          audience,
          loginProvider
        }, 
        'user'
      ));

      if (data?.token) {
        const sessionData: UserSession = {
          ...data,
          expiry: new Date().getTime() + 5000
        };

        localStorage.setItem('user', JSON.stringify(sessionData));

        setToken(data.token);

        const decodedToken : UserSession | null = AuthService.decodeUserToken(data.token);

        if (decodedToken?.userData) {
            setCurrentUser(decodedToken.userData);
            setResetApiAuth();
            navigate('/home');
        }

      } else {
        setError('login unsuccessful, please try again');
      }
    } catch (err) {
      setError((err as Error).message || 'invalid username or password');
    }
  }

  return (
    <Grid container direction='row' justifyContent="space-evenly" spacing={5} sx={{mt: 10}}>
      <Grid size={6}>
        {error && <Alert severity='error' sx={{mb: 1}}>{error}</Alert>}

        <Typography component='h5' variant='h5' sx={{fontWeight: 900}}>
          Welcome Back!
        </Typography>
        <Typography component='p' variant='body1'>
          Log into your Future Gen account.
        </Typography>
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type={showPassword ? 'text' : 'password'}
            id='password'
            autoComplete='current-password'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            control={<Checkbox value='remember' color='primary' />}
            label='Remember me'
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{mt: 3, mb: 2}}>
            Sign In
          </Button>
        </Box>
        <Grid container sx={{justifyContent: 'space-evenly'}}>
          <Grid>
            <Link
              component='button'
              variant='body1'
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault();
                navigate('/reset-password');
              }}>
              Forgot password?
            </Link>
          </Grid>
          <Grid>
            <Link
              component='button'
              variant='body1'
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault();
                navigate('/register');
              }}
            >
              Sign Up
            </Link>
          </Grid>
        </Grid>

        <HostedDivider style={{marginTop: 20, marginBottom: 20}}>
          <Typography sx={{fontWeight: 900, color: 'text.secondary'}}>
              OR
          </Typography>
        </HostedDivider>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 20, marginTop: 2, marginBottom: 15}}>
            <LoginSocialGoogle
              client_id={process.env.GOOGLE_CLIENT_ID || ''}
              redirect_uri={redirectUri}
              scope='openid profile email'
              fetch_basic_profile={true}
              isOnlyGetToken={false}
              onResolve={async (data: IResolveParams) => await handleThirdPartyLoginSuccess(data)}
              onReject={error => handleThirdPartyLoginError(error)}>
              <ThirdPartyLoginButton mode={theme.palette.mode} type='login' logo={<GoogleSvg />} text='Login with Google' />
            </LoginSocialGoogle>
            <LoginMicrosoft
                        client_id={process.env.MICROSOFT_CLIENT_ID || ''}
                        redirect_uri={redirectMicrosoftUri}
                        onResolve={(data: IResolveParams) => handleThirdPartyLoginSuccess(data)}
                        onReject={error => handleThirdPartyLoginError(error)}>
              <ThirdPartyLoginButton mode={theme.palette.mode} type='login' logo={<MicrosoftSvg />} text='Login with Microsoft' />
            </LoginMicrosoft>
        </div>
      </Grid>

      <Grid size={6} display={{xs: 'none', md: 'block'}}>
        <Box component='img' src={landingImage} sx={{width: '100%'}} />
      </Grid>

      <Copyright />
    </Grid>
  );
};

export default Login; 