import checkLicense from './license';
import { BillingInformation, User } from '../types';

export function enableVideo(userData: User | null, billingInformation: BillingInformation | null): boolean {
    if(!userData) {
        return false;
    }

    if(!billingInformation) {
        return false;
    }

    if(!billingInformation.data) {
        return false;
    }

    if(Number(billingInformation.data.licenses) === 0 || billingInformation.data.usedLicenses.length === 0) {
        return false;
    }

    return checkLicense(userData.id, billingInformation.data.usedLicenses);
} 