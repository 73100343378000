import { createContext } from 'react';
import { RootContextType } from '../../types';

const RootContext = createContext<RootContextType>({
    accessFlagsContext: {
        accessFlags: {
            enableManageBilling: false,
            enableManageUsers: false,
            showIncompleteProfile: false,
            showMissingLicense: false,
            showUnusedLicenses: false
        },
        setAccessFlags: () => { },
    },
    appTourContext: {
        appTour: {
            tourActive: false,
            run: false,
            stepIndex: 0,
            steps: []
        },
        setTourState: () => { }
    },
    billingContext: {
        billingInformation: null,
        setBillingInformation: () => { },
    },
    messageContext: {
        error: '',
        setError: () => { },
        successMessage: '',
        setSuccessMessage: () => { },
    },
    tokenContext: {
        token: '',
        setToken: () => { },
    },
    userContext: {
        currentUser: null,
        setCurrentUser: () => { },
    }
});

export default RootContext; 

