import { jwtDecode } from 'jwt-decode';
import UserSession from '../types/user-session';
import {Session} from '../types';

class AuthService {
    public getCurrentUser(): UserSession | null {
        const userStr = localStorage.getItem('user');
        return userStr ? JSON.parse(userStr) : null;
    }

    public logout(): void {
        localStorage.removeItem('user');
    }

    public decodeUserToken(token: string | null): UserSession | null {
        if (!token) {
            return null;
        }
        return jwtDecode<UserSession>(token);
    }
}

export default new AuthService();