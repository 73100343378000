/* eslint-disable no-console */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@elements';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import {userApi} from '../../../api/openapi-axios-client';

import * as EmailValidator from 'email-validator';

export const GlobalUserInvite = (props) => {

    const {userInvites, setUserInvites} = props;

    const [inviteError, setInviteError] = useState('');

    // Roles Data
    const [roles, setRoles] = useState([]);

    const [inviteEmail, setInviteEmail] = useState('');
    const [inviteRole, setInviteRole] = useState('');

    useEffect(() => {
        const getData = async () => {

          // Get Roles
          const roles = await userApi.roles.getAllRoles(false);
          setRoles(roles);
        };
    
        getData().catch((error) => {
          console.log(error);
        });
    }, []);  

    const handleAddInvite = () => {
        setInviteError('');
        if(EmailValidator.validate(inviteEmail)) {
            // Check if email already exists
            if(userInvites.filter((i) => i.email === inviteEmail).length > 0) {
                setInviteError('Email already invited');
                return;
            }

            if(inviteRole === '') {
                setInviteError('Select Role');
                return;
            }

            setUserInvites([...userInvites, {email: inviteEmail, roleId: inviteRole}]);
            setInviteEmail('');
            setInviteRole('');
        } else {
            inviteError('Invalid Email');
        }
    }

    const handleRemoveInvite = (invite) => {
        setUserInvites(userInvites.filter((i) => i.email !== invite.email));
    }

    const inviteUser = invite => {
    return (
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}} key={invite.id}>
        <IconButton aria-label="delete-invite" onClick={() => handleRemoveInvite(invite)} sx={{marginRight:'4px', color: (theme) => theme.palette.grey[500]}}>
            <CloseIcon />
        </IconButton>
        <Typography variant='caption' style={{padding: 5, flexGrow:1, fontSize:18}}>{invite.email}</Typography>
        <Typography variant='caption' style={{padding: 5, fontSize:18}}>{roles[roles.map(role => role.id).indexOf(invite.roleId)].name}</Typography>
        </div>
    );
    }

    return (
        <div style={{display:'flex', flexDirection:'column', height:'100%'}}>
            {(inviteError.length > 0) && <Alert severity="error">{inviteError}</Alert>}
                <div style={{display:'flex', flexDirection:'row', gap:5}}>
                    <TextField id='txtInviteEmail' label='Email' sx={{flexGrow:1, display:'flex'}} value={inviteEmail} onChange={(e) => setInviteEmail(e.target.value)} />
                    <FormControl>   
                        <InputLabel id="select-label">Role</InputLabel>
                        <Select id='selInviteRole' labelId='select-label' label='Role' sx={{minWidth:200, maxWidth:200}} value={inviteRole} onChange={(e) => setInviteRole(e.target.value)}>
                            {roles.map((role) => <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Button format='primary' text='Add' onClick={() => handleAddInvite()} />
                </div>
                <Typography variant='caption' sx={{marginTop:4, fontSize:18}}>Invites</Typography>
                <Box padding={1} marginBottom={2} sx={{maxHeight: 260, flexGrow:1, flexShrink:1, borderRadius: 2, border: 1, borderColor: 'grey.500', overflow:'auto'}}>
                    {userInvites.map((invite) => inviteUser(invite))}
                </Box>
        </div>
    )
}

GlobalUserInvite.propTypes = {
    userInvites: PropTypes.array,
    setUserInvites: PropTypes.func
};