import React from 'react';
import { Controller, Control, FieldValues, RegisterOptions } from 'react-hook-form';
import { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import startCase from 'lodash/startCase';

/* Passthroughs should be left for the following:
- variant
- fullWidth
- size
*/

interface GlobalFormInputProps extends Omit<TextFieldProps, 'name'> {
  id: string;
  name: string;
  control: Control<any>;
  defaultValue?: string;
  label?: string;
  rules?: RegisterOptions;
}

export const GlobalFormInput: React.FC<GlobalFormInputProps> = (props) => {
  const { name, control, id, label, rules, ...passthroughProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <TextField
          id={id}
          label={label || startCase(id)}
          name={name}
          helperText={error ? error.message : null}
          error={!!error}
          onChange={onChange}
          value={value}
          {...passthroughProps}
        />
      )}
    />
  );
}; 