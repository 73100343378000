import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import {DocumentProps, PDFViewer} from '@react-pdf/renderer';
import InsightReport from './InsightReport';

import {assessmentApi} from '../../api/openapi-axios-client';
import { callApi } from '../../api/helpers';
import { UserShareReportData } from '../../types';

const ViewInsightReport: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [previewReport, setPreviewReport] = useState<React.ReactElement | null>(null);

    const [searchParams] = useSearchParams();

    const userId = searchParams.get('userId');
    const shareId = searchParams.get('shareId');

    useEffect(() => {
        const getData = async (): Promise<void> => {
            if(isLoading || isError) {
                return;
            }

            // Data was feed in from the state
            if(localStorage.getItem('reportData')) {
                const reportData = JSON.parse(localStorage.getItem('reportData') || '{}');
                localStorage.removeItem('reportData');
                setPreviewReport(<InsightReport reportData={reportData}/>);
                setIsLoading(false);
            }
            else {
                if(!userId || !shareId) {
                    return;
                }

                if(previewReport) {
                    return;
                }

                try {
                    setIsLoading(true);
                    const reportData = await callApi<UserShareReportData>(() => assessmentApi.resultSharing.getShareReportData(userId, shareId));

                    // Set Report
                    setIsLoading(false);
                    setPreviewReport(<InsightReport reportData={reportData}/>);
                }
                catch(e) {
                    setIsLoading(false);
                    setIsError(true);
                }
            }
        };

        getData();
    });

    const buildLoadingControl = (): React.ReactElement => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', height:'100%', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress sx={{width: 75, height: 75}}/>
                <Typography sx={{fontWeight: 900, fontSize:'24px', marginBottom: '20px', flexGrow:1}}>
                    Building Report ...
                </Typography>
            </div>
        );
    }

    const buildPreviewControl = (): React.ReactElement => {
        if(!previewReport) {
            return (
                <Typography sx={{fontWeight: 900, fontSize:'24px', marginBottom: '20px', flexGrow:1}}>
                    Error Loading Report
                </Typography>
            );
        }
        else {
            return (
                <PDFViewer width='100%' height='100%' style={{flexGrow: 1}}>
                    {previewReport as React.ReactElement<DocumentProps>}
                </PDFViewer>
            )
        }
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignContent: 'stretch', justifyContent: 'stretch', width: '100%', height: '90vh', marginTop:1}}>
            {isLoading ? buildLoadingControl() : buildPreviewControl()}
        </Box>
    );
}

export default ViewInsightReport; 