import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {assessmentApi} from '../../api/openapi-axios-client';
import {callApi} from '../../api/helpers';
import {CareerCluster} from '../../types';
import {getClusterImage} from '../../helpers/images';

const LibraryClustersPage: React.FC = () => {
  const [careerClusters, setCareerClusters] = useState<CareerCluster[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getClusters = async () => {
      const response = await callApi<CareerCluster[]>(() => assessmentApi.careerData.getONetCareerClusters());

      if(response && response.length) {
        setCareerClusters(response);
      }
    }
    
    if(!careerClusters.length) {
      getClusters();
    }
  }, [careerClusters.length]);

  return (
    <Box>
      <Typography sx={{fontWeight: 900, fontSize:'24px', marginBottom: '10px', color: 'text.primary'}}>
        Career Industries
      </Typography>
      <Typography sx={{fontSize:'18px', marginBottom: '20px', color: 'text.primary'}}>
        Explore over 50,000+ career profiles to find out what they pay, expected growth, recommended education, and more.
      </Typography>
      <Box sx={{
        display:'flex', 
        flexDirection:'row', 
        flexWrap:'wrap', 
        justifyContent: 'space-between', 
        gap:'10px', 
        minWidth:1140, 
        maxWidth:1140
      }}>
        {careerClusters.length > 0 && careerClusters
          .sort((a, b) => Number(a.code) - Number(b.code))
          .map(cluster => (
            <Box 
              key={cluster.id} 
              sx={{minWidth:270, maxWidth:270, cursor:'pointer'}} 
              onClick={() => navigate(`/library/cluster/${cluster.id}`)}
            >
              <img src={getClusterImage(cluster.code)} alt={cluster.title} style={{width: '100%', height: '175px'}}/>
              <Typography sx={{fontWeight: 'bold', fontSize:'14px', color: 'text.primary'}}>
                {cluster.title}
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default LibraryClustersPage; 