import { SalaryChartData, SalaryTableData } from '../../../types';

export const baseSalaryTableData: SalaryTableData = {
    title: 'Annual Salary',
    location: 'National',
    salaries: [
        {
            key: 'ninetiethPercentile',
            ordering: 0,
            title: 'Top Earners',
            hourly: 'N/A',
            annual: 'N/A'
        },
        {
            key: 'seventyFifthPercentile',
            ordering: 1,
            title: '75th Percentile',
            hourly: 'N/A',
            annual: 'N/A'
        },
        {
            key: 'average',
            ordering: 2,
            title: 'Average',
            hourly: 'N/A',
            annual: 'N/A'
        },
        {
            key: 'twentyFifthPercentile',
            ordering: 3,
            title: '25th Percentile',
            hourly: 'N/A',
            annual: 'N/A'
        },
        {
            key: 'tenthPercentile',
            ordering: 4,
            title: '10th Percentile',
            hourly: 'N/A',
            annual: 'N/A'
        }
    ]
};

export const baseSalaryChartData: SalaryChartData = {
    title: '',
    location: '',
    annual: {
        values: [10, 25, 50, 25, 10],
        labels: ['', ' ', 'Nation Average: $0', '  ', '    '],
        colors: ['#A8B2FF', '#A8B2FF', '#2B3EAA', '#A8B2FF', '#A8B2FF']
    },
    hourly: {
        values: [10, 25, 50, 25, 10],
        labels: ['', ' ', 'National Average: $0', '  ', '    '],
        colors: ['#A8B2FF', '#A8B2FF', '#2B3EAA', '#A8B2FF', '#A8B2FF']
    }
};