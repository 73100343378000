import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { ONetDetailReportEducationLevelRequiredCategoryInner, ONetScore } from '../../../types';

interface EducationItemProps {
    data: ONetDetailReportEducationLevelRequiredCategoryInner
}

const formateScore = (score: ONetScore) => {
    if(!score) {
        return (<span>&nbsp;&nbsp;0%</span>);
    }

    if(score.value.toString().length >= 2) {
        return `${score.value}%`;
    }

    return (<span>&nbsp;&nbsp;{score.value}%</span>);
}

const Education = ({ data }: EducationItemProps) => {
    
    if(data?.score) {
        return (
            <div style={{display:'flex', flexDirection:'row', gap: 10, alignItems: 'center'}}>
                <Typography sx={{fontSize: 14, color: 'primary.main', fontWeight: 900}}>
                    {formateScore(data?.score)}
                </Typography>
                <LinearProgress 
                    variant="determinate" 
                    value={data?.score?.value || 0} 
                    sx={{height: 10, width: 100, borderRadius: 2, color: 'primary.main'}} 
                />
                <Typography sx={{fontSize: 14, color: 'text.primary'}}>
                    {`${data.name} required`}
                </Typography>
            </div>
        );
    }
    else {
        return (
            <div style={{display:'flex', flexDirection:'row', gap: 10, alignItems: 'center'}}>
                <Typography sx={{fontSize: 16, color: 'text.primary'}}>
                    {`* ${data.name} required`}
                </Typography>
            </div>
        );
    }
};

export default Education;