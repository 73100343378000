import React, {useContext, useEffect, useState, useRef} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Avatar from '@mui/material/Avatar';
import Check from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Mode from '@mui/icons-material/Mode';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import RootContext from '../../services/context-states/root-context';
import {assessmentApi} from '../../api/openapi-axios-client';
import {callApi} from '../../api/helpers';
import {stringToColor} from '../../helpers/strings';
import { DialogType, NewShareUser, ShareUser, SharedUser, UsersForSharing, UserResultShare, UserShareReportData } from '../../types';

const ShareReportPage: React.FC = () => {
    const {messageContext, tokenContext} = useContext(RootContext);
    const {token} = tokenContext;    
    const {setError, setSuccessMessage} = messageContext;
    
    const [loaded, setLoaded] = useState<boolean>(false);
    const [dialogToOpen, setDialogToOpen] = useState<DialogType>('ADD');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const [shareData, setShareData] = useState<UsersForSharing[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<UsersForSharing[]>([]);
    const [usersForEdit, setUsersForEdit] = useState<any[]>([]);
    const [selectedRelationship, setSelectedRelationship] = useState<string>('');
    
    const ref = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const relationshipRef = useRef<HTMLInputElement>(null);
    const otherRef = useRef<HTMLInputElement>(null);
    const firstNameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const getData = async (): Promise<void> => {
            const shareDataResponse = await callApi<UsersForSharing[]>(() => assessmentApi.resultSharing.getUsersSharedWith());
            setShareData(shareDataResponse);
            setLoaded(true);
        };

        if(token && !loaded) {
            getData();
        }
    }, [token, loaded]);

    const handleSelectedUserChanged = (user: UsersForSharing, status: boolean): void => {
        if(status) {
            setSelectedUsers([...selectedUsers, user]);
        } 
        else {
            setSelectedUsers(selectedUsers.filter((selected) => selected.user.id !== user.user.id));
        }
    }

    const handleAddDialogOpen = (): void => {
        setSuccessMessage('');
        const sharedToEdit = selectedUsers.length === 1 ? selectedUsers[0].sharedWith?.map(shared => ({
            ...shared,
            editMode: false
        })) : [];

        setUsersForEdit(sharedToEdit);
        setDialogToOpen('ADD');
        setDialogOpen(true);
    }

    const handleViewPreviewDialogOpen = async (user: ShareUser): Promise<void> => {

        if(user.watchedCount && Number(user.watchedCount) === 0) {
            return;
        }

        setDialogToOpen('LOADING');
        setDialogOpen(true);

        try {
            const reportData = await callApi<UserShareReportData>(() => assessmentApi.resultSharing.getPreviewShareReportData(user.id));
            localStorage.setItem('reportData', JSON.stringify(reportData));
            window.open('/results/share', '_blank', 'noreferrer');
        }
        catch(error) {
            setError('Error loading Report');
        }

        setDialogOpen(false);
    }

    const handleDialogClose = (resultMessage: string | null): void => {
        if(dialogToOpen === 'ADD') {
            setLoaded(false);
            setUsersForEdit([]);
            setSelectedUsers([]);
            setShareData([]);

            if(resultMessage) {
                setSuccessMessage(resultMessage);
            }

            setDialogOpen(false);
        }
        else {
            setDialogOpen(false);
        }
    }

    const handleAdd = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        setError('');
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get('email') as string;
        const firstName = data.get('firstName') as string;
        const lastName = data.get('lastName') as string;
        const relationship = data.get('relationship') as string;
        const other = data.get('other') as string;
        
        if(!email || !firstName || !lastName || !relationship) {
            setError('All fields are required');
            return;
        }

        if(relationship === 'other' && !other) {
            setError('All fields are required');
            return;
        }

        const user: NewShareUser = {
            email,
            firstName,
            lastName,
            relationship: relationship === 'other' ? other : relationship
        }

        await handlePeopleWithAccessChanged(user, 'add');

        if(relationship === 'other' && otherRef.current) {
            otherRef.current.value = '';
        }

        if(emailRef.current) emailRef.current.value = '';
        if(firstNameRef.current) firstNameRef.current.value = '';
        if(lastNameRef.current) lastNameRef.current.value = '';
        if(relationshipRef.current) relationshipRef.current.value = '';
    };

    const handleEdit = (user: SharedUser, editMode: boolean): void => {
        setUsersForEdit(usersForEdit.map((selected) => {
            if(selected.id === user.id) {
                return {
                    ...selected,
                    editMode
                }
            }
            return selected;
        }));
    }

    const handlePeopleWithAccessChanged = async (user: SharedUser | NewShareUser, action: 'delete' | 'add' | 'update'): Promise<void> => {
        if(action === 'delete') {
            const convertedUser = user as SharedUser;
            await callApi<UserResultShare>(() => assessmentApi.resultSharing.deleteShare(convertedUser.id));
            setUsersForEdit(usersForEdit.filter((selected) => selected.id !== convertedUser.id));
        }

        if(action === 'add') {
            const addedUsers: SharedUser[] = [];

            for(const selected of selectedUsers) {
                const response = await callApi<UserResultShare>(() => assessmentApi.resultSharing.createShare({
                    ...user,
                    userId: selected.user.id
                }));

                addedUsers.push({...response, editMode: false});
            }

            if(selectedUsers.length > 1) {
                handleDialogClose('Results Shared');
            }
            else {
                setUsersForEdit([...usersForEdit, ...addedUsers]);
            }
        }

        if(action === 'update') {
            const convertedUser = user as SharedUser;
            await callApi<UserResultShare>(() => assessmentApi.resultSharing.updateShare(user as SharedUser));

            setUsersForEdit(usersForEdit.map((selected) => {
                if(selected.id === convertedUser.id) {
                    return user as SharedUser;
                }
                return selected;
            }));
        }
    }

    const buildPeopleWithAccess = (user: SharedUser | null): React.ReactElement | null => {
        if(!user) {
            return null;
        }

        const colorForBorder = user.views > 0 ? '#04854F' : '#E08600';
        const colorForBackground = user.views > 0 ? '#D6F7E9' : '#F7EAD6';

        return (
            user.editMode ? (
                <div style={{display: 'flex', flexDirection: 'row', gap: 5, width: '100%', height:60, alignItems: 'center', marginBottom:15}}>
                    <TextField autoComplete='email' inputRef={ref} name={`edit-email-${user.id}`} required id='email' label='E-mail' size='small' autoFocus sx={{flexFlow: 1, width: '100%'}} defaultValue={user.email} />
                    <IconButton aria-label='save' onClick={async () => {
                        if(ref.current) {
                            user.email = ref.current.value;
                            await handlePeopleWithAccessChanged(user, 'update');
                            handleEdit(user, false);
                        }
                    }}><Check style={{fontSize:18}}/></IconButton>
                    <IconButton aria-label='cancel' onClick={() => {handleEdit(user, false)}}><CloseIcon style={{fontSize:18}}/></IconButton>
                </div>
            ) : (
                <div key={user.id} style={{display: 'flex', flexDirection: 'row', gap: 5, width: '100%', height:60, alignItems: 'center', marginBottom:15}}>
                    <IconButton aria-label='delete' onClick={async () => await handlePeopleWithAccessChanged(user, 'delete')}><CloseIcon style={{fontSize:18}}/></IconButton>
                    <IconButton aria-label='edit' onClick={()=> handleEdit(user, true)}><Mode style={{fontSize:18}}/></IconButton>
                    <Avatar sx={{bgcolor: stringToColor(`${user.firstName} ${user.lastName}`), height: 40, width: 40}}>{user.firstName[0]}</Avatar>
                    <div style={{display: 'flex', flexDirection: 'column', gap:0, marginLeft:10, flexGrow:1}}>
                        <Typography sx={{fontWeight: 'bold', fontSize:16}}>{user.firstName} {user.lastName}</Typography>
                        <Typography sx={{color: 'grey.500', fontSize:14}}>{user.email}</Typography>
                        <Typography sx={{color: 'grey.500', fontSize:14, textTransform:'capitalize'}}>{user.relationship}</Typography>
                    </div>
                    <Box sx={{border: '1px', borderStyle:'solid', borderColor: colorForBorder, background: colorForBackground, borderRadius: 2, height:40, width:120, display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Typography sx={{color: colorForBorder, fontSize:14}}>{user.views > 0 ? 'Active' : 'Pending'}</Typography>
                    </Box>
                </div>
            )
        )
    }

    const buildLoadingModal = (): React.ReactElement => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', height:'100%', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress sx={{width: 75, height: 75}}/>
                <Typography sx={{fontWeight: 900, fontSize:'24px', marginBottom: '20px', flexGrow:1}}>
                    Building Report ...
                </Typography>
            </div>
        );
    }

    const buildInviteControl = (): React.ReactElement => {
        return (
            <Box component='form' onSubmit={handleAdd} style={{padding: 20, display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'stretch', height:'100%'}}>
                <div style={{display: 'flex', flexDirection: 'row', gap: 10, width: '100%'}}>
                    <TextField inputRef={emailRef} autoComplete='email' name='email' required id='email' label='E-mail' autoFocus sx={{flexFlow: 1, width: '100%'}} />
                    <FormControl id={'relationship-form'} sx={{minWidth: 200}}>
                        <InputLabel id='relationship-label'>Relationship</InputLabel>
                        <Select inputRef={relationshipRef} fullWidth label='Relationship' id='relationship' name='relationship' labelId='relationship-label' value={selectedRelationship} onChange={(e: SelectChangeEvent) => setSelectedRelationship(e.target.value)}>
                            <MenuItem value='parent'>Parent</MenuItem>
                            <MenuItem value='teacher'>Teacher</MenuItem>
                            <MenuItem value='mentor'>Mentor</MenuItem>
                            <MenuItem value='counselor'>Counselor</MenuItem>
                            <MenuItem value='other'>Other</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {selectedRelationship === 'other' && (
                <div style={{display: 'flex', flexDirection: 'row', gap: 10, width: '100%'}}>
                    <TextField inputRef={otherRef} autoComplete='other' name='other' required id='other' label='Other' autoFocus sx={{flexFlow: 1, width: '100%'}} />
                </div>
                )}
                <div style={{display: 'flex', flexDirection: 'row', gap: 10, width: '100%'}}>
                    <TextField inputRef={firstNameRef} autoComplete='given-name' name='firstName' required id='firstName' label='First Name' sx={{flexFlow: 1, width: '100%'}} />
                    <TextField inputRef={lastNameRef} autoComplete='family-name' name='lastName' required id='lastName' label='Last Name' sx={{flexFlow: 1, width: '100%'}} />
                </div>
                <div style={{display: 'flex', flexDirection: 'row', gap: 10, width: '100%', justifyContent: 'end'}}>
                    <Button variant='contained' color='primary' type='submit'>Add User</Button>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 10, width: '100%', height:'100%'}}>
                    <Typography sx={{fontWeight:'bold'}}>People with Access</Typography>
                    <Box sx={{maxHeight: 260, flexGrow:1, flexShrink:1, overflow:'auto'}}>
                        {usersForEdit.map((user) => buildPeopleWithAccess(user))}
                    </Box>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', gap: 10, width: '100%', alignSelf:'end', justifyContent: 'end'}}>
                    <Button variant='contained' color='primary' sx={{width:100}} onClick={() => handleDialogClose('Results Shared')}>Done</Button>
                </div>
            </Box>
        )
    }

    const getDialogToOpen = (): React.ReactElement => {
        switch(dialogToOpen) {
            case 'LOADING':
                return buildLoadingModal();
            case 'ADD':
                return buildInviteControl();
            default:
                return <></>;
        }
    }

    const buildSharedWithOrTooltip = (row: UsersForSharing): React.ReactElement => {

        if(row.user.watchedCount && row.user.watchedCount > 0) {
            return (<>{row.sharedWith.length} people</>);
        }
        else {        
            return (
                <div style={{display: 'flex', flexDirection: 'row', gap: 5}}>
                <>Not enough videos watched</>
                <Tooltip title={'This User\'s results will be available after the user has started watching videos'}>
                    <InfoOutlinedIcon sx={{color:'grey.600', width:20, height:20, cursor:'pointer'}}/>
                </Tooltip>
                </div>
            )   
        }
    }

    const getDialogTitle = (): string => {
        if(dialogToOpen === 'LOADING') {
            return 'Loading...';
        }
        else if(dialogToOpen === 'ADD') {
            if(selectedUsers.length > 0) {
                return selectedUsers.length > 1 
                    ? 'Multiple Users' 
                    : `Share ${selectedUsers[0].user.firstName} ${selectedUsers[0].user.lastName}'s Results`;
            }
            else {
                return 'Share Results';
            }
        }
        else {
            return 'View Insights Report';
        }
    }

    const getDialogWidth = (): string => {
        switch(dialogToOpen) {
            case 'LOADING':
                return '25%';
            case 'ADD':
                return '70%';
            default:
                return '50%';
        }
    }

    const getDialogHeight = (): string => {
        switch(dialogToOpen) {
            case 'LOADING':
                return '25%';
            case 'ADD':
                return '50%';
            default:
                return '50%';
        }
    }

    return (
        <>
        <Dialog open={dialogOpen} PaperProps={{sx: {width: getDialogWidth(), height: getDialogHeight()}}}>
          <DialogTitle>
            {getDialogTitle()}
            <IconButton aria-label="close" onClick={() => handleDialogClose(null)} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
            {getDialogToOpen()}
        </Dialog>    
            <Box>
                <Typography sx={{fontWeight: 900, fontSize:'24px', marginBottom: '20px', flexGrow:1}}>
                    Share Results
                </Typography>
                <Box sx={{
                    display:'flex', 
                    flexDirection:'row', 
                    flexWrap:'wrap', 
                    justifyContent: 'right', 
                    gap:'10px', 
                    minWidth:400, 
                    maxWidth:1140}}>
                        <Button variant='contained' color='primary' disabled={!(selectedUsers.length > 0)} sx={{height: 40, borderRadius: 2}} startIcon={<ShortcutOutlinedIcon/>} onClick={() => handleAddDialogOpen()}>
                            Share Results
                        </Button>
                        <TableContainer component={Paper} sx={{borderRadius:2}}>
                            <Table sx={{minWidth: 400}} size='small' aria-label='Users & Invites'>
                                <TableHead sx={{'& th': {
                                    color: 'grey.500'}}}>
                                    <TableRow>
                                        <TableCell sx={{width: 25}}></TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Shared to</TableCell>
                                        <TableCell align='center'>Preview</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {shareData?.map((row) => (
                                    <TableRow key={row.user.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell align='center' sx={{width:25}}><Checkbox disabled={row.user.watchedCount && row.user.watchedCount > 0 ? false : true} onChange={(event) => handleSelectedUserChanged(row, event.target.checked)}/></TableCell>
                                        <TableCell component='th' scope='row' sx={{color: row.user.watchedCount && row.user.watchedCount > 0 ? 'inherit' : 'grey.500'}}>{row.user.firstName} {row.user.lastName}</TableCell>
                                        <TableCell sx={{color: row.user.watchedCount && row.user.watchedCount > 0 ? 'inherit' : 'grey.500'}}>{row.user.email}</TableCell>
                                        <TableCell sx={{color: row.user.watchedCount && row.user.watchedCount > 0 ? 'inherit' : 'grey.500'}}>{buildSharedWithOrTooltip(row)}</TableCell>
                                        <TableCell align='center'><VisibilityOutlinedIcon sx={{color: row.user.watchedCount && row.user.watchedCount > 0 ? 'grey600' : 'grey.500', width:30, height:30, cursor: row.user.watchedCount && row.user.watchedCount > 0 ? 'pointer' : 'default'}} onClick={async () => await handleViewPreviewDialogOpen(row.user)}/></TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                </Box>
            </Box>
        </>
    );
}

export default ShareReportPage; 