/* global RUNNING_ENVIRONMENT */

import AuthService from '../services/auth';
import {Configuration, SessionAudience} from '@futuregenxyz/user-api-client';

const env = RUNNING_ENVIRONMENT || null;
const serviceUnavailableError = 'Service is unavailable.';

const getApiUrl = (name, port) => {
    switch (env) {
        case 'dev':
        case 'staging':
        case 'production':
            return `https://${name}-api.${env}.futuregenxyz.com`
        default:
            return `http://localhost:${port}`;
    }
}

const formatErrors = (error) => {
    // detailed error printouts for local development only when local storage key-value set.
    if (env === 'local' && localStorage.getItem('devLogging') === 'true') {
        console.log(JSON.stringify(error));
    }

    const {code, message, response} = error;

    if (typeof error === 'string' || error instanceof String) {
        return {message: error, status: undefined};
    }

    if (code === 'ERR_NETWORK' && !response?.status) {
        return {message: serviceUnavailableError, status: 502};
    }

    if (response?.data?.message) {
        if (response.data.message.includes('unsupported type undefined')) {
            return {message: response.data.message, status: error?.response?.status}
        }
    }

    if (response?.status && response?.data?.message) {
        return {message: response?.data?.message, status: response?.status};
    }

    if (message) {
        return {message, status: undefined};
    }

    return {message: 'Unknown Error', status: undefined};
}

export const getConfiguration = (name, port) => { 
    const configuration = new Configuration({
    basePath: getApiUrl(name, port),
    baseOptions: {
        headers: {
            'x-authorization-type': SessionAudience.UserWebApp
        }
    }});

    const authToken = AuthService.getCurrentUser()?.token;

    if (authToken) {
        configuration.apiKey = `Bearer ${authToken}`;
    }

    return configuration;
}

export const handleError = (response) => {
    const error = formatErrors(response);

    switch (error.status) {
        case 401:
        case 403:
            throw(error);
        case 422: {
            const message = error.message === serviceUnavailableError
                ? 'Some fields were not submitted properly.'
                : error.message;
                throw({...error, message});
        }
        default:
            throw(error);
    }
}

export const handleSuccess = (response) => {
    const {status, data} = response;

    switch (status) {
        case 200:
        case 201:
        case 204:
            return data;
        default:
            throw(data);
    }
}