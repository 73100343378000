import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import { fastGrowingCareer } from '../../helpers/strings';

interface FastGrowingCareerProps {
    sx?: SxProps<Theme>;
}

export const GlobalFastGrowingCareer: React.FC<FastGrowingCareerProps> = ({ sx }) => {
    return (
        <Box 
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                bgcolor: 'container.border',
                borderRadius: 2,
                padding: 1,
                width: 'fit-content',
                ...sx
            }}>
            <Typography sx={{ fontSize: '14px' }}>
                {fastGrowingCareer} Indicates Fast-Growing Careers
            </Typography>
        </Box>
    );
};
