import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { ONetElementDetail } from '../../../types';
interface WorkContextProps {
    item: ONetElementDetail & {
        response: {
            percentage: number, 
            name: string
        }[]
    }; 
    index: number;
}

const WorkContext = ({ item, index }: WorkContextProps) => {
    const percentage = item?.response?.length ? item.response[0]?.percentage : 0;
    const name = item?.response?.length ? item.response[0]?.name : ' No Response';

    return (
        <div style={{display:'flex', flexDirection:'row', gap: 10, alignItems: 'center'}}>
            <LinearProgress variant="determinate" value={percentage} sx={{height: 10, width: 80, borderRadius: 2, color: 'primary.main'}} />
            <Typography sx={{fontSize: 14, color: 'text.primary'}}>
                {`${index}. ${item.name} — `} <b style={{color: ''}}>{`${percentage}% responded "${name}"`}</b>
            </Typography>
        </div>
    );
};

export default WorkContext;