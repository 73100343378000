import React, {useContext, useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import Joyride, {EVENTS, CallBackProps} from 'react-joyride';

import AuthService from '../../services/auth';
import RootContext from '../../services/context-states/root-context';
import SideAppBar from './SideAppBar';
import TopAppBar from './TopAppBar';
import { ThemeToggle } from '../ThemeToggle';
import {userApi} from '../../api/openapi-axios-client';
import AppTourToolTip from '../app-tour/AppTourToolTip';
import { TourStep } from '../../types/app-tour';
import { NavigationBarProps } from 'types/component-props';
import { callApi } from '../../api/helpers';
import { BillingInformation } from 'types/api-client-types';

import { logAnalyticsEvent, setAnalyticsUser } from '../../helpers/analytics';
import { GoogleAnalyticsEvent } from '../../types/analytic-events';

const NavigationBar: React.FC<NavigationBarProps> = ({ignoreLogin = false}) => {
  const {appTourContext, billingContext, tokenContext} = useContext(RootContext);
  const {appTour, setTourState} = appTourContext;
  const {billingInformation, setBillingInformation} = billingContext;
  const {token, setToken} = tokenContext;
  const [isLoggedIn, toggleLoggedIn] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(ignoreLogin) {
      return;
    }

    const authToken = AuthService.getCurrentUser()?.token;
    if (!isLoggedIn && authToken) {
      // check if authToken is valid
      userApi.account.verify().then(() => {
        toggleLoggedIn(true);
        setAnalyticsUser(AuthService.getCurrentUser()?.userData?.id || '');

        if(!token || token.length === 0) {
          setToken(authToken);
        }
      }).catch(() => {
        toggleLoggedIn(false);
        AuthService.logout();
        setToken(null);
      });
    } else if (!authToken && isLoggedIn) {
      toggleLoggedIn(false);
    }
  });

  useEffect(() => {
    if(ignoreLogin) {
      return;
    }

    if(isLoggedIn && token && billingInformation === null) {
      callApi<BillingInformation>(() =>userApi.billing.checkBilling()).then((response) => {
        setBillingInformation(response);
      }).catch(() => {
        setBillingInformation(null);
      });
    }
  }, [isLoggedIn, billingInformation]);

  const handleTourCallback = (tourData: CallBackProps): void => {
    const {
      action,
      index,
      step,
      type,
    } = tourData;

    const tourStep = step as TourStep;
    const { next, previous } = tourStep?.data || {next: null, previous: null};
    const isPreviousAction = action === 'prev';

    if(action === 'skip') {

      logAnalyticsEvent({
        category: 'tour',
        action: GoogleAnalyticsEvent.UserSkipedTourAfterStarting,
        label: `User skipped tour after starting ${index}`,
      });

      setTourState(() => ({
        tourActive: false,
        run: false,
        stepIndex: 0,
        steps: appTour.steps
      }));

      if(location.pathname !== '/home') {
        navigate('/home');
      }
      return;
    }

    if (type === EVENTS.STEP_AFTER) {
      if (index < (appTour.steps.length - 1)) {
        if(isPreviousAction && previous) {
          setTourState(() => ({
            run: false,
            stepIndex: index - 1,
            steps: appTour.steps,
            tourActive: appTour.tourActive
          }));

          navigate(previous);
        }
        else if (next) {
          setTourState(() => ({
            run: false,
            stepIndex: index + 1,
            steps: appTour.steps,
            tourActive: appTour.tourActive
          }));

          navigate(next);
        }
        else {
          setTourState((prevState) => ({ 
            ...prevState, 
            stepIndex: index + 1 
            }));
        }
      }

      if (index === (appTour.steps.length - 1)) {
        if (isPreviousAction && previous) {
            setTourState((prevState) => ({
            ...prevState,
            run: false
            }));

          navigate(previous);
        } else if(next) {
          setTourState((prevState) => ({
            ...prevState,
            tourActive: false,
            stepIndex: 0,
            run: false
          }));

          navigate(next);

        } else {
          setTourState(() => ({
            tourActive: false,
            run: false,
            stepIndex: 0,
            steps: appTour.steps
          }));
        }
      }
    }
  };

  return (
    <>
      <Joyride 
        callback={handleTourCallback}
        tooltipComponent={AppTourToolTip}
        steps={appTour.steps}
        run={appTour.run}
        stepIndex={appTour.stepIndex}
        disableOverlayClose={true}
        styles={{
          options: {
            arrowColor: '#4C52D3',
            zIndex: 10000,
          },
        }}/>
      { !isLoggedIn || ignoreLogin ? (
        <TopAppBar />
      ) : (
        <SideAppBar />
      )}
      <div>
        {
          isLoggedIn && (
            <div style={{ 
              position: 'fixed', 
              top: '0.5rem', 
              right: '1.5rem', 
              zIndex: 1200 
            }}>
              <ThemeToggle />
            </div>
          )
        }
        <Outlet />
      </div>
    </>
  );
};

export default NavigationBar; 