import React, {useContext, useEffect, useState} from 'react';
import {Button, UserInvites} from '../../../elements';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import RootContext from '../../../services/context-states/root-context';
import {userApi} from '../../../api/openapi-axios-client';
import {truncateString} from '../../../helpers/strings';
import { BillingAvailableCheck, BillingInformation, ChildUser, ChildUserInvite, RootContextType, UserInvite } from '../../../types';
import { callApi } from '../../../api/helpers';

interface UserRow {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  licensed: boolean;
}

const UserManagement: React.FC = () => {
    const {billingContext, messageContext, userContext} = useContext<RootContextType>(RootContext);
    const {billingInformation, setBillingInformation} = billingContext;
    const {error, setError, setSuccessMessage} = messageContext;
    const {currentUser} = userContext;
  
    const [loadedUsers, setLoadedUsers] = useState<boolean>(false);
    const [currentUsers, setCurrentUsers] = useState<UserRow[] | null>(null);
    const [loadedLicenses, setLoadedLicenses] = useState<boolean>(false);
    const [licenseData, setLicenseData] = useState<BillingAvailableCheck | null>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [userInvites, setUserInvites] = useState<ChildUserInvite[]>([]);
  
    useEffect(() => {
      const getData = async () => {
        if(loadedUsers && currentUsers !== null) { 
          return;
        }
    
        const combinedUsers: UserRow[] = [];
    
        if(currentUser) {
          combinedUsers.push({
            id: currentUser.id,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            email: currentUser.email,
            status: currentUser.additionalData?.role?.name || 'Admin',
            licensed: isLicensed(currentUser.id)
          });
        }
    
        if(billingInformation && Object.keys(billingInformation).length > 0) {
          const activeUsers = await callApi<ChildUser[]>(() => userApi.user.childAccounts()).catch(() => []);
    
          activeUsers?.forEach((user) => {
            combinedUsers.push({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              status: user.additionalData?.role?.name || 'Active',
              licensed: isLicensed(user.id)
            });
          });
        }
  
        const invitedUsers = await callApi<UserInvite[]>(() => userApi.userInvites.list()).catch(() => []);
        
        invitedUsers?.forEach((invite) => {
          combinedUsers.push({
            id: invite.id || '',
            firstName: '',
            lastName: '',
            email: invite.email,
            status: 'Invited',
            licensed: false
          });
        });
  
        setCurrentUsers(combinedUsers);
        setLoadedUsers(true);
      };
  
      getData().catch(console.error);
  
      const licenseLoad = async () => {
        if(loadedLicenses || !Object.keys(currentUser || {}).length) {
          return;
        }
  
        if(!billingInformation) {
          setLicenseData({
            isActive: false,
            licenses: 0,
            usedLicenses: 0,
            remaining: 0,
            available: false,
          });
          return;
        }
  
        const licenseCheck = await callApi<BillingAvailableCheck>(() => 
          userApi.billing.checkAvailableLicenses(billingInformation.id)
        );
  
        if (licenseCheck) {
          setLicenseData(licenseCheck);
          setLoadedLicenses(true);
        }
      };
  
      licenseLoad().catch(console.error);
    }, [loadedUsers, loadedLicenses, billingInformation, currentUser]);  

    const isLicensed = (id: string): boolean => {
      if(!billingInformation?.data?.usedLicenses) {
        return false;
      }

      return billingInformation.data.usedLicenses.includes(id);
    }
  
    const deleteInvite = (id: string) => {
      setError('');
      callApi(() => userApi.userInvites.deleteInvite(id))
        .catch((error: Error) => {
          setError(error.message);
        });
  
      setLoadedUsers(false);
    }

    const refreshInvite = (id: string) => {
      setError('');
      callApi(() => userApi.userInvites.refresh(id))
        .then(() => {
          setSuccessMessage('Invite Refreshed');
        })
        .catch((error: Error) => {
          setError(error.message);
        });
  
      setLoadedUsers(false);
    }
  
    const addLicense = async (id: string) => {
      setError('');
      const response = await callApi(() => userApi.billing.assignLicense(id))
        .catch((error: Error) => {
          setError(error.message);
          return null;
        });
      
      if(response) {
        const updatedBilling = await callApi<BillingInformation>(() => userApi.billing.checkBilling());
  
        if(updatedBilling) {
          setBillingInformation(updatedBilling);
          setLoadedUsers(false);
          setLoadedLicenses(false);
        }
      }
    }
  
    const removeLicense = async (id: string) => {
      setError('');
      const response = await callApi(() => userApi.billing.unassignLicense(id));
      
      if(response) {
        const billingUpdate = await callApi(() => userApi.billing.checkBilling());
  
        if(billingUpdate) {
          setBillingInformation(billingUpdate);
          setLoadedUsers(false);
          setLoadedLicenses(false);
        }
      }
    }
  
    const getLicenseComponent = (row: UserRow) => {
      const notAvailable = (
        <Typography>
          None Available
        </Typography>
      );
  
      const addButton = (
        <Button format='primary' text='Assign' style={{width: 50, fontSize: 9}} onClick={() => addLicense(row.id)} />
      );
  
      const removeButton = (
        <Button format='primary' text='Unassign' style={{width: 50, fontSize: 9}} onClick={() => removeLicense(row.id)} />
      );
  
      if(!licenseData || !row) {
        return notAvailable;
      }
  
      if(row.status === 'Invited') {
        return (<></>);
      }

      if(licenseData?.isActive && licenseData?.available && !row.licensed) {
        return addButton;
      }
  
      if(row.licensed) {
        return removeButton;
      }
  
      return notAvailable;
    }

    const getRowButtons = (row: UserRow) => { 
      return (
        <Stack direction='row' spacing={0}>
          {getUserRefreshComponent(row)}
          {getUserDeleteComponent(row)}
        </Stack>
      )
    }
  
    const getUserDeleteComponent = (row: UserRow) => {
      return (
        <IconButton disabled={row.status !== 'Invited'} onClick={() => deleteInvite(row.id)}>
          <DeleteIcon color={row.status === 'Invited' ? 'primary' : undefined} />
        </IconButton>
      );
    }

    const getUserRefreshComponent = (row: UserRow) => {
      return (
        <IconButton disabled={row.status !== 'Invited'} onClick={() => refreshInvite(row.id)}>
          <AutorenewIcon color={row.status === 'Invited' ? 'primary' : undefined} />
        </IconButton>
      );
    }

    const handleInviteUsers = async () => {
      setError('');
      const errors: string[] = [];

      for(const invite of userInvites) {
        const {email, roleId} = invite;

        try {
          await callApi(() => userApi.userInvites.createInvite({email, roleId}));
        }
        catch(error) {
          if (error instanceof Error) {
            errors.push(error.message);
          }
        }
      }

      if(errors.length === 0) {
          setSuccessMessage('Invites Sent');
          setLoadedUsers(false);
          setDialogOpen(false);
          setUserInvites([]);
      }
      else {
        setError(errors.join(', '));
      }
    }
  
    return (
      <div>
        <Dialog open={dialogOpen} PaperProps={{sx: {width: '80%', height: '60%'}}}>
          <DialogTitle>
            Invite Users
            <IconButton 
              aria-label="close" 
              onClick={() => setDialogOpen(false)} 
              sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <div style={{padding: 15, display:'flex', flexDirection:'column', height:'100%'}}>
            <div style={{flexGrow:1, marginBottom:2}}>
              <UserInvites userInvites={userInvites} setUserInvites={setUserInvites}/>
            </div>
            <Button format='primary' text='Send Invite(s)' style={{width: 150}} onClick={handleInviteUsers} />
          </div>
        </Dialog>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'stretch', height:'100%', width:'100%'}}>
          <div style={{display:'flex', flexDirection:'row', flexGrow:1, justifyContent:'space-between', alignItems:'center', height:'100%', width:'100%'}}>
            <Button format='primary' text='+ Invite Users' style={{width: 150}} onClick={() => setDialogOpen(true)} />
            <Paper style={{alignSelf:'flex-end', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', verticalAlign: 'middle', padding: '5px', height:'40px'}}>
              <Stack direction='row' spacing={2}>
                <Typography variant='caption' fontWeight='bold'>
                  Licenses
                </Typography>
                <Typography variant='caption'>
                  Purchased: {licenseData?.licenses || '0'}
                </Typography>
                <Typography variant='caption'>
                  Assigned: {licenseData?.usedLicenses || '0'}
                </Typography>
                <Typography variant='caption'>
                  Available: {licenseData?.remaining || '0'}
                </Typography>
              </Stack>
            </Paper>
          </div>
          <div style={{maxHeight: 540, width:'100%', overflowY:'scroll', marginTop:20}}>
            <TableContainer component={Paper} style={{height: '50%'}}>
              <Table sx={{minWidth: 650}} size='small' aria-label='Users & Invites'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align='center'>Email</TableCell>
                    <TableCell align='center'>Role/Status</TableCell>
                    <TableCell align='center'>License</TableCell>
                    <TableCell align='center' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentUsers?.map((row) => (
                    <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      <TableCell component='th' scope='row' sx={{fontSize:12}}>{row.firstName} {row.lastName}</TableCell>
                      <TableCell align='center' sx={{fontSize:12}}>{truncateString(row.email, 35)}</TableCell>
                      <TableCell align='center' sx={{fontSize:12}}>{row.status}</TableCell>
                      <TableCell align='center' sx={{fontSize:12}}>{getLicenseComponent(row)}</TableCell>
                      <TableCell align='center' sx={{fontSize:12}}>{getRowButtons(row)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
};

export default UserManagement; 