import {jwtDecode} from 'jwt-decode';

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));
};

const logout = () => localStorage.removeItem('user');

const decodeUserToken = (token) => {
    if (!token)
    {
        return
    }
    return jwtDecode(token);

}
const AuthService = {
    getCurrentUser,
    decodeUserToken,
    logout,
};

export default AuthService;