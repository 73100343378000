import React from 'react'

import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const Policies = () => {
    return (
        <>
            <Typography variant='h5' fontWeight={ 900 } gutterBottom>
                Policies
            </Typography>
            <Typography variant='body1'>
                <Link to='https://www.futuregenxyz.com/terms-of-use'>Terms of Use</Link>
            </Typography>
            <Typography variant='body1'>
                <Link to='https://www.futuregenxyz.com/privacy-policy'>Privacy Policy</Link>
            </Typography>
            <Typography variant='body1'>
                <Link to='https://www.futuregenxyz.com/privacy-policy-for-california-residents'>California Residents Privacy Policy</Link>
            </Typography>
        </>
    )
}

export default Policies