import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { RootContextType, ProfilePageProps, TabItem, TabsObject } from '../../types';
import RootContext from '../../services/context-states/root-context';
import { Tabs } from '../../elements';
import { AccountInfo, Payments, Policies, UserManagement } from './views';
import { useTheme } from '@mui/material/styles';
const ProfilePage: React.FC<ProfilePageProps> = () => {
    const { messageContext, accessFlagsContext, userContext } = useContext<RootContextType>(RootContext);
    const { setError } = messageContext  ;
    const { accessFlags } = accessFlagsContext;
    const { currentUser } = userContext;
    const [searchParams] = useSearchParams();

    const tabToShow = searchParams.get('show');

    const getTabsToLoad = (): TabsObject => {
        const tabsToEvaluate: TabItem[] = [
            { key: 0, name: 'Account Info', view: <AccountInfo profile={currentUser!} />, display: true, showBadge: false, badgeValue: -1 },
            { key: 1, name: 'Billing', view: <Payments />, display: accessFlags.enableManageBilling, showBadge: accessFlags.showIncompleteProfile, badgeValue: -1 },
            { key: 2, name: 'User Management', view: <UserManagement />, display: accessFlags.enableManageUsers, showBadge: accessFlags.showUnusedLicenses, badgeValue: -1 },
            { key: 3, name: 'Policies', view: <Policies />, display: true, showBadge: false, badgeValue: -1 }
        ];

        const tabsToReturn: TabsObject = {};
        let tabIndex = 0;

        tabsToEvaluate.forEach((tab) => {
            if (tab.display) {
                tabsToReturn[tabIndex] = tab;
                tabIndex++;
            }
        });

        return tabsToReturn;
    };

    const getInitialTab = (tabs: TabsObject): number => {
        if (tabToShow) {
            const displayIndex = Object.keys(tabs).find(key => tabs[Number(key)].name === tabToShow);
            if (displayIndex) {
                return parseInt(displayIndex);
            }
        }
        return 0;
    };

    const tabsToLoad = getTabsToLoad();
    const initialTabKey = getInitialTab(tabsToLoad);

    const theme = useTheme();

    // Reset the URL since initalTabKey is set
    window.history.replaceState({}, document.title, '/settings');

    setError(!currentUser ? 'We are unable to locate your information at this time' : '');

    return (
        <Paper elevation={3} sx={{ background: theme.palette.container.background, minWidth: '800px', maxWidth: '50%', maxHeight: '700px', minHeight: '700px' }}>
            <Tabs styles={{ height: '100%' }} tabsObj={tabsToLoad} initialSelectedTab={initialTabKey} />
        </Paper>
    );
};

export default ProfilePage; 