import React from 'react'

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import {Image} from 'mui-image'

import {videosPlaceholder} from '../../assets'

const LikedVideosPage = () => {
    return (
        <Grid container spacing={ 4 }>
            <Grid size={12} />

            <Grid size={ 1 } />
            <Grid align='center' size={ 10 } >
                <Typography component='h3' variant='h3' sx={ {fontWeight: 900} }>
                    These Are Your Liked Videos
                </Typography>
            </Grid>
            <Grid size={ 1 }/>

            <Grid align='center' size={ 12 }>
                <Typography component='p' variant='body1'>
                    {"This page is still under construction. But here's a preview of what liked videos will look like"}
                </Typography>
            </Grid>

            <Grid size={ 12 }>
                <Image src={ videosPlaceholder } />
            </Grid>
        </Grid>
    )
}

export default LikedVideosPage