import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';

import { Button } from '../elements';
import { landingImage } from '../assets';

// Text
const TITLE = 'Find a Career You Love';
const ABOUT = 'Future Gen is a career discovery platform aimed at young adults to help them build the confidence they need to pursue careers that suit them.';

const Landing: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-evenly" sx={{ marginTop: '55px' }}>
      <Grid container spacing={4}>
        <Grid size={6}>
          <Box component="img" src={landingImage} sx={{ width: '100%', height: 'auto' }} />
        </Grid>
        <Grid size={6}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography gutterBottom component="h3" variant="h3" sx={{ fontWeight: 900 }}>
              {TITLE}
            </Typography>
            <Typography gutterBottom component="h6" variant="h6" sx={{ marginTop: 5, marginBottom: 5 }}>
              {ABOUT}
            </Typography>

            <Box display="flex" flexDirection="row" justifyContent="space-evenly">
              <Button format="secondary" text="Login" onClick={() => navigate('/login')} style={{ width: 200, margin: 2 }} />
              <Button format="primary" text="Start Here" onClick={() => navigate('/register')} style={{ width: 200, margin: 2 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Landing; 