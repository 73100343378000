
export const permissions = {
    ManageBilling: 'manage-billing',
    ManageUsers: 'manage-users',
    ViewAssesmentResults: 'view-assessments-results'
}

export function checkPermission(userPermissions: string[], requestPermissions: string[] | string) : boolean {
    // If either are null or no length then cant determine access
    if(!userPermissions || !userPermissions.length 
        || !requestPermissions) {
        return false;
    }

    const finalPermissionsToCheck = Array.isArray(requestPermissions) 
        ? requestPermissions 
        : [requestPermissions];

    return finalPermissionsToCheck.filter(permission => userPermissions.some(up => up === permission)).length > 0;
}