import React, {useContext, useEffect, useState} from 'react';
import { useLocation, useNavigate} from 'react-router-dom';

import AuthService from '../../../services/auth';
import RootContext from '../../../services/context-states/root-context';
import {logoCircle} from '../../../assets';
import DrawerItem from '../../../types/drawer-item';
import { ThemeToggle } from '../../ThemeToggle';

// Drawer Contents
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';

import { useTheme } from '@mui/material/styles';

// Icons
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import {mainDrawer} from './DrawerContentsObject';

import {setResetApiAuth, userApi} from '../../../api/openapi-axios-client';
import { DrawerContentsProps } from 'types/component-props';
import AppTour from 'types/app-tour';
import Typography from '@mui/material/Typography';
import { registeredTrademark } from '../../../helpers/strings';
import { logAnalyticsEvent } from '../../../helpers/analytics';
import { GoogleAnalyticsEvent } from '../../../types/analytic-events';

interface OpenState {
    [key: string]: boolean;
}

const DrawerContents: React.FC<DrawerContentsProps> = ({toggleDrawer}) => {
    const {appTourContext, billingContext, accessFlagsContext, tokenContext, userContext} = useContext(RootContext);
    const {setTourState} = appTourContext;
    const {billingInformation, setBillingInformation} = billingContext;
    const {accessFlags} = accessFlagsContext;
    const {setCurrentUser, currentUser} = userContext;
    const {setToken} = tokenContext;
    const [buttonsToRender, setButtonsToRender] = useState<DrawerItem[]>([]);
    const [open, setOpen] = useState<OpenState>({});
    const [selectedItem, setSelectedItem] = useState<string>('home');
    
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        if(!currentUser || !accessFlags) {
            return;
        }

        const tempButtonsToRender = mainDrawer(currentUser, billingInformation, accessFlags);
        setButtonsToRender(tempButtonsToRender);
    }, [currentUser, billingInformation, accessFlags]);

    useEffect(() => {

        if(!buttonsToRender.length) {
            return;
        }

        const url = location.pathname.split('/').pop();

        if(url && url !== selectedItem) {
            setSelectedItem(url);
        }

    }, [selectedItem, buttonsToRender, location.pathname]);

    const handleClick = (parentCat: string): void => {
        setOpen((prevState: OpenState) => ({
            ...prevState,
            [parentCat]: !prevState[parentCat]
        }));
    };

    const handleTour = (): void => {

        logAnalyticsEvent({
            category: 'tour',
            action: GoogleAnalyticsEvent.UserManuallyStartedTour,
            label: 'User manually started tour',
        });

        navigate('/home');
        setTourState((prevState: AppTour) => ({
            ...prevState,
            tourActive: true,
            stepIndex: 0
        }));
    };

    const logOut = async (): Promise<void> => {
        await userApi.account.logout();

        AuthService.logout();
        setToken(null);
        setCurrentUser(null);
        setBillingInformation(null);
        toggleDrawer(false);
        setResetApiAuth();
        navigate('/');
    };

    const BadgeComponent = (button: DrawerItem) => (
        <Badge badgeContent={button.badgeValue}  
                invisible={!button.showBadge} 
                variant={button.badgeValue === -1 ? 'dot' : 'standard'}
                color="error">
            {button.icon}
        </Badge>
    );

    return (
        <Box
            sx={{width: 250, backgroundColor: 'background.navigation'}}
            role="presentation">
            <nav aria-label="main navigation options">
                <List>
                    <ListSubheader onClick={() => navigate('/home')}
                        sx={{
                            cursor: 'pointer',
                            fontWeight: 900,
                            fontSize: 25,
                            color: 'text.primary',
                            backgroundColor: 'background.navigation'
                        }}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Box
                                component="img"
                                sx={{
                                    maxWidth: 45,
                                    mr: 1
                                }}
                                alt="Future Gen logo"
                                src={logoCircle}
                            />
                            <div style={{display: 'flex', flexDirection: 'column', gap: 0}}>    
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2}}>
                                    <Typography sx={{fontSize: 24, fontWeight: 900, color: 'text.primary'}}>CareerAtlas</Typography>
                                    <Typography sx={{fontSize: 16, fontWeight: 500, color: 'text.primary'}}>{registeredTrademark}</Typography>
                                </div>
                                <Typography sx={{fontSize: 12, fontWeight: 900}}>By Future Gen</Typography>
                            </div>
                        </div>
                    </ListSubheader>

                    {buttonsToRender.map(button => {
                        if (button.hidden) {
                            return null;
                        } else if (button.subroutes?.length > 0) {
                            return (
                                <React.Fragment key={button.key}>
                                    <ListItemButton className={button.className} disabled={button.disabled} sx={{background: selectedItem === button.key ? theme.palette.highlight.navigation : 'transparent'}} onClick={() => {
                                        if (button.route && !button.disabled) {
                                            setSelectedItem(button.key);
                                            navigate(button.route);
                                        }

                                        if (!button.disabled) {
                                            handleClick(button.key);
                                        }
                                    }}>
                                        <ListItemIcon>
                                            {BadgeComponent(button)}
                                        </ListItemIcon>
                                        <ListItemText primary={button.text} />
                                        {open[button.key] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={open[button.key]} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {button.subroutes.map(nestedButton => {
                                                if (!nestedButton.hidden) {
                                                    return (
                                                        <ListItemButton className={nestedButton.className} disabled={nestedButton.disabled} sx={{pl: 4, background: selectedItem === nestedButton.key ? theme.palette.highlight.navigation : 'transparent'}} key={nestedButton.key} onClick={() => {
                                                            if (nestedButton.route && !nestedButton.disabled) {
                                                                setSelectedItem(nestedButton.key);
                                                                navigate(nestedButton.route);
                                                            }
                                                        }}>
                                                            <ListItemText primary={nestedButton.text} />
                                                        </ListItemButton>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </List>
                                    </Collapse>
                                    {button.displayDivider && <Divider />}
                                </React.Fragment>
                            );
                        } else {
                            return (
                                <React.Fragment key={button.key}>
                                    <ListItem disablePadding className={button.className}>
                                        <ListItemButton disabled={button.disabled} sx={{background: selectedItem === button.key ? theme.palette.highlight.navigation : 'transparent'}} className={button.className} onClick={() => {
                                            if (button.route && !button.disabled) {
                                                setSelectedItem(button.key);
                                                navigate(button.route);
                                            }
                                        }}>
                                            <ListItemIcon>
                                                {BadgeComponent(button)}
                                            </ListItemIcon>
                                            <ListItemText primary={button.text} />
                                        </ListItemButton>
                                    </ListItem>
                                    {button.displayDivider && <Divider />}
                                </React.Fragment>
                            );
                        }
                    })}
                </List>

                <List sx={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%'
                }}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleTour}>
                            <ListItemIcon>
                                <HelpOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary='Watch Tour' />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton onClick={logOut}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary='Logout' />
                        </ListItemButton>
                    </ListItem>
                </List>
            </nav>
        </Box>
    );
};

export default DrawerContents; 