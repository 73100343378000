import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid2';
import { PrivateRouteProps } from '../types/route';
import GlobalAlerts from '../components/app-bar/GlobalAlerts';

const PrivateRoute: React.FC<PrivateRouteProps> = ({ authenticated, hideAlerts = false }) => {
    
    if (!authenticated) {
        return <Navigate to='/' replace />;
    }

    return (
        <Container id='main-private-container' component="main" maxWidth={false}>
            <CssBaseline />
            <div id='main-private-container-outlet'>
                <Grid container spacing={1}>
                    {!hideAlerts && (
                        <Grid size={12}>
                            <GlobalAlerts isLoggedIn={authenticated} />
                        </Grid>
                    )}
                    <Grid size={12}>
                        <div style={{ maxWidth: '75%' }}>
                            <Outlet />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

export default PrivateRoute; 