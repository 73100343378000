import React from 'react'
import PropTypes from 'prop-types'
import {Navigate, Outlet} from 'react-router-dom';

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid2';

import GlobalAlerts from '../components/app-bar/GlobalAlerts';

const PrivateRoute = ({authenticated}) => {
    if (!authenticated) {
        return <Navigate to='/' replace />;
    }

    return (
        <Container id='main-private-container' component="main" maxWidth>
            <CssBaseline />
                <div id='main-private-container-outlet'>
                    <Grid container spacing={1}>
                        <Grid size={12}>
                            <GlobalAlerts isLoggedIn={authenticated} />
                        </Grid>
                        <Grid size={12}>
                            <div style={{maxWidth: '75%'}}>
                                <Outlet />
                            </div>
                        </Grid>
                    </Grid>
                </div>
        </Container>
    )
}

PrivateRoute.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    children: PropTypes.node
}

export default PrivateRoute