import React, { ReactNode } from 'react';
import Modal, { ModalProps } from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

const DEFAULT_MODAL_BOX_STYLE: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
} as const;

interface GlobalModalProps extends Omit<ModalProps, 'children' | 'style'> {
  modalTitle: string;
  modalDescription: string;
  style?: SxProps<Theme>;
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
}

export const GlobalModal: React.FC<GlobalModalProps> = (props) => {
  const { modalTitle, modalDescription, style, children, ...rest } = props;
  const modalStyle = style || DEFAULT_MODAL_BOX_STYLE;

  return (
    <Modal
      aria-labelledby={modalTitle}
      aria-describedby={modalDescription}
      {...rest}
    >
      <Box sx={modalStyle}>
        {children}
      </Box>
    </Modal>
  );
}; 