import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackArrow from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterAlt from '@mui/icons-material/FilterAlt';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';

import {assessmentApi} from '../../api/openapi-axios-client';
import {FastGrowingCareer, Loading} from '../../elements';
import {titleCase} from 'title-case';
import {returnSalaryData} from '../../helpers/salary';
import RootContext from '../../services/context-states/root-context';
import { CareerCluster, ONetOccupationAlternateTitles, OccupationDetails, OccupationFilters, RootContextType } from '../../types';
import { callApi } from '../../api/helpers';
import { fastGrowingCareer } from '../../helpers/strings';
import { getClusterImage } from '../../helpers/images';

const LibraryClusterDetailsPage: React.FC = () => {
  const {messageContext, userContext} = useContext<RootContextType>(RootContext);
  const {setError} = messageContext;
  const {currentUser} = userContext;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayCluster, setDisplayCluster] = useState<CareerCluster | null>(null);
  const [clusterDetails, setClusterDetails] = useState<OccupationDetails[]>([]);
  const [filters, setFilters] = useState<OccupationFilters>({search: '', income: '', degree: '', fastGrowing: false});
  const [search, setSearch] = useState<string>('');
  const [ordering, setOrdering] = useState<'az' | 'za'>('az');
  const [page, setPage] = useState<number>(0); 
  const [recordsPerPage, setRecordsPerPage] = useState<number>(25); 

  const navigate = useNavigate();
  const {clusterId} = useParams();
  const [searchParams] = useSearchParams();
  const backLocation = searchParams.get('backLocation');

  useEffect(() => {
    const getDetails = async () => {
      try {
        const cluster = await callApi<CareerCluster>(() => assessmentApi.careerData.getCareerCluster(clusterId || ''));
        const response : ONetOccupationAlternateTitles[] = await callApi<any>(() => assessmentApi.careerData.getONetOccupationsDetailsForCluster(clusterId || '', 'all'));
  
        const detailsWithSalary : OccupationDetails[] = response.map((occupation : ONetOccupationAlternateTitles) => ({
          ...occupation,
          convertedSalary: returnSalaryData(occupation?.salary)
        })) ;
  
        const filteredResponse = detailsWithSalary.filter((occupation) => occupation.occupationAlternateTitle !== '');
  
        if (cluster) {
          setDisplayCluster(cluster);
          setClusterDetails(filteredResponse);
          setIsLoading(false);
        }
      }
      catch(error) {
        setError('Error retrieving career cluster details. Please try again later.');
      }
    }

    if(displayCluster && clusterDetails.length) {
      return;
    }
    
    getDetails();
  }, [clusterId, displayCluster, clusterDetails.length, setError]);

  const degressFilterOptions = [
    {title: 'no high school diploma/GED', filterValue: 'less than high school diploma'},
    {title: 'high school diploma/GED', filterValue: 'high school diploma or equivalent'},
    {title: 'certificate after high school', filterValue: 'post-secondary certificate'},
    {title: 'some college', filterValue: 'some college, no degree'},
    {title: 'associate\'s degree', filterValue: 'associate\'s degree'},
    {title: 'bachelor\'s degree', filterValue: 'bachelor\'s degree'},
    {title: 'certificate after college', filterValue: 'post-baccalaureate certificate'},
    {title: 'master\'s degree', filterValue: 'master\'s degree'},
    {title: 'certificate after master\'s', filterValue: 'Post-master\'s certificate'},
    {title: 'professional degree', filterValue: 'professional degree'},
    {title: 'doctoral degree', filterValue: 'doctoral degree'},
    {title: 'post-doctoral training', filterValue: 'post-doctoral training'}
  ];

  const getFilteredData = () => {
    if(!filters.search && !filters.income && !filters.degree && !filters.fastGrowing) {
      return handleOrdering(clusterDetails);
    }

    let filteredData = clusterDetails;

    if(filters.search) {
      filteredData = filteredData.filter(occupation => 
        occupation.occupationAlternateTitle.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    if(filters.income) {
      const [min, max] = filters.income.split(':').map(Number);
      filteredData = filteredData.filter(occupation => 
        occupation.convertedSalary?.[1]?.annual !== undefined && 
        Number(occupation.convertedSalary[1].annual) >= min && 
        Number(occupation.convertedSalary[1].annual) <= max
      );
    }

    if(filters.degree) {
      filteredData = filteredData.filter(occupation => 
        occupation?.education?.level_required?.category?.some(
          (category) => category.name.toLowerCase() === filters.degree.toLowerCase()
        )
      );
    }

    if(filters.fastGrowing) {
      filteredData = filteredData.filter(occupation => occupation.fastGrowing);
    }

    return handleOrdering(filteredData);
  }

  const handleSortChange = (event: SelectChangeEvent<'az' | 'za'>) => {
    setOrdering(event.target.value as 'az' | 'za');
    setPage(0);
  };

  const handleOrdering = (dataToOrder: OccupationDetails[]) => {
    return dataToOrder.sort((a, b) => 
      ordering === 'az' 
        ? a.occupationAlternateTitle.localeCompare(b.occupationAlternateTitle)
        : b.occupationAlternateTitle.localeCompare(a.occupationAlternateTitle)
    );
  }

  const handleFilterChange = (key: keyof OccupationFilters, value: string | boolean) => {
    setFilters(prev => ({...prev, [key]: value}));
    setPage(0);
  }

  const handleChangePage = (event: unknown, newPage: number) => { 
    setPage(newPage); 
  } 

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => { 
    setRecordsPerPage(parseInt(event.target.value, 10)); 
    setPage(0); 
  } 

  const buildOccupationItem = (occupation: OccupationDetails) => {
    return (
      <Box key={occupation.occupationCode} sx={{cursor:'pointer'}} onClick={() => navigate(`/careers/details/${currentUser?.id}/${clusterId}/career/${clusterId}-${occupation.occupationId}-${occupation.occupationAlternateTitleId}?source=library`)}>
        <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:1}}>
          <Box sx={{flex:1, display:'flex', flexDirection:'row', alignItems:'center'}}>
            <Typography sx={{flexGrow:1, color: 'text.primary'}}>
              {occupation.occupationAlternateTitle}
            </Typography>
            {occupation.fastGrowing && (
              <Typography sx={{fontSize: 14, color: 'text.fastCareer', marginRight: 4}}>
                  {fastGrowingCareer}
              </Typography>) }
          </Box>
          <KeyboardArrowRight sx={{color: 'text.secondary', fontSize: '2.0rem'}}/>
        </Box>
        <Divider sx={{marginTop:1, color: 'divider', bgcolor: 'divider'}}/>
      </Box>
    )
  }

  const getOccupationRows = () => {
    const filteredData = getFilteredData();
    
    if(page > Math.floor(filteredData.length / recordsPerPage)) {
      setPage(0);
    }

    const startIndex = page * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    const toDisplay = filteredData.slice(startIndex, endIndex);

    return (
      <div key={`occupation-rows-${page}-${recordsPerPage}-${filters.search}-${filters.income}-${filters.degree}-${filters.fastGrowing}-${ordering}`}>
        {toDisplay.map(buildOccupationItem)}
        <TablePagination 
          rowsPerPageOptions={[25, 50, 100]} 
          component='div' 
          count={filteredData.length} 
          rowsPerPage={recordsPerPage} 
          page={page} 
          onPageChange={handleChangePage} 
          onRowsPerPageChange={handleChangeRowsPerPage} 
        /> 
      </div>
    )
  }

  return (
    <Box>
      {(isLoading && <Loading />) ||
        <>
        <Stack direction='row' spacing={0} display={'flex'} alignItems={'center'} sx={{marginBottom:2}}>
          <IconButton onClick={() => navigate(backLocation ? -1 : '/library' as any)}>
              <BackArrow sx={{color: 'text.secondary', fontSize: '2.5rem'}}/>
          </IconButton>
          <Typography sx={{fontSize: 20, color: 'text.secondary', verticalAlign:'center', cursor:'pointer'}} onClick={() => navigate(backLocation ? -1 : '/library' as any)}>
              {backLocation ? `Return to ${backLocation}` : 'Return to Career Clusters'}
          </Typography>
        </Stack>
        <Box sx={{marginLeft:4, display:'flex', flexDirection:'column', gap:'10px'}}>
          <Box sx={{borderRadius: 2, border: 1, borderColor: 'container.border', bgcolor: 'container.background', display:'flex', flexDirection:'row'}}>
            <img src={getClusterImage(displayCluster?.code ?? '')} alt={displayCluster?.code ?? ''} style={{overflow: 'hidden', objectFit: 'cover', minWidth: 400}}/>
            <div style={{display: 'flex', flexDirection: 'column', padding: 20, rowGap: 20}}>
              <Typography sx={{fontSize: 22, fontWeight: 900, color: 'text.primary'}}>
                  {displayCluster?.title}
              </Typography>
              <Typography sx={{fontSize: 16, color: 'text.primary'}}>
                  {displayCluster?.description}
              </Typography>
            </div>
          </Box>
          <Divider sx={{marginTop:4, marginBottom:3, color: 'divider', bgcolor: 'divider'}}/>
          <Box>
            <Typography sx={{fontSize: 22, fontWeight: 900, color: 'text.primary'}}>
                Potential Career Paths
            </Typography>
            <Typography sx={{fontSize: 16, color: 'text.primary'}}>
                Career Paths that can be found in {displayCluster?.title}
            </Typography>
            <TextField variant='outlined' 
              placeholder={'Search (Press Enter to Search)'} 
              value={search} 
              sx={{fontSize: 14, marginTop:1, marginBottom:1, width:650, background:'container.background', color: 'text.secondary'}} 
              size={'small'} 
              onChange={(e) => setSearch(e.target.value)} 
              onKeyDown={(e) => {if (e.key === 'Enter') { handleFilterChange('search', (e.target as HTMLInputElement).value)}}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{color: 'text.secondary'}} />
                  </InputAdornment>
                ),
                endAdornment:(
                  <InputAdornment position="end">
                    {filters.search &&
                      <IconButton onClick={() => {setSearch(''); handleFilterChange('search', '')}}>
                        <CancelIcon sx={{color: 'text.secondary'}} />
                      </IconButton>
                    }
                  </InputAdornment>
                )}} />
            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
              <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', flexGrow:1}}>
                <FilterAlt sx={{color: 'text.primary', fontSize: '1.5rem'}}/>
                <Typography sx={{fontSize: 16, color: 'text.primary', fontWeight:900, marginLeft:2}}>
                  Filters:
                </Typography>
                <Select sx={{marginLeft:4, fontSize:14, width:250, fontWeight:900}} variant='standard' value={filters.income} displayEmpty onChange={(e) => handleFilterChange('income', e.target.value)} size={'small'}>
                  <MenuItem value={''}>{'Average Projected Income (All)'}</MenuItem>
                  <MenuItem value={'1:19999'}>{'<$20,000'}</MenuItem>
                  <MenuItem value={'20000:39999'}>{'$20,000-$39,999'}</MenuItem>
                  <MenuItem value={'40000:59999'}>{'$40,000-$59,999'}</MenuItem>
                  <MenuItem value={'60000:79999'}>{'$60,000-$79,999'}</MenuItem>
                  <MenuItem value={'80000:99999'}>{'$80,000-$99,999'}</MenuItem>
                  <MenuItem value={'100000:119999'}>{'$100,000-$119,999'}</MenuItem>
                  <MenuItem value={'120000:139999'}>{'$120,000-$139,999'}</MenuItem>
                  <MenuItem value={'140000:159999'}>{'$140,000-$159,999'}</MenuItem>
                  <MenuItem value={'160000:179999'}>{'$160,000-$179,999'}</MenuItem>
                  <MenuItem value={'180000:200000'}>{'$180,000-$200,000'}</MenuItem>
                  <MenuItem value={'99999999'}>{'>$200,000'}</MenuItem>
                </Select>
                <Select sx={{marginLeft:4, fontSize:14, width:250, fontWeight:900}} variant='standard' value={filters.degree} displayEmpty onChange={(e) => handleFilterChange('degree', e.target.value)} size={'small'}>
                  <MenuItem value={''}>{'Degree Required (All)'}</MenuItem>
                  {degressFilterOptions.map((degree, index) => (
                    <MenuItem key={`degree-filter-${index}`} value={degree.filterValue}>{titleCase(degree.title)}</MenuItem>
                  ))}
                </Select>
                <Select sx={{marginLeft:4, fontSize:14, width: 230, fontWeight:900}} variant='standard' value={filters.fastGrowing.toString()} displayEmpty onChange={(e) => handleFilterChange('fastGrowing', e.target.value === 'true')} size={'small'}>
                  <MenuItem value={'false'}>{'Occupation Outlook (All)'}</MenuItem>
                  <MenuItem value={'true'}>{'Fast-growing career'}</MenuItem>
                </Select>
              </Box>
              <Select value={ordering} sx={{fontSize:14, background:'container.background', color: 'text.primary'}} onChange={handleSortChange} size={'small'}>
                <MenuItem value={'az'}>{'Sort by: A -> Z'}</MenuItem>
                <MenuItem value={'za'}>{'Sort by: Z -> A'}</MenuItem>
              </Select>
            </Box>
            <FastGrowingCareer sx={{marginTop: 1}}/>
            <Box padding={2} sx={{marginTop:1, borderRadius: 2, border: 1, borderColor: 'container.border', bgcolor: 'container.background'}}>
              <Typography sx={{fontSize: 16, color: 'text.secondary'}}>
                Occupations
              </Typography>
              <Divider/>
              {clusterDetails.length > 0 && 
                getOccupationRows()
              }
            </Box>
          </Box>
        </Box>
        </>
      }
    </Box>
  );
}

export default LibraryClusterDetailsPage; 