import React, { useContext, useEffect, useState } from 'react';
import { useMount } from 'react-use';
import { useBlocker } from 'react-router-dom';

import Checkbox from '@mui/material/Checkbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useTheme } from '@mui/material/styles';

import { videoApi } from '../../api/openapi-axios-client';
import VideoPlayer from './VideoPlayer';
import { Button, TrustMeter } from '../../elements';
import { truncateString } from '../../helpers/strings';
import RootContext from '../../services/context-states/root-context';
import { Video, RatingOption } from '../../types/videos-page';
import { callApi } from '../../api/helpers';

const VideosPage: React.FC = () => {
    const { appTourContext, userContext } = useContext(RootContext);
    const { currentUser } = userContext;
    const { appTour, setTourState } = appTourContext;
    const [video, setVideo] = useState<Video>({} as Video);
    const [videoEndTimestamp, setVideoEndTimestamp] = useState<Date | null>(null);
    const [rating, setRating] = useState<number>(0);
    const [watchCount, setWatchCount] = useState<number>(-1);
    const [showMore, setShowMore] = useState<boolean>(false);

    const theme = useTheme();

    const ratingOptions: RatingOption[] = [
        { value: 1, label: 'Not Interesting' },
        { value: 2, label: 'Slighly Interesting' },
        { value: 3, label: 'Somewhat Interesting' },
        { value: 4, label: 'Quite Interesting' },
        { value: 5, label: 'Very Interesting' },
    ];

    const descriptionMaxLength = 50;

    useEffect(() => {
        if (watchCount === -1 && currentUser?.id) {
            callApi(() => videoApi.watchLog.getHistory('count', currentUser.id))
                .then(data => {
                    setWatchCount(Number(data));
                })
                .catch((error) => {
                    console.log('error', error);
                });

            fetchNextVideo();
        }
    }, [currentUser]);

    useMount(() => {
        if (appTour.tourActive) {
            setTimeout(() => {
                setTourState((prevState) => ({ 
                    ...prevState, 
                    run: true
                }));
            }, 500);
        }
    });

    const blocker = useBlocker(() => {
        callApi(() => videoApi.watchLog.updateVideoLog({
            rating: 0,
            ended: videoEndTimestamp?.toISOString() || new Date().toISOString(),
            watchId: video.watchId
        }));

        return false;
    });

    const fetchNextVideo = async (): Promise<void> => {
        try {
            const data = await callApi<Video>(() => videoApi.videos.getNextVideo());
            await setupViewingHistory(data);
            setVideo(data);
        } catch (error) {
            console.error('Error fetching next video:', error);
        }
    };

    const setupViewingHistory = async (data: Video): Promise<void> => {
        try {
            await callApi(() => videoApi.watchLog.addVideoLog({
                watchId: data.watchId,
                source: 'youtube',
                sourceId: data.id,
                url: data.url
            }));
            setRating(0);
        } catch (error) {
            console.error('Error setting up viewing history:', error);
        }
    };

    const submitVideoRating = async (): Promise<void> => {
        await callApi(() => videoApi.watchLog.updateVideoLog({
            rating,
            ended: videoEndTimestamp?.toISOString() || new Date().toISOString(),
            watchId: video.watchId
        }));

        setWatchCount(watchCount + 1);
        fetchNextVideo();
        setVideoEndTimestamp(null);
        setRating(0);
        setShowMore(false);
    };

    const endOfVideoEvent = (): void => {
        setVideoEndTimestamp(new Date());
    };

    const rateVideo = (newRating: number): void => setRating(newRating);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', justifyContent: 'flex-start', alignItems: 'stretch', height: 800, minHeight: 650, maxHeight: 800 }}>
            {/*VIDEO PLAYER*/}
            <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: 'text.primary' }}>
                    {truncateString(video.title, 100)}
                </Typography>
                <VideoPlayer 
                    video={video} 
                    autoplay={1} 
                    onEndFunc={endOfVideoEvent}
                    addtionalOptions={{}}
                    additionalVars={{}}
                    height={'100%'}
                    width={'100%'}
                />
            </div>

            {/*VIDEO DETAILS*/}
            <div style={{ display: 'flex', flexDirection: 'column', width: '30%', borderRadius: '10px', borderColor: theme.palette.container.border, backgroundColor: theme.palette.container.background, borderWidth: '1px', borderStyle: 'solid', minWidth: 300 }}>
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, padding: '15px' }}>           
                    <div className='tour__learnAboutEachCareer'>
                        <Typography sx={{ fontSize: 15, fontWeight: 'bold', color: 'text.primary' }}>
                            Careers shown in this video:
                        </Typography>
                        <Typography sx={{ fontSize: 16, color: 'text.primary', marginBottom: 2 }}>
                            {video.onetData?.title}
                        </Typography>
                        <Typography sx={{ fontSize: 15, fontWeight: 'bold', color: 'text.primary' }}>
                            Career type:
                        </Typography>
                        <Typography sx={{ fontSize: 16, color: 'text.primary', marginBottom: 2 }}>
                            {video.careerCluster?.title}
                        </Typography>
                        <Typography sx={{ fontSize: 15, fontWeight: 'bold', color: 'text.primary' }}>
                            Job Description:
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: 160, overflowY: showMore ? 'scroll' : 'auto' }}>
                            <Typography sx={{ fontSize: 16, color: 'text.primary' }}>
                                {showMore ? video.onetData?.description : truncateString(video.onetData?.description, descriptionMaxLength)}
                            </Typography>
                            {video.onetData?.description && video.onetData.description.length > descriptionMaxLength && (
                                <Button format='tertiary' style={{ color: 'primary' }} text={showMore ? 'Show Less' : 'Show More'} onClick={() => setShowMore(!showMore)} />
                            )}
                        </div>
                    </div>
                    <Divider style={{ marginTop: 20, marginBottom: 20, color: 'divider' }} />
                    <div className='tour__shareYourFeedback'>
                        <Typography sx={{ fontSize: 16, fontWeight: 900, color: 'text.primary' }}>
                            Did you find this career interesting?
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            {ratingOptions.map(option => (
                                <div key={option.value} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <Checkbox
                                        icon={<RadioButtonUncheckedIcon />}
                                        checkedIcon={<CheckCircleIcon />}
                                        checked={rating === option.value}
                                        onChange={() => { rating === option.value ? rateVideo(0) : rateVideo(option.value)}}
                                        sx={{ '&.Mui-checked': { color: 'primary' }}}/>
                                    <Typography sx={{ fontSize: 14, color: 'text.primary', cursor: 'pointer' }} onClick={() => { rating === option.value ? rateVideo(0) : rateVideo(option.value) }}>
                                        {option.value} - {option.label}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Divider style={{ marginTop: 20, marginBottom: 20, color: 'divider' }} />
                    <Button
                        className='tour__keepWatchingVideos'
                        disabled={rating === 0}
                        format='primary'
                        text='Next Video'
                        style={{ height: 50, borderRadius: 2 }}
                        sx={{ '&.Mui-disabled': { background: 'primary', color: '#FFFFFF' } }}
                        onClick={submitVideoRating}
                    />
                </div>
                <TrustMeter fullAmount={32} trustAmount={watchCount} style={{ height: 50, borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }} />
            </div>
        </div>
    );
};

export default VideosPage; 