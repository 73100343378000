/* eslint-disable no-console */

import OpenApiAxiosClient from 'axios';
import {CareerDataApi, RatingsApi, ResultSharingApi} from '@futuregenxyz/assessment-api-client';
import {AuthorizationType, AccountApi, BillingApi, PasswordApi, RolesApi, SessionAudience, SurveysApi, UserApi, UserType, UserInvitesApi} from '@futuregenxyz/user-api-client';
import {OrganizationApi} from '@futuregenxyz/organization-api-client';
import {VideosApi, WatchLogApi} from '@futuregenxyz/video-api-client';
import {getConfiguration, handleError, handleSuccess} from './helpers';
import { AssessmentApiType, OrganizationApiType, UserApiType, VideoApiType } from '../types/apis';

const audience = SessionAudience.UserWebApp;

OpenApiAxiosClient.interceptors.response.use(res => handleSuccess(res), rej => handleError(rej));

let assessmentApi: AssessmentApiType;
let organizationApi: OrganizationApiType;
let userApi: UserApiType;
let videoApi: VideoApiType;

const assessmentApis = (): AssessmentApiType => {
    const assessmentApiApiConfig = getConfiguration('assessment', 9003);

    return {
        careerData: new CareerDataApi(assessmentApiApiConfig, undefined, OpenApiAxiosClient),
        ratings: new RatingsApi(assessmentApiApiConfig, undefined, OpenApiAxiosClient),
        resultSharing: new ResultSharingApi(assessmentApiApiConfig, undefined, OpenApiAxiosClient)
    };
};

const organizationApis = (): OrganizationApiType => {
    const organizationApiConfig = getConfiguration('organization', 9002);

    return { 
        organization: new OrganizationApi(organizationApiConfig, undefined, OpenApiAxiosClient)
    };
};

const userApis = (): UserApiType => {
    const userApiConfig = getConfiguration('user', 9001);

    return {
        account: new AccountApi(userApiConfig, undefined, OpenApiAxiosClient),
        billing: new BillingApi(userApiConfig, undefined, OpenApiAxiosClient),
        password: new PasswordApi(userApiConfig, undefined, OpenApiAxiosClient),
        roles: new RolesApi(userApiConfig, undefined, OpenApiAxiosClient),
        user: new UserApi(userApiConfig, undefined, OpenApiAxiosClient),
        userInvites: new UserInvitesApi(userApiConfig, undefined, OpenApiAxiosClient),
        userSurveys: new SurveysApi(userApiConfig, undefined, OpenApiAxiosClient)
    };
};

const videoApis = (): VideoApiType => {
    const videoApiConfig = getConfiguration('video', 9004);

    return {
        videos: new VideosApi(videoApiConfig, undefined, OpenApiAxiosClient),
        watchLog: new WatchLogApi(videoApiConfig, undefined, OpenApiAxiosClient)
    };
};

const types = {
    AuthorizationType,
    UserType
} as const;

const setResetApiAuth = (): void => {
    assessmentApi = assessmentApis();
    organizationApi = organizationApis();
    userApi = userApis();
    videoApi = videoApis();
};

setResetApiAuth();

export {
    audience,
    types,
    assessmentApi,
    organizationApi,
    userApi,
    videoApi,
    setResetApiAuth
}; 