import React, {useContext, useEffect, useState} from 'react';
import {useSearchParams, useNavigate} from 'react-router-dom';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement> & {
                'pricing-table-id': string;
                'publishable-key': string;
                'client-reference-id'?: string;
            }, HTMLElement>;
        }
    }
}

import {Button, Modal} from '../../../elements';

import Box from '@mui/material/Box';
import Close from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import RootContext from '../../../services/context-states/root-context';
import {userApi} from '../../../api/openapi-axios-client';
import { callApi } from '../../../api/helpers';
import { BillingInformation, CheckoutSession, RootContextType } from '../../../types';

const Payments: React.FC = () => {
    const {billingContext, messageContext, tokenContext} = useContext<RootContextType>(RootContext);
    const {billingInformation, setBillingInformation} = billingContext;  
    const {setError} = messageContext;
    const {token} = tokenContext;    
    const [searchParams] = useSearchParams();
    const [checkoutSession, setCheckoutSession] = useState<string | null>(null);
    const [showCheckoutForm, setShowCheckoutForm] = useState<boolean>(false);
    const [showModal, toggleModal] = useState<boolean>(false);
    const [updatingBilling, setUpdatingBilling] = useState<boolean>(false);
    const reloadBilling = searchParams.get('reloadBilling');

    const navigate = useNavigate();

    useEffect(() => {
      const loadBilling = async () => {
        setError('');
        if(reloadBilling && token) {
          setUpdatingBilling(true);
          toggleModal(true);

          const intervalId = setInterval(async () => {
            const response = await callApi<BillingInformation>(() => userApi.billing.checkBilling()).catch(() => null);

            if(response && response.isActive) {
              setBillingInformation(response);
              setUpdatingBilling(false);
            }
            else {
              setError('Issue updating billing information, please wait a bit and attempt to reload');
              setUpdatingBilling(false);
              toggleModal(false);
            }
          }, 5000);
      
          return () => clearInterval(intervalId);
        }
      }

      loadBilling();
    }, [token, reloadBilling, setError, setBillingInformation]);
  
    const updateUserModal = () => {
      return (
        <Grid display='flex' container>
          <Grid size={12}>
            <Typography component='div' gutterBottom sx={{color: 'primary.black', fontWeight: 'bold', marginBottom: 2}} >
              You&apos;re one step closer to completing your profile.
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography component='div' gutterBottom sx={{color: 'primary.black', marginBottom: 2}} >
              Time to invite your users to Future Gen.
            </Typography>
          </Grid>
          <Grid size={12}>
           <Button format='primary' text='Go to User Managment' onClick={() => {
            navigate('/settings?show=User Management', {replace: true});
            navigate(0);
            }} />
          </Grid>
        </Grid>
      );
    }

    const updateBillingModal = () => {
      return (
        <Grid display='flex' container>
          <Grid size={12}>
            <Typography component='div' gutterBottom sx={{color: 'primary.black', fontWeight: 'bold', marginBottom: 2}} >
              Updating Billing Information, please wait
            </Typography>
          </Grid>
          <Grid size={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    }

    const handleUpdateClient = async () => {
        setError('');
        if(billingInformation && Object.keys(billingInformation).length > 0) {
          const portalUrl = await callApi<string>(() => userApi.billing.customerPortalUrl())
            .catch((error: Error) => {
              setError(error.message);
              return null;
            });
    
          if(portalUrl) {
            window.open(portalUrl, '_self');
            return;
          }

          setError('Failed to get portal url');
          return;
        }

        if(!checkoutSession) {
          const checkoutSession = await callApi<CheckoutSession>(() => userApi.billing.checkoutSession());
    
          if(checkoutSession) {
            setCheckoutSession(checkoutSession.id);
          }
          else {
            setError('Failed to get checkout session');
            return; 
          }
        }
    
        setShowCheckoutForm(true);
    }
    
    const informationComponent = () => {
      return (
        <>
        <Modal
          open={showModal}
          onClose={() => toggleModal(false)}
          modalTitle=''
          modalDescription=''
          disableEscapeKeyDown
          style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              minWidth: 500,
              Width: 500,
              maxWidth: 500,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: 5,
              p: 4,
          }}>
            <Box>
            <Grid display='flex' container>
              <Grid size={12} sx={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                  <Close style={{cursor: 'pointer'}} onClick={() => toggleModal(false)}/>
              </Grid>
              {updatingBilling ? updateBillingModal() : updateUserModal()}
            </Grid>
            </Box>
          </Modal>
        <Paper elevation={2} sx={{padding: '15px', width: '450px', marginLeft: 'auto', marginRight: 'auto'}}>
          <Grid container spacing={2}>
            <Grid size={6}>
              <Typography sx={{fontWeight: 450}}>
                <b>Status:</b> {billingInformation?.isActive ? 'Active' : 'Inactive'}
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography sx={{fontWeight: 450, textTransform: 'capitalize'}}>
                <b>Tier:</b> {billingInformation?.data?.tier || 'None'}
              </Typography>
            </Grid>
            {(billingInformation && Object.keys(billingInformation).length && billingInformation.data && billingInformation.data.type !== 'ach') &&
              <>
              <Grid size={6}>
                <Typography sx={{fontWeight: 450}}>
                  <b>Last 4:</b> {billingInformation?.data?.details?.lastFour || 'None'}
                </Typography>
              </Grid>
              <Grid size={6}>
                <Typography sx={{fontWeight: 450, textTransform: 'capitalize'}}>
                  <b>Expiration:</b> {billingInformation?.data?.details?.expiration || 'None'}
                </Typography>
              </Grid>
              </>
            }
            <Grid size={6}>
              <Typography sx={{fontWeight: 450}}>
                <b>Licenses:</b> {billingInformation?.data?.licenses || '0'}
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography sx={{fontWeight: 450, textTransform: 'capitalize'}}>
                <b>Used:</b> {billingInformation?.data?.usedLicenses?.length || 0}
              </Typography>
            </Grid>
            {(!billingInformation || (billingInformation && billingInformation.data && billingInformation.data.type !== 'ach')) &&
              <Grid container size={12}>
                <Grid size={12} sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                  <Button format='primary' text={billingInformation?.isActive ? 'Update' : 'Add'} style={{width: 150}} onClick={handleUpdateClient} />
                </Grid>
                <Grid size={12} sx={{paddingTop:'10px'}}>
                  <Typography sx={{fontWeight: 150, fontStyle: 'italic'}}>
                    Please click above to Add, Update billing information or purchased licenses or Cancel your subscription.
                  </Typography>
                </Grid>
              </Grid>
            }
          </Grid>
      </Paper>
      </>
      );
    }
  
    const checkoutForm = () => { 
      return (
        <Grid container spacing={2}>
          <Grid size={12}>
            <div dangerouslySetInnerHTML={{
              __html: `<stripe-pricing-table 
                pricing-table-id="${process.env.STRIPE_TABLE_ID}"
                publishable-key="${process.env.STIPE_KEY}"
                client-reference-id="${checkoutSession || ''}"
              ></stripe-pricing-table>`
            }} />
          </Grid>
          <Grid size={12} sx={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
            <Button format='primary' text='Cancel' style={{width: 150}} onClick={() => setShowCheckoutForm(false)} />
          </Grid>
        </Grid>
      );
    }
  
    return (
      <Grid container spacing={2}>
        <Grid size={12}>
          {!showCheckoutForm ? informationComponent() : checkoutForm()}
        </Grid>
      </Grid>
    );
};

export default Payments; 