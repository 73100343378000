import React, { useEffect, useState, ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { TabPanel } from './TabPanel';
import { TabData } from 'types/component-props';
import { GlobalTabsProps } from '../../../types';

const COMPONENT_DEFAULT_STYLE: SxProps<Theme> = {
  width: '100%',
  backgroundColor: 'container.background',
};

const TAB_DEFAULT_STYLE: SxProps<Theme> = {
  borderBottom: 1,
  borderColor: 'divider',
};

const SUBTAB_BOX_DEFAULT_STYLE: SxProps<Theme> = {
  borderBottom: 1,
  borderColor: 'divider',
  marginLeft: 2,
  marginRight: 2,
};

const SUBTAB_DEFAULT_STYLE: SxProps<Theme> = {
  marginLeft: 5,
  marginRight: 5,
  marginTop: -1,
};

const SUBTAB_DEFAULT_TAB_STYLE: SxProps<Theme> = {
  paddingBottom: 0,
};

const VIEW_DEFAULT_STYLE: SxProps<Theme> = {
  p: 5,
  minHeight: 200,
};

export function GlobalTabs({ tabsObj, styles, initialSelectedTab = 0 }: GlobalTabsProps) {
  const [value, setValue] = useState(0);
  const [subtabValue, setSubtabValue] = useState(0);
  const [tabsToRender, setTabsToRender] = useState<TabData[]>([]);
  const [subtabsToRender, setSubtabsToRender] = useState<TabData[]>([]);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    const allowedTabs: TabData[] = [];

    Object.keys(tabsObj).forEach((key) => {
      const tabToRender = tabsObj[Number(key)];

      if (
        tabToRender.showTab === undefined ||
        tabToRender.showTab === null ||
        tabToRender.showTab
      ) {
        allowedTabs.push(tabToRender);
      }
    });

    setTabsToRender(allowedTabs);
  }, [tabsObj]);

  useEffect(() => {
    const subtabsObj = tabsToRender[value]?.subtabs;
    const subTabs: TabData[] = [];

    // only need to reset to empty array if it needs to be and isn't one
    if (!subtabsObj) {
      if (subtabsToRender.length) {
        setSubtabsToRender([]);
      }
      return;
    }

    Object.keys(subtabsObj).forEach((key) => {
      const tabToRender = subtabsObj[key];

      if (
        tabToRender.showTab === undefined ||
        tabToRender.showTab === null ||
        tabToRender.showTab
      ) {
        subTabs.push(tabToRender);
      }
    });

    setSubtabsToRender(subTabs);
  }, [value, tabsToRender]);

  useEffect(() => {
    if (tabsToRender.length && initialSelectedTab !== 0 && !hasLoaded) {
      setValue(initialSelectedTab);
      setHasLoaded(true);
    }
  }, [tabsToRender, initialSelectedTab, hasLoaded]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => setValue(newValue);

  const handleSubtabChange = (_event: React.SyntheticEvent, newValue: number) => setSubtabValue(newValue);

  const BadgeComponent = (tab: TabData) => (
    <Badge
      badgeContent={tab.badgeValue}
      invisible={!tab.showBadge}
      variant={tab.badgeValue === -1 ? 'dot' : 'standard'}
      color="error">
      {tab.name}
    </Badge>
  );

  return (
    <Box sx={styles?.component || COMPONENT_DEFAULT_STYLE}>
      <Box sx={styles?.tab || TAB_DEFAULT_STYLE}>
        <Tabs value={value} onChange={handleChange} aria-label="profile tabs" allowScrollButtonsMobile>
          {tabsToRender.map((tab) => (
            <Tab
              key={tab.name}
              label={BadgeComponent(tab)}
              id={`${tab.name}-tab-${tab.key}`}
              aria-controls={`${tab.name}-tabpanel-${tab.key}`}
            />
          ))}
        </Tabs>
      </Box>

      {!!subtabsToRender.length && (
        <Box sx={styles?.tab || SUBTAB_BOX_DEFAULT_STYLE}>
          <Tabs
            value={subtabValue}
            onChange={handleSubtabChange}
            aria-label="profile tabs"
            sx={styles?.subtab || SUBTAB_DEFAULT_STYLE}
            allowScrollButtonsMobile
          >
            {subtabsToRender.map((tab) => (
              <Tab
                key={tab.name}
                label={BadgeComponent(tab)}
                id={`${tab.name}-tab-${tab.key}`}
                aria-controls={`${tab.name}-tabpanel-${tab.key}`}
                sx={SUBTAB_DEFAULT_TAB_STYLE}
              />
            ))}
          </Tabs>
        </Box>
      )}

      <TabPanel value={value} sx={styles?.view || VIEW_DEFAULT_STYLE}>
        {subtabsToRender.length
          ? subtabsToRender[subtabValue]?.view
          : tabsToRender.length
          ? tabsToRender[value]?.view
          : null}
      </TabPanel>
    </Box>
  );
} 