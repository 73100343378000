
import ReactGA from 'react-ga4';

import { AnalyticReport } from '../types/analytic-report';

const handleInitialized = () => {
    if(!ReactGA.isInitialized) {
        ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID || '');
    }
}

export const setAnalyticsUser = (userId: string) => {
    handleInitialized();
    ReactGA.set({ userId });
}

export const logAnalyticsPageView = (path: string) => {
    handleInitialized();
    ReactGA.send({ hitType: "pageview", page: path, environment: process.env.NODE_ENV });
}

export const logAnalyticsEvent = (event: AnalyticReport) => {
    handleInitialized();
    ReactGA.event(event);
}