/* global RUNNING_ENVIRONMENT */
import AuthService from '../services/auth'
import { Configuration, SessionAudience } from '@futuregenxyz/user-api-client';
import { AxiosError, AxiosResponse } from 'axios';

import ApiError from '../types/api-error';
import { Environment } from '../types/environment';

declare const RUNNING_ENVIRONMENT: string | null;

const env: Environment = (RUNNING_ENVIRONMENT as Environment) || null;
const serviceUnavailableError = 'Service is unavailable.';

const getApiUrl = (name: string, port: number): string => {
    switch (env) {
        case 'dev':
        case 'staging':
        case 'production':
            return `https://${name}-api.${env}.futuregenxyz.com`;
        default:
            return `http://localhost:${port}`;
    }
};

const formatErrors = (error: string | AxiosError | Error): ApiError => {
    // detailed error printouts for local development only when local storage key-value set.
    if (env === 'local' && localStorage.getItem('devLogging') === 'true') {
        console.log(JSON.stringify(error));
    }

    if (typeof error === 'string') {
        return { message: error, status: undefined };
    }

    if ('isAxiosError' in error) {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_NETWORK' && !axiosError.response?.status) {
            return { message: serviceUnavailableError, status: 502 };
        }

        const response = axiosError.response?.data as { message?: string };
        if (response?.message) {
            if (response.message.includes('unsupported type undefined')) {
                return { message: response.message, status: axiosError.response?.status };
            }
        }

        if (axiosError.response?.status && response?.message) {
            return { message: response.message, status: axiosError.response.status };
        }
    }

    if ('message' in error) {
        return { message: error.message, status: undefined };
    }

    return { message: 'Unknown Error', status: undefined };
};

export const getConfiguration = (name: string, port: number): Configuration => {
    const configuration = new Configuration({
        basePath: getApiUrl(name, port),
        baseOptions: {
            headers: {
                'x-authorization-type': SessionAudience.UserWebApp
            }
        }
    });

    const authToken = AuthService.getCurrentUser()?.token;

    if (authToken) {
        configuration.apiKey = `Bearer ${authToken}`;
    }

    return configuration;
};

export const handleError = (response: AxiosError | Error | string): never => {
    const error = formatErrors(response);

    switch (error.status) {
        case 401:
        case 403:
            throw error;
        case 422: {
            const message = error.message === serviceUnavailableError
                ? 'Some fields were not submitted properly.'
                : error.message;
            throw { ...error, message };
        }
        default:
            throw error;
    }
};

export const handleSuccess = <T>(response: AxiosResponse<T>): T => {
    const { status, data } = response;

    switch (status) {
        case 200:
        case 201:
        case 204:
            return data;
        default:
            throw data;
    }
}; 

export async function callApi<T>(func: () => Promise<AxiosResponse<T>>) : Promise<T> {
    return await func() as T;
}
