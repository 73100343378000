import React, {useState} from 'react';

import Drawer from '@mui/material/Drawer';
import DrawerContents from './SideAppBarContents/DrawerContents';

const SideAppBar: React.FC = () => {
    const [open, setOpen] = useState<boolean>(true);

    return (
        <div>
            <Drawer
                anchor="left"
                open={open}
                variant="persistent"
                PaperProps={{
                    sx: {
                        backgroundColor: 'background.navigation'
                    }
                }}>
                <DrawerContents toggleDrawer={setOpen} />
            </Drawer>
        </div>
    );
};

export default SideAppBar; 