import React, {useContext, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {titleCase} from 'title-case';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {useTheme} from '@mui/material/styles';

import {types, setResetApiAuth, userApi} from '../../api/openapi-axios-client';
import {callApi} from '../../api/helpers';
import {ChildUserInvite, RootContextType, Session} from '../../types';
import {Button, FormCheckbox, FormInput, HostedDivider, Loading, UserInvites} from '../../elements';
import RootContext from '../../services/context-states/root-context';
import AuthService from '../../services/auth';
import Copyright from '../Copyright';
import { UserSession } from 'types/auth';

import  {
    IResolveParams,
    LoginSocialGoogle,
} from 'reactjs-social-login'

import ThirdPartyLoginButton from './social-third-party-login/ThirdPartyLoginButton';
import { RegistrationFormData } from '../../types';
import GoogleSvg from './social-third-party-login/google/GoogleSvg';
import MicrosoftSvg from './social-third-party-login/microsoft/MicrosoftSvg';
import { LoginMicrosoft } from './social-third-party-login/microsoft/LoginMicrosoft';

const Register: React.FC = () => {
    const [searchParams] = useSearchParams();
    const {appTourContext, tokenContext, userContext} = useContext<RootContextType>(RootContext);
    const {setToken} = tokenContext;
    const {setCurrentUser} = userContext;
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [registrationType, setRegistrationType] = useState<string>('myself');
    const [agreementChecked, setAgreementChecked] = useState<boolean>(false);
    const [userInvites, setUserInvites] = useState<ChildUserInvite[]>([]);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    
    const {control, handleSubmit, register, watch} = useForm<RegistrationFormData>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
            termsAndPolicies: false,
            registrationOptions: {
                registerFor: '',
                registerOthers: false,
                othersToInvite: []
            }
        }
    });

    const navigate = useNavigate();
    const theme = useTheme();
    const isInvite = searchParams.get('invite');
    const password = watch('password');

    const redirectUri = `${window.location.origin}`;
    const redirectMicrosoftUri = `${window.location.origin}/auth-redirect`

    useEffect(() => {
        const validateInvite = async () => {
            const inviteId = searchParams.get('invite') || null;

            if(!inviteId) {
                navigate('/login');
                return;
            }

            try {
                const isValidInvite = await userApi.userInvites.validateInvite(inviteId);

                if(!isValidInvite) {
                    navigate('/login');
                }
            }
            catch(e: any) {
                console.log(e);
                if(e.message === 'Invite not Found') {
                    navigate('/login');
                }
            }
        }

        if (isInvite) {
            validateInvite();
            setRegistrationType('myself');
        }
    }, [isInvite, navigate, searchParams]);

    useEffect(() => {
        register('password');
    }, [register]);

    const toggleAgreement = (e: React.MouseEvent<HTMLLabelElement>) => setAgreementChecked(!agreementChecked);

    const buildLabel = (registrationType: string, baseLabel: string): string => 
        registrationType === 'others' ? `Your ${baseLabel}` : baseLabel;

    const handleFormSubmit = (data: RegistrationFormData) => {
        setError('');
    

        data.loginProvider = 'futuregen';
        handleRegistration(data);
    };

    {/* 
        const handleRegistrationForRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setRegistrationType(event.target.value);
            setRegistrationProvider('futuregen');
        };

        const handleRegistrationProviderRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setRegistrationProvider(event.target.value);
        };
    */}

    const handleRegistration = async (registrationData: RegistrationFormData) => {
        setError('');
        setLoading(true);

        let submissionData: any = registrationData;

        if (isInvite) {
            submissionData = {
                ...submissionData,
                isInvite: true,
                parentId: searchParams.get('parentId') || null,
                inviteId: searchParams.get('invite') || null,
            }
        }

        if(registrationType !== 'myself') {
            submissionData.registrationOptions = {
                registerOthers: true,
                othersToInvite: userInvites
            }
        }

        callApi<Session>(() => userApi.account.register({
            audience: 'user-web-app',
            ...submissionData
        }, types.AuthorizationType.User))
            .then((data: Session) => {
                if (data?.token) {
                    localStorage.setItem('user', JSON.stringify({
                        ...data,
                        expiry: new Date().getTime() + 5000
                    }))
                    setToken(data.token)

                    const decodedUser : UserSession | null = AuthService.decodeUserToken(data.token);

                    if(!decodedUser?.userData) {
                        throw new Error('User data not found');
                    }

                    setCurrentUser(decodedUser.userData);
                    setResetApiAuth();
                    navigate(`/registration-survey`);
                    setLoading(false);
                } else {
                    setError('Registration unsuccessful, please try again later')
                    setLoading(false);
                }
            })
            .catch((err: Error) => {
                setError(err?.message || 'Invalid. This information may already exist')
                setLoading(false);
            });
    }

    const futureGenRegistration = (): React.ReactElement => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', gap: 0}}>
                <Box component='form' onSubmit={handleSubmit(handleFormSubmit)} sx={{mt: 3}}>
                    {!!registrationType &&
                        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>

                            {registrationType === 'others' &&
                                <FormInput
                                    autoFocus
                                    control={control}
                                    fullWidth
                                    id='registration-option'
                                    label='Who are you registering for?'
                                    name='registrationOptions.registerFor'
                                    required={true}/>
                            }

                            <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
                                <FormInput
                                    autoComplete='given-name'
                                    autoFocus
                                    control={control}
                                    fullWidth
                                    id='firstName'
                                    label={buildLabel(registrationType, 'First Name')}
                                    name='firstName'
                                    required={true}/>
                                <FormInput
                                    autoComplete='family-name'
                                    control={control}
                                    fullWidth
                                    id='lastName'
                                    label={buildLabel(registrationType, 'Last Name')}
                                    name='lastName'
                                    required/>
                            </div>

                            <FormInput
                                autoComplete='email'
                                control={control}
                                fullWidth
                                id='email'
                                label={buildLabel(registrationType, 'Email')}
                                name='email'
                                required
                                rules={{
                                    required: 'A valid email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Invalid email address',
                                    },
                            }}/>
                                
                            <FormInput
                                autoComplete='new-password'
                                control={control}
                                fullWidth
                                required
                                name='password'
                                label='Password'
                                type={showPassword ? 'text' : 'password'}
                                id='password'
                                slotProps={{
                                    input: {
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge='end'>
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                }}
                                rules={{
                                    required: 'Password is required',
                                    minLength: {
                                        value: 8,
                                        message: 'Password must be at least 8 characters',
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: 'Password can not exceed 100 characters',
                                    },
                                    validate: {
                                        uppercase: (value) =>
                                            /[A-Z]/.test(value) || 'Password must contain at least one uppercase letter',
                                        lowercase: (value) =>
                                            /[a-z]/.test(value) || 'Password must contain at least one lowercase letter',
                                        hasTwoDigits: (value) =>
                                            (value.match(/\d/g) || []).length >= 2 ||
                                            'Password must contain at least two digits',
                                        hasTwoSpecialCharacters: (value) =>
                                            (value.match(/[@$!%*?&]/g) || []).length >= 1 ||
                                            'Password must contain at least two special characters (@ $ ! % * ? &)',
                                        noSpaces: (value) =>
                                            !/\s/.test(value) || 'Password cannot contain spaces',
                                    }
                            }}/>

                            <FormInput
                                autoComplete='new-password'
                                control={control}
                                fullWidth
                                required
                                name='passwordConfirm'
                                label='Confirm Password'
                                type={showConfirmPassword ? 'text' : 'password'}
                                id='password-confirm'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                rules={{
                                    required: 'Please confirm your password',
                                    validate: (value) =>
                                        value === password || 'Passwords do not match',
                            }}/>

                            <div style={{display: 'flex', flexDirection: 'column', gap: 0}}>
                                <Typography sx={{fontSize:14, color: 'text.secondary'}}>
                                    Password must contain:
                                </Typography>
                                {passwordRequirements.split('\n').map(line => {
                                    return (
                                        <Typography sx={{fontSize:14, color: 'text.secondary', marginLeft: 1}}>
                                            {line}
                                        </Typography>
                                    )
                                })}
                            </div>

                            {registrationType === 'others' && (
                                <div style={{display: 'flex', flexDirection: 'column', gap: 0}}>
                                    <Typography variant='caption' sx={{fontSize:18}}>
                                        Invite Additional Users
                                    </Typography>
                                    <Box sx={{flexGrow: 1, padding: 2, minHeight:400, border: 1, borderRadius:2, borderColor: 'grey.500'}}>
                                        <UserInvites userInvites={userInvites} setUserInvites={setUserInvites} />
                                    </Box>
                                </div>
                            )}

                            <FormCheckbox
                                aria-label='Terms and Policies'
                                control={control}
                                id='termsAndPolicies'
                                key='termsAndPolicies'
                                name='termsAndPolicies'
                                label={
                                    <span>
                                        By checking this box, I certify that I am at least 13 years old and that I agree to the Terms and Policies, <Link href='https://www.futuregenxyz.com/terms-of-use' target="_blank" rel="noopener">Terms of Use</Link>, and <Link href='https://www.futuregenxyz.com/privacy-policy' target="_blank" rel="noopener">Private Policy</Link>. This service is for the U.S. only.
                                    </span>
                                }
                                onClick={toggleAgreement}/>

                            <Button disabled={!agreementChecked} format='primary' type='submit' text='Sign Up' style={{'minWidth': '400px', width: '100%'}} />
                        </div>
                    }
                </Box>
            </div>
        )
    }

    const thirdPartyRegistration = (): React.ReactElement => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 15}}>
                <LoginSocialGoogle
                    client_id={process.env.GOOGLE_CLIENT_ID || ''}
                    redirect_uri={redirectUri}
                    scope='openid profile email'
                    fetch_basic_profile={true}
                    isOnlyGetToken={false}
                    onResolve={(data: IResolveParams) => handleThirdPartyRegistrationSuccess(data)}
                    onReject={error => handleThirdPartyRegistrationError(error)}>
                    <ThirdPartyLoginButton mode={theme.palette.mode} type='signup' logo={<GoogleSvg />} text='Sign up with Google' />
                </LoginSocialGoogle>
                <LoginMicrosoft
                    client_id={process.env.MICROSOFT_CLIENT_ID || ''}
                    redirect_uri={redirectMicrosoftUri}
                    onResolve={(data: IResolveParams) => handleThirdPartyRegistrationSuccess(data)}
                    onReject={error => handleThirdPartyRegistrationError(error)}>
                    <ThirdPartyLoginButton mode={theme.palette.mode} type='signup' logo={<MicrosoftSvg />} text='Sign up with Microsoft' />
                </LoginMicrosoft>
            </div>
        )
    }

    const handleThirdPartyRegistrationSuccess = (data: IResolveParams) => {
        const { access_token, given_name, family_name, email } = data.data as {
            access_token?: string;
            given_name?: string;
            family_name?: string;
            email?: string;
        };

        if (!access_token || !email) {
            setError(`Error registering with ${titleCase(data.provider)}`);
            return;
        }

        handleRegistration({
            firstName: given_name || 'None on Account',
            lastName: family_name || 'None on Account',
            email: email,
            password: access_token,
            passwordConfirm: access_token,
            loginProvider: data.provider,
            termsAndPolicies: true,
            registrationOptions: {
                registerFor: registrationType,
                registerOthers: false,
                othersToInvite: []
            }
        });
    }

    const handleThirdPartyRegistrationError = (error: any) => {
        const {type, message} = error as {type: string, message: string};

        if(type !== 'popup_closed') {
            return;
        }
    }

    const passwordRequirements = `• At least 8 characters
        • At least one uppercase letter
        • At least one lowercase letter
        • At least two digits
        • At least one special character (@ $ ! % * ? &)
        • No spaces allowed`;

    return (
        <>
        {(loading && <Loading />) || 
            <div style={{display: 'flex', flexDirection: 'column', paddingTop: 15}}>
                {error && <Alert severity='error'>{error}</Alert>}

                <Typography component='h1' variant='h5' sx={{fontWeight: 900}}>
                    Create an Account
                </Typography>

                {/*
                {!isInvite &&
                    <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Typography component='p' variant='body1' >
                            Who are you registering this account for?
                        </Typography>

                        <RadioGroup
                            aria-labelledby="registration-radio-buttons-group"
                            name="registration-radio-buttons-group"
                            onChange={handleRegistrationForRadioChange}
                            sx={{display: 'flex', flexDirection: 'row'}}>
                            <Radio label="Myself" value="myself" checked={registrationType === 'myself'}/>
                            <Radio label="Others" value="others" checked={registrationType === 'others'}/>
                        </RadioGroup>
                    </Grid>
                }

                {(registrationType === 'myself') && (
                    <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2}}>
                        <Typography component='p' variant='body1' >
                            How would you like to register?
                        </Typography>

                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0}}>
                            <RadioGroup
                                aria-labelledby="registration--providerradio-buttons-group"
                                name="registration-provider-radio-buttons-group"
                                onChange={handleRegistrationProviderRadioChange}
                                sx={{display: 'flex', flexDirection: 'row'}}>
                                <Radio label="Using Future Gen" value="futuregen" checked={registrationProvider === 'futuregen'}/>
                                <Radio label="Using Third Party" value="thirdparty" checked={registrationProvider === 'thirdparty'}/>
                            </RadioGroup>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, marginLeft: 5}}>
                                <GoogleSvg />
                                <MicrosoftSvg />
                            </div>
                        </div>
                    </Grid>
                )}

                {registrationProvider === 'futuregen' ? futureGenRegistration() : thirdPartyRegistration()}

                */}

                <div style={{
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'center', 
                    alignItems: 'stretch', 
                    width: '85%',
                    minHeight: '400px',
                    gap: 40
                }}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10}}>
                        {futureGenRegistration()}

                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 10}}>
                            {'Already have an account? '}
                            <Link
                                component='button'
                                variant='body1'
                                onClick={() => navigate('/login')}>
                                Sign in
                            </Link>
                        </div>
                    </div>

                    <HostedDivider orientation='vertical' style={{marginLeft: 20, marginRight: 20}}>
                        <Typography sx={{fontWeight: 900, color: 'text.secondary'}}>
                            OR
                        </Typography>
                    </HostedDivider>

                    {thirdPartyRegistration()}
                </div>

                <Copyright/>
            </div>
        }
        </>
    );
};

export default Register; 