import React, { useEffect, useState} from 'react';
import YouTube, { YouTubeEvent } from 'react-youtube';

import { videoApi } from '../../api/openapi-axios-client';
import { callApi } from '../../api/helpers';
import { VideoPlayerProps } from '../../types';

const VideoPlayer: React.FC<VideoPlayerProps> = ({
    video,
    autoplay = 0,
    addtionalOptions,
    additionalVars,
    onEndFunc = () => {},
    viewOnly = false,
    height = '100%',
    width = '100%'
}) => {
    const [playerState, setPlayerState] = useState<string>('unstarted');

    useEffect(() => {
        if(video && Object.keys(video).length > 0) {
            setPlayerState('unstarted');
        }
    }, [video]);

    const recordStateChange = async (state: 'paused' | 'resumed' | 'started' | 'ended'): Promise<void> => {
        if (!video.watchId) {
            return;
        }

        if (state) {

            setPlayerState(state);

            await callApi(() => videoApi.watchLog.addVideoHistory(state, {
                watchId: video.watchId,
                eventOn: new Date().toISOString()
            }));
        }
    };

    const onPlayerStateChange = (event: YouTubeEvent): void => {

        /* 
            -1 (unstarted)
            0 (ended)
            1 (playing)
            2 (paused)
            3 (buffering)
            5 (video cued).
        */

        switch (event?.data) {
            case -1:
                break;
            case 0:
                recordStateChange('ended');
                onEndFunc();
                break;
            case 1: {
                recordStateChange(playerState === 'paused' ? 'resumed' : 'started');
                break;
            }
            case 2:
                recordStateChange('paused');
                break;
            default:
                return;
        }
    };

    const opts = {
        width,
        height,
        playerVars: {
            autoplay,
            ...additionalVars
        },
        disabled: true,
        ...addtionalOptions
    };

    const showView = viewOnly ? { pointerEvents: 'none' as const } : null;

    return (
        <YouTube
            videoId={video.id}
            opts={opts}
            onStateChange={onPlayerStateChange}
            style={{ flexGrow: 1, ...showView, height: opts.height, width: opts.width }}
        />
    );
};

export default VideoPlayer; 