import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import BackArrow from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {convertValue} from '../../helpers/salary';
import RootContext from '../../services/context-states/root-context';
import {assessmentApi} from '../../api/openapi-axios-client';
import {HorizontalScrollBox, Loading} from '../../elements';
import {fastGrowingCareer, truncateString} from '../../helpers/strings';
import { RootContextType, CareerClusterDetails, ClusterDisplayData, VideoHistoryRating, ONetCareerDetails } from '../../types';
import { callApi } from '../../api/helpers';
import { getClusterImage } from '../../helpers/images';

interface LocationState {
    clusterData?: ClusterDisplayData;
}

const ClusterDetailsPage: React.FC = () => {
    const {messageContext} = useContext<RootContextType>(RootContext);
    const {setError} = messageContext;

    const [loading, setLoading] = useState<boolean>(true);
    const [occupationDetails, setOccupationDetails] = useState<CareerClusterDetails[]>([]);
    const [clusterDisplayData, setClusterDisplayData] = useState<ClusterDisplayData | null>(null);

    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as LocationState;
    const {clusterData} = state || {};
    const {userId, careerClusterId, clusterCode} = useParams();

    useEffect(() => {
        const getDetail = async () => {
            let dataForDisplay: ClusterDisplayData | null = null;

            if(!clusterData) {
                const clusters = await callApi<VideoHistoryRating[]>(() => assessmentApi.ratings.getUserHistoryRating(userId || ''));

                if(!clusters) {
                    setError('There was an error retrieving the cluster data.');
                    setLoading(false);
                    return;
                }

                const matchedCluster = clusters.find(cluster => cluster.categoryId === careerClusterId);

                if(!matchedCluster) {
                    setError('There was an error retrieving the cluster data.');
                    setLoading(false);
                    return;
                }

                dataForDisplay = {
                    categoryId: matchedCluster.categoryId,
                    category: matchedCluster.category,
                    averageRating: matchedCluster.averageRating,
                    categoryDescription: matchedCluster.categoryDescription,
                    occupationData: matchedCluster.occupationData
                };

                setClusterDisplayData(dataForDisplay);
            }
            else {
                dataForDisplay = {
                    categoryId: clusterData.categoryId,
                    category: clusterData.category,
                    averageRating: clusterData.averageRating,
                    categoryDescription: clusterData.categoryDescription,
                    occupationData: clusterData.occupationData
                };

                setClusterDisplayData(dataForDisplay);
            }

            if(dataForDisplay && dataForDisplay.occupationData) {
                const occupationData: CareerClusterDetails[] = [];

                try {
                    for(const occupation of dataForDisplay.occupationData) {
                        const response = await callApi<ONetCareerDetails>(() => assessmentApi.careerData.getONetCareerData(occupation.code));
    
                        if(response && response.code) {
                            occupationData.push({
                                ...occupation,
                                ...response,
                                details: response
                            });
                        }
                    }
  
                    setOccupationDetails(occupationData.sort((a, b) => b.averageRating - a.averageRating));
                }
                catch(err) {
                    setError('Unable to load career details');
                    setOccupationDetails([]);
                }
            }

            setLoading(false);
        };

        getDetail();
    }, [userId, careerClusterId, clusterData, setError]);

    const generateCareerCard = (categoryId: string, category: string, occupation: CareerClusterDetails, i: number) => {
        return (
            <Box key={i} sx={{borderRadius: 1, border: .5, borderColor: 'container.border', padding:1, bgcolor: 'container.background', width: 300, height: 200, margin: 1, boxShadow: 4, cursor: 'pointer'}} onClick={() => navigate(`/careers/details/${userId}/${careerClusterId}/career/${careerClusterId}-${occupation.occupationId}-${occupation.occupationId}`, {state: {careerData: {backLabel: 'Top Interests', categoryId, category, occupation}}})}>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%', padding: 10, gap: 10, justifyContent: 'center'}}>
                    <Typography sx={{fontSize: 16, fontWeight: 900, color: 'text.primary'}}>
                        {truncateString(occupation.title, 50)}
                    </Typography>
                    {occupation?.details?.tags?.bright_outlook && (
                        <Typography sx={{fontSize: 14, color: 'text.fastCareer'}}>
                            {`${fastGrowingCareer} Fast-growing career`}
                        </Typography>)
                    }
                    <Typography sx={{fontSize: 14, color: 'primary.main'}}>
                        {`${convertValue(occupation?.averageRating)}% match`}
                    </Typography>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{fontSize: 14, color: 'grey.500'}}>
                            See more
                        </Typography>
                        <ArrowRightAltIcon sx={{color: 'grey.500'}}/>
                    </div>
                </div>
            </Box>
        );
    };

    return (
        <>
        {(loading && <Loading />) || 
            <Grid container spacing={2} sx={{width:'100%', height:'100%'}}>
                <Grid size={12}>
                    <Stack direction='row' spacing={0} display={'flex'} alignItems={'center'}>
                        <IconButton onClick={() => navigate('/results')}>
                            <BackArrow sx={{color: 'text.secondary', fontSize: '2.5rem'}}/>
                        </IconButton>
                        <Typography sx={{fontSize: 20, color: 'text.secondary', verticalAlign:'center', cursor:'pointer'}} onClick={() => navigate('/results')}>
                            Return to Top Interests
                        </Typography>
                    </Stack>
                </Grid>
                <Grid container size={12} sx={{marginLeft:4}}>
                    <Grid size={12} sx={{marginTop: 2}}>
                        <Box sx={{borderRadius: 2, border: 1, borderColor: 'container.border', bgcolor: 'container.background', display:'flex', flexDirection:'row'}}>
                            <img src={getClusterImage(`${clusterCode}.0000`)} alt={clusterCode ?? ''} style={{overflow: 'hidden', objectFit: 'cover', minWidth: 400}}/>
                            <Grid container spacing={2} padding={4}>
                                <Grid size={12}>
                                    <Typography sx={{fontSize: 22, fontWeight: 900, color: 'text.primary'}}>
                                        {clusterDisplayData?.category}
                                    </Typography>
                                </Grid>
                                <Grid size={12}>
                                    <Typography sx={{fontSize: 16, color: 'text.primary'}}>
                                        {`You had a ${convertValue(clusterDisplayData?.averageRating || 0)}% match with this cluster.`}
                                    </Typography>
                                </Grid>
                                <Grid size={12}>
                                    <Typography sx={{fontSize: 16, color: 'text.primary'}}>
                                        {clusterDisplayData?.categoryDescription}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid container size={12} sx={{marginTop: 4}}>
                        <Grid size={12}>
                            <Divider sx={{color: 'divider', background: 'divider'}}/>
                        </Grid>
                        <Grid size={12} sx={{marginTop:4}}>
                            <Typography sx={{fontSize: 22, fontWeight: 900, color: 'text.primary'}}>
                                Potential Career Paths
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <Typography sx={{fontSize: 14, color: 'text.primary'}}>
                                Career paths that can be found in these top categories.
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <HorizontalScrollBox toRender={occupationDetails.map((occupation, i) => generateCareerCard(clusterDisplayData?.categoryId || '', clusterDisplayData?.category || '', occupation, i))} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }
        </>
    );
};

export default ClusterDetailsPage; 