import { CareerTabPanelProps } from '../../../types';
import { useTheme } from '@mui/material/styles';
const CareerTabPanel: React.FC<CareerTabPanelProps> = ({children, value, index, ...other}) => {

    const theme = useTheme();
    return (
        <>
            {value === index && (
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`vertical-tabpanel-${index}`}
                    aria-labelledby={`vertical-tab-${index}`}
                    {...other}
                    style={{
                        display:'flex',
                        width: '100%',
                        minHeight:675,
                        flexGrow: 1,
                        gap: 5,
                        borderStyle: 'solid',
                        borderLeftWidth: 0,
                        borderTopWidth: 1,
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        borderColor: theme.palette.container.border,
                        padding: 15,
                        background:'background.default'
                    }}>
                    {children}
                </div>
            )}
        </>
    );
}

export default CareerTabPanel;