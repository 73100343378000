import PieChartIcon from '@mui/icons-material/PieChart';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import HistoryIcon from '@mui/icons-material/History';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import * as permissionHelper from '../../../helpers/permissions';
import { enableVideo } from '../../../helpers/entitlements';
import AccessFlags from '../../../types/access-flags';
import DrawerItem from '../../../types/drawer-item';
import { User, BillingInformation } from '../../../types';

const enableAdmin = (userData: User | null): boolean => {
  if (!userData?.additionalData?.role?.permissions) {
    return false;
  }

  return (
    permissionHelper.checkPermission(userData.additionalData.role.permissions, permissionHelper.permissions.ManageBilling) ||
    permissionHelper.checkPermission(userData.additionalData.role.permissions, permissionHelper.permissions.ManageUsers)
  );
};

const hideDisableResultsLibrary = (billingInformation: BillingInformation | null, userData: User | null): boolean => {
  if (!userData) {
    return true;
  }

  if (enableVideo(userData, billingInformation)) {
    return false;
  }

  return true;
};

export const mainDrawer = (userData: User | null, billingInformation: BillingInformation | null, accessFlags: AccessFlags): DrawerItem[] => {
  if (!userData || Object.keys(userData).length === 0 || !userData.additionalData) {
    return [];
  }

  return [
    {
      disabled: false,
      hidden: false,
      icon: <HomeOutlinedIcon />,
      showBadge: false,
      badgeValue: -1,
      key: 'home',
      route: '/home',
      text: 'Home',
      subroutes: [],
      displayDivider: false,
    },
    {
        disabled: !enableVideo(userData, billingInformation),
        hidden: false,
        icon: <PlayArrowOutlinedIcon />,
        showBadge: false,
        badgeValue: -1,
        key: 'watch',
        className: 'tour__videosWatch',
        route: '/videos/watch',
        text: 'Videos',
        subroutes: [],
        displayDivider: false,
    },
    {
      disabled: !enableVideo(userData, billingInformation),
      hidden: false,
      icon: <PieChartIcon />,
      showBadge: false,
      badgeValue: -1,
      key: 'results',
      className: 'tour__seeYourResults',
      route: '/results',
      text: 'Results',
      subroutes: [],
      displayDivider: false,
    },
    {
        disabled: false,
        hidden: !enableAdmin(userData),
        icon: <ReplyIcon sx={{transform: 'rotateY(180deg)'}} />,
        showBadge: false,
        badgeValue: -1,
        key: 'share',
        route: '/share',
        text: 'Share',
        subroutes: [],
        displayDivider: false,
    },
    {
      disabled: hideDisableResultsLibrary(billingInformation, userData),
      hidden: false,
      icon: <ExploreOutlinedIcon />,
      showBadge: false,
      badgeValue: -1,
      key: 'library',
      route: '/library',
      text: 'Explore',
      subroutes: [],
      displayDivider: false,
    },
    {
        disabled: !enableVideo(userData, billingInformation),
        hidden: false,
        icon: <HistoryIcon />,
        showBadge: false,
        badgeValue: -1,
        key: 'history',
        route: '/videos/history',
        text: 'History',
        subroutes: [],
        displayDivider: false,
    },
    {
      disabled: false,
      hidden: false,
      icon: <SettingsOutlinedIcon />,
      showBadge: (accessFlags.showIncompleteProfile || accessFlags.showUnusedLicenses) && (accessFlags.enableManageBilling || accessFlags.enableManageUsers),
      badgeValue: -1,
      key: 'settings',
      route: '/settings',
      text: 'Settings',
      subroutes: [],
      displayDivider: false,
    },
  ] as DrawerItem[];
}; 