import React, { useEffect, useState } from 'react';
import { useSetState } from 'react-use';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate, Route, Outlet } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import { ThemeContextProvider } from './ThemeContextProvider';
import RootContext from './services/context-states/root-context';
import { generateTourSteps } from './components/app-tour/steps';
import AuthService from './services/auth';
import AccessFlags from './types/access-flags';
import AppTour from './types/app-tour';
import {BillingInformation, RootContextType, User } from './types';

import AuthRedirect from './components/authentication/social-third-party-login/AuthRedirect';
import CareerDetailsPage from './components/careers/career-details/CareerDetailsPage';
import ClusterDetailsPage from './components/careers/ClusterDetailsPage';
import Home from './components/Home';
import Landing from './components/Landing';
import LibraryClustersPage from './components/library/LibraryClustersPage';
import LibraryClusterDetailsPage from './components/library/LibraryClusterDetailsPage';
import Login from './components/authentication/Login';
import NavigationBar from './components/app-bar/NavigationBar';
import PrivateRoute from './routers/PrivateRoute';
import ProfilePage from './components/profile/ProfilePage';
import PublicRoute from './routers/PublicRoute';
import Register from './components/authentication/Register';
import ResetPassword from './components/authentication/ResetPassword';
import ResetPasswordFinish from './components/authentication/ResetPasswordFinish';
import ResultsPage from './components/results/ResultsPage';
import ShareReportPage from './components/results/ShareReportPage';
import RegistrationSurvey from './components/surveys/RegistrationSurvey';
import VideoHistoryPage from './components/videos/VideoHistoryPage';
import VideosPage from './components/videos/VideosPage';
import ViewInsightReport from './components/results/ViewInsightReport';
import ScrollToTop from './components/ScrollToTop';
import AnalyticsNavigated from './components/AnalyticsNavigated';
const App: React.FC = () => {
  const [token, setToken] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [billingInformation, setBillingInformation] = useState<BillingInformation | null>(null);
  const [error, setError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [accessFlags, setAccessFlags] = useState<AccessFlags>({
    enableManageBilling: false,
    enableManageUsers: false,
    showIncompleteProfile: false,
    showMissingLicense: false,
    showUnusedLicenses: false,
  });

  const [appTour, setTourState] = useSetState<AppTour>({
    tourActive: false,
    run: false,
    stepIndex: 0,
    steps: generateTourSteps()
  });

  const tokenContext = { token, setToken };
  const userContext = { currentUser, setCurrentUser };
  const billingContext = { billingInformation, setBillingInformation };
  const messageContext = { error, setError, successMessage, setSuccessMessage };
  const accessFlagsContext = { accessFlags, setAccessFlags };
  const appTourContext = { appTour, setTourState };
  const value: RootContextType = { 
    accessFlagsContext,
    appTourContext,
    billingContext,
    messageContext,
    tokenContext,
    userContext
  };
  
  useEffect(() => {
    const user = AuthService.getCurrentUser();
    const authToken = user?.token;
    
    if (!token && !!authToken) {
      setToken(authToken);
    }

    if (!currentUser?.id && authToken) {
      const userData = AuthService.decodeUserToken(authToken)?.userData;
      if (userData) {
        setCurrentUser(userData);
      }
    }
  }, [currentUser?.id, token]);

  const authenticated = !!token || !!AuthService.getCurrentUser()?.token;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AnalyticsNavigated />}>
        <Route path="/">
          <Route element={<NavigationBar ignoreLogin={false} />}>
            <Route element={<PublicRoute><Outlet /></PublicRoute>}>
              <Route path="/" element={authenticated ? <Navigate to="/home" /> : <Landing />} />
              <Route path="/auth-redirect" element={<AuthRedirect />} />
              <Route path="/invite-accept" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/reset-password/complete" element={<ResetPasswordFinish />} />
            </Route>
            <Route element={<PrivateRoute authenticated={authenticated} />}>
              <Route path="/home" element={<Home />} />
              <Route path="/careers/details/:userId/:careerClusterId/career/:detailId" element={<CareerDetailsPage />} />
              <Route path="/careers/details/:userId/:careerClusterId/:clusterCode" element={<ClusterDetailsPage />} />
              <Route path='/library' element={<LibraryClustersPage />} />
              <Route path='/library/cluster/:clusterId' element={<LibraryClusterDetailsPage />} />
              <Route path="/results" element={<ResultsPage />} />
              <Route path="/settings" element={<ProfilePage />} />
              <Route path="/share" element={<ShareReportPage />} />
              <Route path="/user-profile/:profileSearch" element={<ProfilePage loadPublic={true} />} />
              <Route path="/videos/watch" element={<VideosPage />} />
              <Route path="/videos/history" element={<VideoHistoryPage />} />
              <Route path="*" element={<Home />} />
            </Route>
            <Route element={<PublicRoute />}>
              <Route path="*" element={<Landing />} />
            </Route>
          </Route>
          <Route element={<NavigationBar ignoreLogin={true} />}>
            <Route element={<PublicRoute />}>
              <Route path="/results/share" element={<ViewInsightReport />} />
            </Route>
          </Route>
          <Route element={<NavigationBar ignoreLogin={true} />}>
            <Route element={<PrivateRoute authenticated={true} hideAlerts={true} />}>
              <Route path="/registration-survey" element={<RegistrationSurvey />} />
            </Route>
          </Route>
        </Route>
      </Route>
    )
  );

  return (
    <ThemeContextProvider>
      <CssBaseline />
      <RootContext.Provider value={value}>
        <RouterProvider router={router} />
      </RootContext.Provider>
    </ThemeContextProvider>
  );
};

export default App; 