/* eslint-disable no-console */
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@elements';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import {userApi} from '../../../api/openapi-axios-client';
import {userInviteTemplate} from '../../../assets';

import * as EmailValidator from 'email-validator';
import * as XLSX from 'xlsx';

export const GlobalUserInvite = (props) => {

    const {userInvites, setUserInvites} = props;

    const [inviteError, setInviteError] = useState('');

    // Roles Data
    const [roles, setRoles] = useState([]);

    const [inviteEmail, setInviteEmail] = useState('');
    const [inviteRole, setInviteRole] = useState('');

    const inputFile = useRef(null) 

    useEffect(() => {
        const getData = async () => {

          // Get Roles
          const roles = await userApi.roles.getAllRoles(false);
          setRoles(roles);
        };
    
        getData().catch((error) => {
          console.log(error);
        });
    }, []);  

    const handleAddInvite = () => {
        setInviteError('');
        if(EmailValidator.validate(inviteEmail)) {
            // Check if email already exists
            if(userInvites.filter((i) => i.email === inviteEmail).length > 0) {
                setInviteError('Email already invited');
                return;
            }

            if(inviteRole === '') {
                setInviteError('Select Role');
                return;
            }

            setUserInvites([...userInvites, {email: inviteEmail, roleId: inviteRole}]);
            setInviteEmail('');
            setInviteRole('');
        } else {
            inviteError('Invalid Email');
        }
    }

    const handleRemoveInvite = (invite) => {
        setUserInvites(userInvites.filter((i) => i.email !== invite.email));
    }

    const inviteUser = invite => {
    return (
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}} key={invite.id}>
        <IconButton aria-label="delete-invite" onClick={() => handleRemoveInvite(invite)} sx={{marginRight:'4px', color: (theme) => theme.palette.grey[500]}}>
            <CloseIcon />
        </IconButton>
        <Typography variant='caption' style={{padding: 5, flexGrow:1, fontSize:18}}>{invite.email}</Typography>
        <Typography variant='caption' style={{padding: 5, fontSize:18}}>{roles[roles.map(role => role.id).indexOf(invite.roleId)].name}</Typography>
        </div>
    );
    }

    const handleTemplateUpload = selectedFile => {
        const file = selectedFile.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (event) => {
          const workbook = XLSX.read(event.target.result, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const sheetData = XLSX.utils.sheet_to_json(sheet)
         
          // Process File Data
          processTemplateData(sheetData);
        }

        reader.readAsArrayBuffer(file);
    }

    const processTemplateData = (sheetData) => {
        if(!sheetData) {
            setInviteError('Invalid Template');
        }

        if(!sheetData.length) {
            setInviteError('Empty  Template');
        }

        // Iterate through the Template Data
        let inviteErrors = [];
        let invitesToAdd = [];
        const roleId = roles.filter((role) => role.name === 'User')[0].id;

        sheetData.forEach((row) => {

            // Check if the email is valid
            if(!EmailValidator.validate(row['Email Address'])) {
                inviteErrors.push(`${row.Email} is not a valid Email`);
            }

            // Check if the email is already invited
            if(userInvites.filter((i) => i.email === row['Email Address']).length > 0) {
                setInviteError(`${row.Email} is already invited`);
            }

            // Add the invite
            invitesToAdd.push({email: row['Email Address'], roleId: roleId});
        });

        if(inviteErrors.length === 0) {
            setUserInvites([...userInvites, ...invitesToAdd]);
        }

        if(inviteErrors.length > 0) {
            setInviteError(inviteErrors.join(', '));
        }
    }

    return (
        <div style={{display:'flex', flexDirection:'column', height:'100%'}}>
            {(inviteError.length > 0) && <Alert severity="error">{inviteError}</Alert>}
                <div style={{display:'flex', flexDirection:'row', gap:5}}>
                    <TextField id='txtInviteEmail' label='Email' sx={{flexGrow:1, display:'flex'}} value={inviteEmail} onChange={(e) => setInviteEmail(e.target.value)} />
                    <FormControl>   
                        <InputLabel id="select-label">Role</InputLabel>
                        <Select id='selInviteRole' labelId='select-label' label='Role' sx={{minWidth:200, maxWidth:200}} value={inviteRole} onChange={(e) => setInviteRole(e.target.value)}>
                            {roles.map((role) => <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Button format='primary' text='Add' onClick={() => handleAddInvite()} />
                </div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'flex-end', marginTop:15}}>
                    <Typography variant='caption' sx={{fontSize:18, flexGrow: 1}}>Invites</Typography>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center', alignItems:'center', gap:0}}>
                        <Typography variant='caption' sx={{fontSize:14}}>Bulk Template:</Typography>
                        <a href={userInviteTemplate} download={'User Upload Template.xlsx'}><IconButton size='small'><DownloadForOfflineIcon color='primary'/></IconButton></a>
                        <IconButton size='small' onClick={() => inputFile.current.click()}><UploadFileIcon color='primary'/></IconButton>
                        <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleTemplateUpload} />
                    </div>
                </div>
                <Box padding={1} marginBottom={2} sx={{maxHeight: 220, flexGrow:1, flexShrink:1, borderRadius: 2, border: 1, borderColor: 'grey.500', overflow:'auto'}}>
                    {userInvites.map((invite) => inviteUser(invite))}
                </Box>
        </div>
    )
}

GlobalUserInvite.propTypes = {
    userInvites: PropTypes.array,
    setUserInvites: PropTypes.func
};