import { SxProps, Theme } from '@mui/material/styles';

const ROUND_HOVER_CHANGE_BUTTON: SxProps<Theme> = {
    fontSize: '1.5rem',
    minWidth: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    backgroundColor: 'primary.contrastText',
    color: 'primary.main',
    border: '2px solid',
    ':hover': {
        border: '2px solid',
        borderColor: 'primary.contrastText',
        backgroundColor: 'primary.main',
        color: 'primary.contrastText'
    }
};

const ROUND_HOVER_CHANGE_BUTTON_INVERSE: SxProps<Theme> = {
    fontSize: '1.5rem',
    minWidth: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    backgroundColor: 'primary.main',
    borderColor: 'primary.main',
    color: 'primary.contrastText',
    border: '2px solid',
    ':hover': {
        border: '2px solid',
        borderColor: 'primary.main',
        backgroundColor: 'primary.contrastText',
        color: 'primary.main'
    }
};

const ROUND_HOVER_CHANGE_ICON_BUTTON: SxProps<Theme> = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    border: '2px solid',
    ':hover': {
        border: '2px solid',
        borderColor: 'primary.main',
        backgroundColor: 'primary.contrastText',
        color: 'primary.main'
    }
};

// Use on light backrounds so border does not get lost
const ROUND_HOVER_CHANGE_ICON_BUTTON_LIGHT: SxProps<Theme> = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    border: '2px solid',
    borderColor: 'primary.main',
    ':hover': {
        border: '2px solid',
        borderColor: 'primary.main',
        backgroundColor: 'primary.contrastText',
        color: 'primary.main'
    }
};

// Use on dark backgrounds because icon's white border needs to show
const ROUND_HOVER_CHANGE_ICON_BUTTON_INVERSE_DARK: SxProps<Theme> = {
    backgroundColor: 'primary.contrastText',
    color: 'primary.main',
    border: '2px solid',
    ':hover': {
        border: '2px solid',
        borderColor: 'primary.contrastText',
        backgroundColor: 'primary.main',
        color: 'primary.contrastText'
    }
};

// Use on light backgrounds because the border needs to show
const ROUND_HOVER_CHANGE_ICON_BUTTON_INVERSE_LIGHT: SxProps<Theme> = {
    backgroundColor: 'primary.contrastText',
    color: 'primary.main',
    border: '2px solid',
    borderColor: 'primary.main',
    ':hover': {
        border: '2px solid',
        borderColor: 'primary.main',
        backgroundColor: 'primary.main',
        color: 'primary.contrastText'
    }
};

export {
    ROUND_HOVER_CHANGE_BUTTON,
    ROUND_HOVER_CHANGE_BUTTON_INVERSE,
    ROUND_HOVER_CHANGE_ICON_BUTTON,
    ROUND_HOVER_CHANGE_ICON_BUTTON_LIGHT,
    ROUND_HOVER_CHANGE_ICON_BUTTON_INVERSE_DARK,
    ROUND_HOVER_CHANGE_ICON_BUTTON_INVERSE_LIGHT
}; 