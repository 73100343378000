import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { logAnalyticsPageView } from '../helpers/analytics';

const AnalyticsNavigated = () => {

  const { pathname, search } = useLocation();

  useEffect(() => {
    logAnalyticsPageView(pathname + search);
  }, [pathname, search]);

  return <Outlet />;
};

export default AnalyticsNavigated; 