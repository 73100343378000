import React, {useContext, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';

import PropTypes from 'prop-types'
import RootContext from '../../../services/context-states/root-context';

import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {Button, FormInput} from '@elements';

import {userApi} from '../../../api/openapi-axios-client';

const AccountInfo = ({profile}) => {

    const [showEdit, setShowEdit] = useState(false);
    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const {control, reset, handleSubmit} = useForm({
        defaultValues: {
            email: profile.email || '',
            firstName: profile.firstName || '',
            lastName: profile.lastName || ''
        }
    });
    
    const {messageContext: {setError}, userContext: {setCurrentUser}} = useContext(RootContext);

    const currentPassword = useRef(null);
    const newPassword = useRef(null);
    const confirmPassword = useRef(null);

    const handleFormSubmit = async data => {
        setError('');
        const response = userApi.user.updateProfile(data).catch(err => { setError(err.message); return;});

        const newCurrentUser = {
            ...profile,
            ...response
        };

        setCurrentUser(newCurrentUser);
        toggleEdit(false);
    }

    const handlePasswordUpdate = async () => {

        setError('');
        const currentPasswordValue = currentPassword.current.value;
        const newPasswordValue = newPassword.current.value;
        const confirmPasswordValue = confirmPassword.current.value;

        if(!currentPasswordValue || !newPasswordValue || !confirmPasswordValue) {
            setError('All fields are required');
            return;
        }

        if(newPasswordValue !== confirmPasswordValue) {
            setError('Passwords do not match');
            return;
        }

        await userApi.password.changePassword({currentPassword: currentPasswordValue, newPassword: newPasswordValue}).catch(err => { setError(err.message); return; });
        togglePasswordChange(false);
    }

    const editForm = () => {
        return (
            <>
                <Grid size={12}>
                    <FormInput key={ 'email' } name={ 'email' } id={ 'email' } control={ control } sx={{width:'100%'}} />
                </Grid>
                <Grid size={12}>
                    <Stack direction={'row'} spacing={2}>
                        <FormInput key={ 'firstName' } name={ 'firstName' } id={ 'firstName' } control={ control } sx={{width:'50%'}} />
                        <FormInput key={ 'lastName' } name={ 'lastName' } id={ 'lastName' } control={ control } sx={{width:'50%'}} />
                    </Stack>
                </Grid>
                <Grid size={12}>
                    <Stack direction='row' spacing={2} sx={{display: 'flex', justifyContent: 'left', alignItems: 'center', textAlign: 'center'}}>
                        <Button format='primary' type='submit' text='Update' sx={{marginRight: 1} } />
                        <Button format='secondary' type='button' text='Cancel' sx={{marginRight: 1} } onClick={() => { toggleEdit(false); reset();}}/>
                    </Stack>
                </Grid>
            </>
        )
    }

    const viewForm = () => {
        return (
            <>
                <Grid size={12}>
                    <Typography variant='body1' fontWeight={900}>
                        Email
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        {profile.email}
                    </Typography>
                </Grid>
                <Grid size={4}>
                    <Typography variant='body1' fontWeight={900}>
                        First Name
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        {profile.firstName}
                    </Typography>
                </Grid>
                <Grid size={4} />
                <Grid size={4}>
                    <Typography variant='body1' fontWeight={900}>
                        Last Name
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        {profile.lastName}
                    </Typography>
                </Grid>
                <Grid size={12}>
                    <Button format='primary' type='button' text='Edit' sx={{marginRight: 1} } onClick={() => toggleEdit(true)} />
                </Grid>
            </>
        )
    }

    const toggleEdit = (value) => {
        setShowEdit(value);
        setError('');
    }

    const togglePasswordChange = (value) => {
        setShowPasswordChange(value);
        setError('');
    }

    const passwordUpdateForm = () => {
        return (
            <>
                <Grid size={12}>
                    <Stack direction={'column'} spacing={2}>
                    <TextField id={'currentPassword'} label={'Current Password'} name={'currentPassword'} type='password' required inputRef={currentPassword} />
                    <TextField id={'newPassword'} label={'New Password'} name={'newPassword'} type='password' required inputRef={newPassword}/>
                    <TextField id={'confirmPassword'} label={'Confirm Password'} name={'confirmPassword'} type='password' required inputRef={confirmPassword}/>
                    </Stack>
                </Grid>
                <Grid size={12}>
                    <Stack direction='row' spacing={2} sx={{display: 'flex', justifyContent: 'left', alignItems: 'center', textAlign: 'center'}}>
                    <Button format='primary' type='button' text='Update' sx={{marginRight: 1} } onClick={handlePasswordUpdate}/>
                    <Button format='secondary' type='button' text='Cancel' sx={{marginRight: 1} } onClick={() => { togglePasswordChange(false);}}/>
                    </Stack>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Grid container size={12} spacing={2} sx={{width:'500px'}}>
                <Grid size={12}>
                    <Typography variant='h5' fontWeight={900}>
                        Account Info
                    </Typography>
                </Grid>
                <Grid container spacing={2} size={12} component='form' onSubmit={handleSubmit(handleFormSubmit)}>
                    {
                        showEdit ? editForm() : viewForm()
                    }
                </Grid>
                <Grid size={12}>
                    <Typography variant='h5' fontWeight={900}>
                        Update Password
                    </Typography>
                </Grid>
                <Grid size={12} />
                <Grid container spacing={2} size={12} justifyContent={'left'}>
                    {
                        !showPasswordChange 
                            ? (
                                <Grid size={12}>
                                    <Button format='primary' type='button' text='Update' onClick={() => { setShowPasswordChange(true);}}/>
                                </Grid>
                            )
                            : passwordUpdateForm()
                    }
                </Grid>
            </Grid>
        </>
    )
}

AccountInfo.propTypes = {
    profile: PropTypes.object.isRequired,
}

export default AccountInfo