import React, { useRef, ReactNode } from 'react';
import useHorizontalScrollBox from './useHorizontalScrollBox';
import './horizontalScrollBox.css';
import { GlobalHorizontalScrollBoxProps } from '../../../types';
export function GlobalHorizontalScrollBox({ toRender }: GlobalHorizontalScrollBoxProps) {
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const { isDragging } = useHorizontalScrollBox(scrollWrapperRef);

  return (
    <div className="scroll-box">
      <div className="scroll-box__wrapper" ref={scrollWrapperRef}>
        <div className="scroll-box__container" role="list" style={{ pointerEvents: isDragging ? 'none' : undefined, gap: 10 }}>
          {toRender.map((child, i) => (
            <div role="listitem" key={`scroll-box-item-${i}`} className="scroll-box__item">
              {child}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
} 