import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RootContext from '../services/context-states/root-context';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import {
  exampleMenu,
  exampleWatch,
  exampleRatings,
  exampleResults,
  iconLightBulb,
  iconHammerWrench,
} from '../assets';

import { enableVideo } from '../helpers/entitlements';
import AppTour from 'types/app-tour';
import { registeredTrademark } from '../helpers/strings';
import { BooleanResponse } from '../types/api-client-types';
import { callApi } from '../api/helpers';
import { userApi } from '../api/openapi-axios-client';
import { logAnalyticsEvent } from '../helpers/analytics';
import { GoogleAnalyticsEvent } from '../types/analytic-events';

interface SetupModalStep {
  image: string;
  caption: string;
}

interface CardProps {
  icon: string;
  title: string;
  description: string;
  modal: () => void;
}

const Home: React.FC = () => {
  const { appTourContext, billingContext, userContext } = useContext(RootContext);
  const { billingInformation } = billingContext;
  const { currentUser } = userContext;
  const { appTour, setTourState } = appTourContext;

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showSetupModal, setShowSetupModal] = useState(false);
  const [showHowItWorksModal, setShowHowItWorksModal] = useState(false);
  const [setupStep, setSetupStep] = useState(0);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const displayWelcomeModal = searchParams.get('welcome') === 'true';
  const theme = useTheme();
  
  useEffect(() => {
    if (displayWelcomeModal) {
      setShowWelcomeModal(true);
    }
  }, [displayWelcomeModal]);

  useEffect(() => {
    if (appTour.tourActive) {
      // Small delay to ensure DOM elements are ready
      setTimeout(() => {
        setTourState((prevState: AppTour) => ({
            ...prevState,
            run: true,
        }));
      }, 500);
    }
  }, [appTour.tourActive, setTourState]);

  useEffect(() => {
    const checkRegistrationSurvey = async () => {
      if (!currentUser) {
        return;
      }

      const registrationSurvey = await callApi<BooleanResponse>(() => userApi.userSurveys.hasCompletedSurvey('registration-survey'));
      
      if (!registrationSurvey.result && currentUser?.createdOn && new Date(currentUser.createdOn) >= new Date('2025-03-10T00:00:00Z')) {
        navigate('/registration-survey', { replace: true });
      }
    }
    
    checkRegistrationSurvey();
  }, [currentUser, navigate]);

  const buildCard = ({ icon, title, description, modal }: CardProps) => {
    return (
      <Box sx={{ boxShadow: 3, borderRadius: 2, padding: '8px 4px', backgroundColor: 'container.background', borderColor: 'container.border', borderWidth: 1, width: '300px', textAlign: 'center' }}>
        <Stack spacing={2} sx={{ alignContent: 'center', alignItems: 'center' }}>
          <img src={icon} alt="icon" style={{ width: 50, height: 50, marginTop: 10, marginBottom: 10 }} />
          <Typography sx={{ fontWeight: 'bold', fontSize: 22, marginTop: 2, color: 'text.primary' }}>{title}</Typography>
          <Typography sx={{ fontSize: 18, marginTop: 2, color: 'text.primary' }}>{description}</Typography>
          <IconButton onClick={modal}>
            <ArrowCircleRightOutlinedIcon sx={{ width: 50, height: 50}} />
          </IconButton>
        </Stack>
      </Box>
    );
  };

  const handleSetupModalClose = () => {
    setShowSetupModal(false);
    setSetupStep(0);
  };

  const handleStepMove = (direction: 'forward' | 'back') => {
    if (direction === 'forward') {
      if (setupStep < setupModalData.length - 1) {
        setSetupStep(setupStep + 1);
      }
    } else {
      if (setupStep > 0) {
        setSetupStep(setupStep - 1);
      }
    }
  };

  const setupSteps = () => {
    return (
      <div style={{ display: 'grid', gridTemplateRows: 'auto minmax(0, 1fr)' }}>
        <div style={{ maxHeight: '350px', minHeight: '350px', display: 'grid', gridTemplateRows: 'auto minmax(0, 1fr)', marginTop: 8 }}>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'flex-start', alignContent: 'center', justifyContent: 'center', paddingTop: 2, textAlign: 'center' }}>
            <img src={setupModalData[setupStep].image} alt="example" />
          </Box>
        </div>
        <div style={{ maxHeight: '160px', minHeight: '160px', display: 'grid', gridTemplateRows: 'auto minmax(0, 1fr)', backgroundColor: 'container.background', padding: '0 2px' }}>
          <Box sx={{ display: 'flex', height: '160px', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
              <IconButton
                onClick={() => handleStepMove('back')}
                sx={{ color: (theme) => theme.palette.grey[500], visibility: setupStep > 0 ? 'visible' : 'hidden' }}>
                <KeyboardArrowLeft sx={{ fontSize: '50px' }} />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', padding: 2 }}>
              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'flex-start', alignContent: 'center', justifyContent: 'center', paddingTop: 2, textAlign: 'center' }}>
                <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: '#000000' }}>{setupModalData[setupStep].caption}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', alignContent: 'center', justifyContent: 'center', paddingBottom: 1 }}>
                {setupStep + 1 === setupModalData.length ? (
                  <Button variant="contained" onClick={() => navigate('/videos/watch')} disabled={!enableVideo(currentUser, billingInformation)}>
                    Start watching videos
                  </Button>
                ) : (
                  <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'lightgray' }}>
                    {setupStep + 1} / {setupModalData.length}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
              <IconButton
                onClick={() => handleStepMove('forward')}
                sx={{ color: (theme) => theme.palette.grey[500], visibility: setupStep + 1 < setupModalData.length ? 'visible' : 'hidden' }}
              >
                <KeyboardArrowRight sx={{ fontSize: '50px' }} />
              </IconButton>
            </Box>
          </Box>
        </div>
      </div>
    );
  };

  const setupModalData: SetupModalStep[] = [
    {
      image: exampleMenu,
      caption: 'Navigate to the Watch Page under the Videos tab.',
    },
    {
      image: exampleWatch,
      caption: 'Watch videos to learn about different careers and give a Yes or No reaction. Each reaction you give contributes to a more accurate career recommendation.',
    },
    {
      image: exampleRatings,
      caption: 'When you\'re done with the video, you can proceed to the next video.',
    },
    {
      image: exampleResults,
      caption: 'After watching 30 videos, you\'ll be prompted to go to your Results Page to see your career insights.',
    },
  ];

  const welcomeModal = () : React.ReactElement => {
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 20, paddingBottom: 20, paddingLeft: 40, paddingRight: 40, gap: 20}}>
        <Typography sx={{fontSize: 48}}>{String.fromCodePoint(0x1F389)}</Typography>
        <Typography sx={{fontSize: 24, color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main, fontWeight: 900}}>
          We're glad you're here!
        </Typography>
        <div style={{display: 'flex', flexDirection: 'row', gap: 2}}>
          <Typography sx={{fontSize: 18, color: theme.palette.text.primary}}>
            Start exploring your career options with CareerAtlas!
          </Typography>
          <Typography sx={{fontSize: 12, color: theme.palette.text.primary}}>
            {registeredTrademark}
          </Typography>
        </div>
        <Typography sx={{fontSize: 18, color: theme.palette.text.primary}}>
          Let us show you around.
        </Typography>
        <div style={{display: 'flex', flexDirection: 'row', gap: 40, marginTop: 20, height: 50}}>
          <Button variant='outlined' color={`${theme.palette.mode === 'dark' ? 'secondary' : 'primary'}`} type='submit' style={{width: 175}} onClick={() => {
            
            logAnalyticsEvent({
              category: 'tour',
              action: GoogleAnalyticsEvent.UserSkipedWelcomeTour,
              label: 'User skipped Welcome Tour',
            });

            setShowWelcomeModal(false)
            navigate('/home', { replace: true });
          }}>Skip</Button>
          <Button variant='contained' color='primary' type='submit' style={{width: 175}} onClick={() => {
                setTourState((state: AppTour) => ({
                  ...state,
                  tourActive: true,
                  run: true,
                  stepIndex: 0
              }));
              setShowWelcomeModal(false);
              navigate('/home', { replace: true });
          }}>Start the tour</Button>
        </div>
      </div>
    );
  };

  return (
    <Grid container spacing={2} sx={{ marginLeft: 3 }}>
      <Grid size={12}>
        <Dialog
          open={showSetupModal}
          onClose={handleSetupModalClose}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                background: '#EEEDFF',
                width: '100%',
                maxWidth: '650px',
                height: '100%',
                maxHeight: '550px',
              },
            },
          }}>
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleSetupModalClose}
              sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          {setupSteps()}
        </Dialog>
        <Dialog
          open={showHowItWorksModal}
          onClose={() => setShowHowItWorksModal(false)}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '650px',
              },
            },
          }}
        >
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setShowHowItWorksModal(false)}
              sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Stack spacing={2} sx={{ padding: 4 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 24 }}>How your Results are Calculated</Typography>
            <Typography sx={{ fontSize: 20 }}>
              The career paths shown to you in your Results page are calculated from your reactions to the videos.
              <br />
              <br />
              Every video you've liked will increase a career path's ranking and every video that you've disliked will decrease a career path's ranking.
              <br />
              <br />
              The selection of curated videos will be refreshed based on your results after you have watched 30 videos.
            </Typography>
          </Stack>
        </Dialog>
        <Dialog open={showWelcomeModal}>
          {welcomeModal()}
        </Dialog>
      </Grid>
      <Grid size={12}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 38 }}>Welcome back, {currentUser?.firstName}!</Typography>
      </Grid>
      <Grid size={12}>
        <Typography sx={{ fontSize: 28, fontWeight: 'bold', marginTop: 2 }}>Guides to get started</Typography>
      </Grid>
      <Grid size={12}>
        <Stack spacing={3} direction="row">
          {buildCard({
            icon: iconLightBulb,
            title: 'Getting Started',
            description: 'Learn how to begin your CareerAtlas journey.',
            modal: () => setShowSetupModal(true),
          })}
          {buildCard({
            icon: iconHammerWrench,
            title: 'How It Works',
            description: 'Find out how we calculate your results.',
            modal: () => setShowHowItWorksModal(true),
          })}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Home; 